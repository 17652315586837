<template>
  <header
    :class="{
      [$style.header]: true,
      [$style.primaryColor]: hasPrimaryColor && !headerComp?.isMenuOpen,
      [$style.headerBackground]: isBackgroundShown,
      [$style.headerHover]: isBackgroundHoverShown && !$isMobile,
    }"
  >
    <app-header-container />
  </header>
</template>

<script lang="ts" setup>
import { isClient } from '@vueuse/core';
import type { ComponentPublicInstance, Ref } from 'vue';
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';

import useThemeColor from '@/code/layout/use-theme-color';
import { AppRoute } from '@/platform/router/routes';

import AppHeaderContainer from './AppHeaderContainer.vue';

const { setThemeColor } = useThemeColor();

const headerComp = ref<
  ComponentPublicInstance<{
    isMenuOpen: Ref<boolean>;
  }>
>();

const route = useRoute();

const HEADER_PRIMARY_COLORED_ROUTES = [AppRoute.MyChannelMoment];

const isBackgroundShown = ref(false);
const isBackgroundHoverShown = computed(() => route.name !== AppRoute.MyChannelMoment);

const hasPrimaryColor = computed(() => route.name && HEADER_PRIMARY_COLORED_ROUTES.includes(route.name as AppRoute));

watch(
  hasPrimaryColor,
  (val) => {
    if (val) {
      return setThemeColor('#000000');
    }

    setThemeColor('#122121FF');
  },
  { immediate: isClient },
);

const onScroll = (event: Event) => {
  const target = event.target as HTMLElement;
  isBackgroundShown.value = target.scrollTop >= 20;
};

onMounted(() => {
  const element = document.querySelector('#inner-body');

  if (element) {
    element.addEventListener('scroll', onScroll);
  }
});

onBeforeUnmount(() => {
  const element = document.querySelector('#inner-body');

  if (element) {
    element.removeEventListener('scroll', onScroll);
  }
});
</script>

<style lang="scss" module>
@use '../../assets/breakpoints' as breakpoints;

.header {
  position: absolute;
  top: 0;
  z-index: var(--z-index-header);
  width: 100%;
}

.headerHover {
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: var(--color-bg-primary);
  }
}

.headerBackground {
  background-color: var(--color-bg-primary);
  transition: background-color 0.2s;
}

@include breakpoints.max-width-1024 {
  .primaryColor {
    background-color: var(--color-notheme-bg-primary);
  }
}
</style>
