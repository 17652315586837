import { useLogger } from '../platform/logger/use-logger';

export default defineNuxtPlugin({
  name: 'nuxt:chunk-reload',
  setup(nuxtApp) {
    const router = useRouter();
    const logger = useLogger();

    const chunkErrors = new Set();

    router.beforeEach(() => {
      chunkErrors.clear();
    });
    nuxtApp.hook('app:chunkError', ({ error }) => {
      chunkErrors.add(error);
    });

    router.onError((error: Error) => logger.error(error));
  },
});
