import type { Actor, ApiActor } from '@SDK/api';
import { getPersonName } from '@SDK/api';

import { ProcessingMapper } from '../base/processing-mapper';

export class ActorMapper {
  static mapMany(items: ApiActor[] = []): Actor[] {
    return ProcessingMapper.process(items.map(ActorMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiActor): Actor {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
