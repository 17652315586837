<template>
  <div
    v-show="breadcrumbs && breadcrumbs.length"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'breadcrumbs')"
    :class="$style.breadcrumbs"
  >
    <app-router-link
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="breadcrumb.url"
      :to="breadcrumb.url === undefined ? (undefined as string) : createExternalLink(breadcrumb.url)"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Footer, 'breadcrumbsItem')"
      :class="{
        [$style.breadcrumbsItem]: true,
        [$style.breadcrumbsItemDisabled]: isLastBreadcrumb(index) || breadcrumb.url === undefined,
        [$style.breadcrumbsItemLast]: isLastBreadcrumb(index),
      }"
    >
      {{ breadcrumb.label }}
    </app-router-link>
  </div>
</template>

<script lang="ts" setup>
import { isClient } from '@vueuse/core';
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';

import useLDJSONMarkup from '../code/linked-data-json-markup/use-ld-json-markup';
import { useRouteUtils } from '../platform/router/use-route-utils';
import type { BreadcrumbsItem } from '../stores/use-layout-store';
import AppRouterLink from './AppRouterLink';

const { $emitter } = useNuxtApp();
const { setBreadcrumbMarkup } = useLDJSONMarkup();
const { createExternalLink } = useRouteUtils();

const { data } = await useAsyncData<BreadcrumbsItem[]>(
  'breadcrumbs',
  () => new Promise((resolve) => $emitter.on('app.breadcrumbs.updated', (val) => resolve(val))),
  { server: true },
);

if (!isClient && data.value) {
  setBreadcrumbMarkup(data.value as BreadcrumbsItem[]);
}

const onBreadcrumbsUpdated = (val: BreadcrumbsItem[]) => {
  data.value = val;
  setBreadcrumbMarkup(val);
};

const breadcrumbs = computed(() => {
  if (data.value) {
    return data.value;
  }

  return [];
});

onBeforeMount(() => {
  $emitter.on('app.breadcrumbs.updated', onBreadcrumbsUpdated);
});

onBeforeUnmount(() => {
  data.value = null;

  $emitter.off('app.breadcrumbs.updated', onBreadcrumbsUpdated);
});

const isLastBreadcrumb = (breadcrumbIndex: number) =>
  breadcrumbIndex === (breadcrumbs.value as BreadcrumbsItem[]).length - 1;
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.breadcrumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: var(--g-spacing-24);
}

.breadcrumbsItem {
  @include fonts.WebLabel-2;
  line-height: 2 !important;
  color: var(--color-text-primary);

  &:not(.breadcrumbsItemLast):after {
    content: '/';
    display: inline-flex;
    margin: 0 var(--g-spacing-8);
    color: var(--color-text-tertiary);
  }

  &:not(.breadcrumbsItemDisabled):hover:after {
    color: var(--color-text-tertiary);
  }
}

.breadcrumbsItemDisabled {
  pointer-events: none;
  color: var(--color-text-tertiary);
}
</style>
