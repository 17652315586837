/**
 * @see https://datatracker.ietf.org/doc/html/rfc5646
 */
export enum AppLanguage {
  RU = 'ru',
  EN = 'en',
  AM = 'am',
}

export enum LanguageTitle {
  Ru = 'Русский',
  En = 'English',
  // Am = 'հայերեն',
  Am = 'Armenian',
}

export const getAppLanguageTitle = (language: AppLanguage) => {
  if (language === AppLanguage.RU) {
    return LanguageTitle.Ru;
  } else if (language === AppLanguage.EN) {
    return LanguageTitle.En;
  } else if (language === AppLanguage.AM) {
    return LanguageTitle.Am;
  }

  return language;
};

export function getLanguageByISO639Format(language: AppLanguage) {
  if (language === AppLanguage.RU) {
    return 'ru-RU';
  }

  if (language === AppLanguage.EN) {
    return 'en-US';
  }

  return 'ru-RU';
}

export const getLanguageIconTitle = (language: AppLanguage) => {
  const iconUrlTemplate = '/icons/countries/';

  if (language === AppLanguage.RU) {
    return iconUrlTemplate + 'ru.svg';
  } else if (language === AppLanguage.EN) {
    return iconUrlTemplate + 'en.svg';
  } else if (language === AppLanguage.AM) {
    return iconUrlTemplate + 'ar.svg';
  }

  return iconUrlTemplate + 'en.svg';
};
