import type { ApiVpnStatus, VpnStatus } from '@package/sdk/src/api';
import { VpnNoticeMapper } from '@package/sdk/src/api';
import { useNuxtApp } from 'nuxt/app';

import { ENDPOINTS } from '../../platform/http';

export function useVpnNoticeApi() {
  const { $http } = useNuxtApp();

  const checkVpnStatus = (): Promise<VpnStatus> => {
    return $http.get<ApiVpnStatus>(ENDPOINTS.VPN_NOTICE).then(VpnNoticeMapper.map);
  };

  return { checkVpnStatus };
}
