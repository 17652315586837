import { DurationUnit } from '../bonus-programs/promocode/duration-unit';
import { SubscriptionPlatform } from '../subscriptions/types/subscription-platform';

export enum OfferCardType {
  PROMO = 'promo',
  DEFAULT = 'default',
}

export interface IOfferCard {
  id: string;
  type: OfferCardType;
  title: string;
  subtitle: string;
  cardPrice: string;
  price: string;
  platform: SubscriptionPlatform;
  badge: string;
  bullets: string[];
  background: string;
  preBackground: string;
  activeByDefault: boolean;
  trialDuration: number;
  trialDurationUnit: DurationUnit;
}
