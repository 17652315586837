import type { ChannelProgram, ContentMoment, Media, Moment } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { isClient } from '@vueuse/core';
import normalizeUrl from 'normalize-url';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import type { RouteLocationNamedRaw } from 'vue-router';

import { useOffersStore } from '../../stores/use-offers-store';
import { ensureEndSlash } from '../base/string';
import useEnvironment from '../environment/use-environment';
import { RouteQuery } from './query';
import { AppRoute } from './routes';

const createAppLink = (route: AppRoute, ...paths: string[]): string => `/${route}/${paths.join('/')}`;

interface IGetContentRouteOptions {
  isPlayerFullscreen?: boolean;
}

export function useRouteUtils() {
  const route = useRoute();
  const headers = useRequestHeaders();
  const { isDev } = useEnvironment();
  const { isActiveSubscription } = storeToRefs(useOffersStore());

  const createExternalLink = (url: string) => ensureEndSlash(`${fullHostName.value}${url}`);

  const fullHostName = computed(() =>
    normalizeUrl(isClient ? window.location.host : `${headers.host}`, { forceHttps: !isDev }),
  );

  const fullRoute = computed(() =>
    route.path === '/'
      ? fullHostName.value
      : normalizeUrl(`${fullHostName.value}${route.path}/`, { removeTrailingSlash: false }),
  );

  const getContentExternalLink = (content: Media) => {
    const { slug, contentType } = content;

    const isMovieContent = contentType === MediaContentType.MOVIE;
    const contentRoute = isMovieContent ? AppRoute.Movies : AppRoute.Serials;

    return createExternalLink(createAppLink(contentRoute, slug));
  };

  const getContentRoute = (
    content: Media | Moment | ContentMoment | ChannelProgram,
    options: IGetContentRouteOptions = {},
  ): RouteLocationNamedRaw => {
    const { slug, serialId } = content as Media;
    const { contentId, contentSlug, contentType } = content as Moment | ContentMoment;

    const id = (() => {
      if (contentType === MediaContentType.EPISODE) {
        const watchingItem = (content as Media).watchingItem;

        if (watchingItem?.slug) {
          return watchingItem.slug;
        }

        return serialId as string;
      }

      return contentSlug || slug || contentId || content.id;
    })();

    const params: Record<string, string> = { id };

    const seasonNumber = (() => {
      if (contentType !== MediaContentType.EPISODE) {
        return;
      }

      const { seasonNumber: season } = content as ContentMoment;

      if (season) {
        return season;
      }

      const watchingItem = (content as Media).watchingItem;
      if (watchingItem?.seasonNumber) {
        return watchingItem.seasonNumber;
      }
    })();

    const episodeNumber = (() => {
      if (contentType !== MediaContentType.EPISODE) {
        return;
      }

      const { episodeNumber: episode } = content as ContentMoment;
      if (episode) {
        return episode;
      }

      const watchingItem = (content as Media).watchingItem;

      if (watchingItem?.episodeNumber) {
        return watchingItem.episodeNumber;
      }
    })();

    if (seasonNumber) {
      params.seasonId = String(seasonNumber);
    }

    if (episodeNumber) {
      params.episodeId = String(episodeNumber);
    }

    const name = (() => {
      if (contentType === MediaContentType.MOVIE) {
        return AppRoute.MovieCard;
      }

      if (episodeNumber) {
        return AppRoute.SerialEpisodeCard;
      }

      if (seasonNumber) {
        return AppRoute.SerialSeasonCard;
      }

      return AppRoute.SerialCard;
    })();

    const query = {};
    const { isPlayerFullscreen } = options;

    // Этот флаг, мы можем ставить только если юзер авторизован.
    if (isActiveSubscription.value && isPlayerFullscreen) {
      Reflect.set(query, RouteQuery.PlayerFullscreen, '1');
    }

    return { name, params, query };
  };

  return { fullRoute, fullHostName, getContentRoute, getContentExternalLink, createExternalLink, createAppLink };
}
