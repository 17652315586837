import { ProcessingMapper } from '../base/processing-mapper';
import { ApiSubscription } from './subscription-types';
import { Subscription } from './types/subscription';

export class SubscriptionMapper {
  static map({
    offer_id,
    canceled_at,
    duration_unit,
    trial_duration,
    trial_duration_unit,
    ends_at,
    price_currency,
    final_price_of_next_period,
    discount_of_next_period,
    ...rest
  }: ApiSubscription): Subscription {
    return ProcessingMapper.process({
      offerId: offer_id,
      canceledAt: canceled_at,
      durationUnit: duration_unit,
      trialDuration: trial_duration,
      trialDurationUnit: trial_duration_unit,
      endsAt: ends_at,
      priceCurrency: price_currency,
      finalPrice: final_price_of_next_period,
      discount: discount_of_next_period,
      ...rest,
    });
  }
}
