import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';

export function useSubscriptionAnalytics() {
  const onClickSubscribeManage = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManage,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManageRenew = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManageRenew,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManageBack = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManageBack,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManageChange = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManageChange,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManageOn = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManageOn,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManageSave = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManageSave,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManageCancel = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManageCancel,
      page: AnalyticPageName.Account,
    });
  };

  const onShowSubscribeManagePopup = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribeManagePopup,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeManagePopup = (isUserWantCancelSubscription: boolean) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeManagePopup,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Button,
          value: isUserWantCancelSubscription,
        },
      ],
    });
  };

  const onShowSubscribeCancelReasons = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribeCancelReasons,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeCancelReasonsStay = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeCancelReasonsStay,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeCancelReasonsProceed = (reasons: string[]) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeCancelReasonsProceed,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Reasons,
          value: reasons,
        },
      ],
    });
  };

  const onShowSubscribeCancelLastChance = () => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ShowSubscribeCancelLastChance,
      page: AnalyticPageName.Account,
    });
  };

  const onClickSubscribeCancelLastChance = (isUserWantCancelSubscription: boolean) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSubscribeCancelLastChance,
      page: AnalyticPageName.Account,
      values: [
        {
          label: AnalyticPayloadLabelName.Button,
          value: isUserWantCancelSubscription,
        },
      ],
    });
  };

  return {
    onShowSubscribeCancelReasons,
    onClickSubscribeCancelReasonsStay,
    onClickSubscribeCancelReasonsProceed,
    onClickSubscribeCancelLastChance,
    onShowSubscribeCancelLastChance,
    onShowSubscribeManagePopup,
    onClickSubscribeManagePopup,
    onClickSubscribeManageSave,
    onClickSubscribeManageOn,
    onClickSubscribeManageCancel,
    onClickSubscribeManage,
    onClickSubscribeManageChange,
    onClickSubscribeManageRenew,
    onClickSubscribeManageBack,
  };
}
