import type { JwtPayload } from 'jwt-decode';
import jwtDecode from 'jwt-decode';

import { useLogger } from '../logger/use-logger';

export type AuthTokenPayload = JwtPayload & {
  user_id: string;
  profile_id: string;
  visitor_id: string;
};

export function useJwtDecoder() {
  const logger = useLogger();

  const decode = (token: string): AuthTokenPayload | undefined => {
    try {
      return jwtDecode<AuthTokenPayload>(token);
    } catch (err) {
      logger.error('Unexpected situation: Bad JWT token.');
    }
  };

  return { decode };
}
