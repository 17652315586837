import type { ApiMoment, Moment } from '@package/sdk/src/api';
import { MomentMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export function useContentMomentsApi() {
  const { $http } = useNuxtApp();

  const fetchMoments = (contentId: string): Promise<Moment[]> => {
    return $http.get<ApiMoment[]>(ENDPOINTS.CONTENTS_MOMENTS, { params: { id: contentId } }).then(MomentMapper.mapMany);
  };

  const fetchMoment = (id: string): Promise<Moment> => {
    return $http.get<ApiMoment>(ENDPOINTS.MOMENT, { params: { id } }).then(MomentMapper.map);
  };

  return { fetchMoments, fetchMoment };
}
