<template>
  <app-modal-wrapper :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'deleteAccountSuccessModal')">
    <modal-title :title="$t('lk.deleteAccount.successModalTitle')" />
    <modal-description :description="$t('lk.deleteAccount.successModalDescription')" />

    <div :class="$style.buttons">
      <app-button
        variation="button-primary"
        :text="$t('lk.deleteAccount.successModalButtonText')"
        :title="$t('ariaLabel.devices.deleteReject')"
        :aria-label="$t('ariaLabel.devices.deleteReject')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'rejectRemoveButton')"
        @click="closeModal"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { useLayoutStore } from '../../stores/use-layout-store';
import AppButton from '../ui/AppButton.vue';
import ModalDescription from './ModalDescription.vue';
import ModalTitle from './ModalTitle.vue';

const layoutStore = useLayoutStore();

const closeModal = () => {
  layoutStore.setCurrentModalName(null);
};
</script>

<style lang="scss" module>
.buttons {
  display: grid;
  grid-template-columns: 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-24);
}
</style>
