import { AppVariation } from './interfaces';

const isDevAM = import.meta.env.VITE_AM_DEV;

export default function useVariationVariables() {
  const AM_VARIATION_HOSTNAMES = ['dev.viju.am', 'viju.am'];

  if (isDevAM) {
    AM_VARIATION_HOSTNAMES.push('localhost:3000');
  }

  const getAppVariation = (hostName: string) => {
    if (AM_VARIATION_HOSTNAMES.includes(hostName)) {
      return AppVariation.Am;
    }

    return AppVariation.Ru;
  };

  const getFlocktoryId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return '3931';
      default:
        return undefined;
    }
  };

  const getGoogleTagManagerId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 'GTM-W74KLJR';
      case AppVariation.Am:
        return 'GTM-NZTLDGNQ';
      default:
        return undefined;
    }
  };

  const getGoogleAnalyticsId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Am:
        return 'G-Y2CWNQEQ9N';
      default:
        return undefined;
    }
  };

  const getAppsFlyerId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
      case AppVariation.Am:
        return '60c6d80f-ed60-4b5d-b458-08326ff1c206';
      default:
        return undefined;
    }
  };

  const getYandexMetricaId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return '84842932';
      case AppVariation.Am:
        return '94437248';
      default:
        return undefined;
    }
  };

  const getVKId1 = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 'VK-RTRG-1158759-cEfPx';
      default:
        return undefined;
    }
  };

  const getVKId2 = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 'VK-RTRG-1638140-hKVSH';
      default:
        return undefined;
    }
  };

  const getTopmailId = (variation: AppVariation) => {
    switch (variation) {
      case AppVariation.Ru:
        return 3259859;
      case AppVariation.Am:
        return 3522935;
      default:
        return undefined;
    }
  };

  return {
    AM_VARIATION_HOSTNAMES,
    getAppVariation,
    getGoogleAnalyticsId,
    getAppsFlyerId,
    getTopmailId,
    getVKId1,
    getVKId2,
    getYandexMetricaId,
    getGoogleTagManagerId,
    getFlocktoryId,
  };
}
