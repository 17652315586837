import type { Bonus, PromocodeData } from '@package/sdk/src/api';
import { defineStore } from 'pinia';
import { ref } from 'vue';

export const usePromocodeStore = defineStore('promocode', () => {
  const savedPromocode = ref('');

  const setSavedPromocode = (value: string) => {
    savedPromocode.value = value;
  };

  const promocodeCheckInfo = ref<PromocodeData>();
  const setPromocodeCheckInfo = (value?: PromocodeData) => {
    promocodeCheckInfo.value = value;
  };

  const appliedBonusInfo = ref<Bonus>();
  const setAppliedBonusInfo = (value: Bonus) => {
    appliedBonusInfo.value = value;
  };

  const reset = () => {
    promocodeCheckInfo.value = undefined;
    appliedBonusInfo.value = undefined;
    savedPromocode.value = '';
  };

  return {
    reset,
    appliedBonusInfo,
    setAppliedBonusInfo,
    savedPromocode,
    setSavedPromocode,
    promocodeCheckInfo,
    setPromocodeCheckInfo,
  };
});
