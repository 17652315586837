import type { IDisposable } from '@package/sdk/src/core';
import { getFirstElement, indexOutOfRange, isUndefinedOrNull, toDisposable } from '@package/sdk/src/core';
import { nanoid } from 'nanoid';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import type { IBaseNotification, ISimpleNotification } from '../code/notifications/notification';
import { AppLanguage } from '../platform/localization/language';
import { useLogger } from '../platform/logger/use-logger';
import { AppRoute } from '../platform/router/routes';

export interface BreadcrumbsItem {
  url: string;
  label: string;
}

export type CurrentModalName =
  | 'DeleteAccountConfirmModal'
  | 'DeleteAccountHasActiveSubscriptionModal'
  | 'DeleteAccountSuccessModal'
  | 'ParentalControlModal'
  | 'ParentalControlProfileModal'
  | 'ReferralModal'
  | 'TvPromoModal'
  | 'QuizModal'
  | 'CloudpaymentsWidgetModal'
  | 'DeleteDeviceModal'
  | 'LogoutModal'
  | 'ConfirmDisableAutoRenewalModal'
  | 'BonusDisableAutoRenewalModal'
  | 'ReasonsDisableAutoRenewalModal'
  | 'ShareLinkModal'
  | 'ExploreMyChannelModal'
  | 'WatchAllEpisodesModal';

interface ScrollPositionBase {
  top?: number;
  left?: number;
}

interface ScrollPositionNormalized extends ScrollPositionBase {
  behavior?: ScrollOptions['behavior'];
}

const LayoutModalWeight: Record<CurrentModalName, number> = {
  ParentalControlModal: 10,
  DeleteAccountConfirmModal: 9,
  DeleteAccountHasActiveSubscriptionModal: 9,
  DeleteAccountSuccessModal: 9,
  CloudpaymentsWidgetModal: 9,
  ParentalControlProfileModal: 5,
  ShareLinkModal: 5,
  ExploreMyChannelModal: 5,
  TvPromoModal: 5,
  DeleteDeviceModal: 5,
  LogoutModal: 5,
  ConfirmDisableAutoRenewalModal: 5,
  BonusDisableAutoRenewalModal: 5,
  ReasonsDisableAutoRenewalModal: 5,
  WatchAllEpisodesModal: 5,
  ReferralModal: 1,
  QuizModal: 1,
};

function getModalWeight(modalName: CurrentModalName): number {
  return LayoutModalWeight[modalName] || 1;
}

interface SetCurrentModalNameOptions {
  hasCloseIcon?: boolean;
  options?: any;
}
export const useLayoutStore = defineStore('layout', () => {
  const logger = useLogger();

  const currentPageKey = ref(nanoid(2));
  const forceReRenderPage = () => {
    currentPageKey.value = nanoid(2);
  };

  const currentAppLanguage = ref<AppLanguage>(AppLanguage.RU);
  const setCurrentAppLanguage = (value: AppLanguage) => {
    currentAppLanguage.value = value;
  };

  const scrollPositionMap = ref<Record<string, ScrollPositionBase>>({});
  const saveScrollPosition = (route: AppRoute, position: ScrollPositionBase) => {
    scrollPositionMap.value[route] = position;
  };
  const clearScrollPosition = (route: AppRoute) => {
    scrollPositionMap.value[route] = {};
  };

  const currentModalName = ref<CurrentModalName | null>(null);
  const hasModalCloseIcon = ref(true);
  const modalOptions = ref<any>({});

  const hasActiveLayoutModal = computed(() => !isUndefinedOrNull(currentModalName.value));

  const disposeModal = () => {
    const { $emitter } = useNuxtApp();

    currentModalName.value = null;

    $emitter.emit('app.modal.disposed');
    modalOptions.value = {};
    hasModalCloseIcon.value = true;
    currentModalName.value = null;
  };

  const setIsCloseableModal = (value: boolean) => {
    hasModalCloseIcon.value = value;
  };

  const setCurrentModalName = (name: CurrentModalName | null, options?: SetCurrentModalNameOptions) => {
    const { $emitter } = useNuxtApp();

    if (isUndefinedOrNull(name)) {
      return disposeModal();
    }

    const doOpenModal = () => {
      currentModalName.value = name;
      hasModalCloseIcon.value = options?.hasCloseIcon ?? true;
      modalOptions.value = options?.options;
      $emitter.emit('app.modal.created', name);
    };

    // Нет активной модалки - все круто
    if (!currentModalName.value) {
      doOpenModal();
    } else {
      const activeModalNameWeight = getModalWeight(currentModalName.value);
      const newModalNameWeight = getModalWeight(name);

      // Если новая модалка по весу выше.
      if (newModalNameWeight >= activeModalNameWeight) {
        doOpenModal();
      }
    }
  };

  const scrollPosition = ref<ScrollPositionNormalized>();
  const setScrollPosition = (position: ScrollPositionNormalized) => {
    scrollPosition.value = position;
  };

  const notifications = ref<ISimpleNotification[]>([]);
  const currentNotification = computed(() => getFirstElement(notifications.value));
  const removeNotification = (notificationId: string) => {
    const index = notifications.value.findIndex((notification) => notification.id === notificationId);

    if (indexOutOfRange(index)) {
      return logger.error('removeNotification - index cant be < 0');
    }

    notifications.value.splice(index, 1);
  };

  const addNotification = (notification: IBaseNotification): IDisposable => {
    if (currentNotification.value) {
      removeNotification(currentNotification.value.id);
    }

    // Добавляем туда id
    const notificationId = nanoid(2);
    Reflect.set(notification, 'id', notificationId);

    notifications.value.push(notification as ISimpleNotification);
    return toDisposable(() => removeNotification(notificationId));
  };

  return {
    currentAppLanguage,
    setCurrentAppLanguage,
    currentPageKey,
    forceReRenderPage,
    scrollPositionMap,
    saveScrollPosition,
    clearScrollPosition,
    scrollPosition,
    setScrollPosition,
    currentModalName,
    hasModalCloseIcon,
    modalOptions,
    hasActiveLayoutModal,
    setCurrentModalName,
    setIsCloseableModal,
    currentNotification,
    notifications,
    removeNotification,
    addNotification,
  };
});
