import { isClient } from '@vueuse/core';

import { isIOS } from '../../platform/base/platform';

export enum OperationSystem {
  WindowsPhone = 'Windows Phone',
  Android = 'Android',
  IOS = 'iOS',
  Windows = 'Windows',
  MacOS = 'macOS',
  Linux = 'Linux',
  Unknown = 'Unknown',
}

export enum AppBrowser {
  Chrome = 'Chrome',
  Firefox = 'Firefox',
  Safari = 'Safari',
  Opera = 'Opera',
  Unknown = 'Unknown',
}

export const PLATFORM_NAME = 'web';

export function getOperationSystemName(): OperationSystem {
  if (process.server) {
    return OperationSystem.Unknown;
  }

  const userAgent = window.navigator.userAgent;

  if (/windows phone/i.test(userAgent)) {
    return OperationSystem.WindowsPhone;
  }

  if (/android/i.test(userAgent)) {
    return OperationSystem.Android;
  }

  if (isIOS) {
    return OperationSystem.IOS;
  }

  if (userAgent.includes('Win')) {
    return OperationSystem.Windows;
  }

  if (userAgent.includes('Mac')) {
    return OperationSystem.MacOS;
  }

  if (userAgent.includes('Linux')) {
    return OperationSystem.Linux;
  }

  return OperationSystem.Unknown;
}

export function getWindowsVersion(): string {
  if (isClient) {
    return '';
  }

  return window.navigator.appVersion.split('NT')[1].split(';')[0].trim();
}

export function getIOSVersion() {
  if (!isClient) {
    return '';
  }

  const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
  let version;

  if (match !== undefined && match !== null) {
    version = [parseInt(match[1], 10), parseInt(match[2], 10), parseInt(match[3] || '0', 10)];

    return String(parseFloat(version.join('.')));
  }

  return '';
}

export function getAndroidVersion(): string {
  if (!isClient) {
    return '';
  }

  const userAgent = navigator.userAgent.toLowerCase();
  const match = userAgent.match(/android\s([0-9.]*)/i);
  return match ? match[1] : '';
}

export function getBrowserName(): AppBrowser {
  const userAgent = window.navigator.userAgent;

  if (/OPR/i.test(userAgent)) {
    return AppBrowser.Opera;
  }

  // с помощью CriOS определяем Chrome на ios
  if (/Chrome|CriOS/i.test(userAgent)) {
    return AppBrowser.Chrome;
  }

  // с помощью FxiOS определяем Firefox на ios
  if (/Firefox|FxiOS/i.test(userAgent)) {
    return AppBrowser.Firefox;
  }

  if (/Safari/i.test(userAgent)) {
    return AppBrowser.Safari;
  }

  return AppBrowser.Unknown;
}
