import { isClient } from '@vueuse/core';
import { storeToRefs } from 'pinia';

import useEnvironment from '../../platform/environment/use-environment';
import useHostVariation from '../../platform/variation/use-host-variation';
import { useOffersStore } from '../../stores/use-offers-store';
import { useSessionStore } from '../../stores/use-session-store';

export default function useAsyncPlayer() {
  const sessionsStore = useSessionStore();
  const offersStore = useOffersStore();
  const { user } = storeToRefs(sessionsStore);
  const { currentOffer } = storeToRefs(offersStore);

  const { appCurrency } = useHostVariation();
  const { isRelease } = useEnvironment();

  const loadPlayer = async () => {
    const chunk = await import('@package/media-player/src/player/index');

    const { WebVijuPlayer } = chunk;

    WebVijuPlayer.setCurrency(appCurrency.value);
    WebVijuPlayer.setEnvironment(isRelease ? 'production' : 'development');

    if (isClient && window.$vijuPlayer?.logger) {
      window.$vijuPlayer.logger.setLevel(6);
    }

    WebVijuPlayer.setSession({
      user: user.value as any,
      offer: currentOffer.value as any,
    });

    return chunk;
  };

  return {
    loadPlayer,
  };
}
