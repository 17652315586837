<template>
  <app-slot-button :class="$style.connectTvButton" @click="onClick">
    {{ $t('header.connectTV') }}
  </app-slot-button>
</template>

<script lang="ts" setup>
import { AppRoute } from '../../platform/router/routes';
import AppSlotButton from '../ui/AppSlotButton.vue';

const route = useRoute();

const onClick = () => {
  navigateTo({
    name: AppRoute.AccountDevices,
    query: route.query,
  });
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.connectTvButton {
  @include fonts.WebLabel-2;
  margin-top: auto;
  margin-bottom: var(--g-spacing-16);
  padding: var(--g-spacing-8) var(--g-spacing-10);
  border-radius: var(--g-border-round-8);
  background-color: var(--color-notheme-bg-secondary-80);
}
</style>
