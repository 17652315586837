import {
  Disposable,
  EventEmitter,
  IDisposable,
  isObject,
  isUndefinedOrNull,
  UnexpectedComponentStateError,
} from '@package/sdk/src/core';
import scheduler from '@PLAYER/player/modules/performance/performance-scheduler';
import { isClient } from '@vueuse/core';

export enum LogLevel {
  Trace,
  Debug,
  Info,
  Warning,
  Error,
  Critical,
  Off,
}

interface LogMessageOptions {
  levelStr: string;
  css: string;
  message: string;
  args: any[];
  level: LogLevel;
}

export class LogMessage extends Disposable {
  public readonly date = new Date().toLocaleTimeString();
  public readonly playerId: string = '';

  constructor(
    public readonly levelStr: string,
    public readonly css: string,
    public readonly message: string,
    public readonly args: any[],
  ) {
    super();

    if (isClient && window.$vijuPlayer?.currentPlayerId) {
      this.playerId = window.$vijuPlayer.currentPlayerId;
    }
  }

  public [Symbol.toPrimitive](hint: 'string' | 'number' | 'default'): unknown {
    if (hint === 'string') {
      return this.toString();
    }

    return true;
  }

  public toString(): string {
    return this.message;
  }

  public log(): void {
    const { levelStr, css, message, args } = this;
    console.log(levelStr, css, message, ...args);
  }
}

export interface Logger {
  setLevel(level: LogLevel): void;

  getLevel(): LogLevel;

  subscribe(callback: (log: LogMessage) => void): IDisposable;

  trace(message: string, ...args: any[]): void;

  debug(message: string, ...args: any[]): void;

  info(message: string, ...args: any[]): void;

  warn(message: string, ...args: any[]): void;

  error(message: string, ...args: any[]): void;

  critical(message: string, ...args: any[]): void;
}

interface LoggerEventMap {
  message: LogMessage;
}

class LogBuffer extends Disposable {
  private _buffer: LogMessage[] = [];
  private readonly _MAX_BUFFER_SIZE: number = 200;

  constructor() {
    super();
  }

  public add(log: LogMessage): void {
    if (this._buffer.length >= this._MAX_BUFFER_SIZE) {
      this._buffer.shift();
    }

    this._buffer.push(log);
  }

  public get messages(): LogMessage[] {
    return this._buffer;
  }

  private clear(): void {
    this._buffer = [];
  }

  public dispose() {
    this.clear();
  }
}

class LoggerInstance implements Logger {
  #level: LogLevel = LogLevel.Info;
  readonly #emitter: EventEmitter<LoggerEventMap> = new EventEmitter<LoggerEventMap>();
  readonly #buffer: LogBuffer = new LogBuffer();

  public setLevel(level: LogLevel): void {
    if (isUndefinedOrNull(level)) {
      throw new UnexpectedComponentStateError('AbstractLogService#setLevel - no log level provided');
    }

    this.#level = level;

    this.doCreateLogMessage({
      levelStr: 'INFO',
      css: 'color: #33f',
      message: `Video Player: Log level was change to: ${LogLevel[level]}`,
      args: [],
      level: LogLevel.Info,
    });
  }

  public subscribe(callback: (log: LogMessage) => void): IDisposable {
    const disposable = this.#emitter.on('message', callback);
    this.logExistBuffer();

    return disposable;
  }

  public getLevel(): LogLevel {
    return this.#level;
  }

  constructor(logLevel: LogLevel = LogLevel.Critical) {
    this.setLevel(logLevel);
  }

  public trace(message: string, ...args: any[]): void {
    this.doCreateLogMessage({
      levelStr: 'TRACE',
      css: 'color: #888',
      message,
      args,
      level: LogLevel.Trace,
    });
  }

  public debug(message: string, ...args: any[]): void {
    this.doCreateLogMessage({
      levelStr: 'DEBUG',
      css: 'background: #eee; color: #888',
      message,
      args,
      level: LogLevel.Debug,
    });
  }

  public info(message: string, ...args: any[]): void {
    this.doCreateLogMessage({
      levelStr: 'INFO',
      css: 'color: #33F',
      message,
      args,
      level: LogLevel.Info,
    });
  }

  public warn(message: string, ...args: any[]): void {
    this.doCreateLogMessage({
      levelStr: 'WARN',
      css: 'color: #993',
      message,
      args,
      level: LogLevel.Warning,
    });
  }

  public error(message: string, ...args: any[]): void {
    if (this.getLevel() <= LogLevel.Error) {
      this.doCreateLogMessage({
        levelStr: 'ERR',
        css: 'color: #f33',
        message,
        args,
        level: LogLevel.Error,
      });
    }
  }

  public critical(message: string, ...args: any[]): void {
    this.doCreateLogMessage({
      levelStr: 'CRITI',
      css: 'background: #f33; color: white',
      message,
      args,
      level: LogLevel.Critical,
    });
  }

  private logExistBuffer(): void {
    this.#buffer.messages.forEach((msg) => this.#emitter.emit('message', msg));
  }

  private doCreateLogMessage(options: LogMessageOptions): void {
    void scheduler.postTask(
      () => {
        const { levelStr, css, message, args = [], level } = options;

        const logArgs = args.map((arg) => (isObject(arg) ? JSON.stringify(arg) : arg));

        const logMessage = new LogMessage(levelStr, css, message, logArgs);

        this.#buffer.add(logMessage);

        if (this.getLevel() <= level) {
          logMessage.log();
        }

        this.#emitter.emit('message', logMessage);
      },
      {
        priority: 'background',
      },
    );
  }
}

const logger = new LoggerInstance();

export default logger;
