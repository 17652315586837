import type { ApiDeviceSession, ApiSession, DeviceSession, Session } from '@package/sdk/src/api';
import { DeviceSessionMapper, SessionMapper } from '@package/sdk/src/api';
import { useNuxtApp } from 'nuxt/app';

import { ENDPOINTS } from '../../platform/http';
import type { IUserCreateSession } from './use-users-api';

export function useSessionsApi() {
  const { $http } = useNuxtApp();

  const getSessions = (active = false): Promise<DeviceSession[]> => {
    return $http
      .get<ApiDeviceSession[]>(ENDPOINTS.SESSIONS_BASE, { query: { active } })
      .then(DeviceSessionMapper.mapMany);
  };

  const refresh = (refreshToken: string): Promise<Session> => {
    return $http
      .post<ApiSession>(
        ENDPOINTS.SESSIONS_REFRESH,
        { refresh_token: refreshToken },
        {
          signRequest: true,
          isForceAPIAuthToken: true,
        },
      )
      .then(SessionMapper.map);
  };

  const restore = (token: string, userInfo: IUserCreateSession): Promise<Session> => {
    return $http
      .post<ApiSession>(
        ENDPOINTS.SESSIONS_BASE,
        {
          access_token: token,
          ...userInfo,
        },
        {
          signRequest: true,
        },
      )
      .then(SessionMapper.map);
  };

  return { getSessions, refresh, restore };
}
