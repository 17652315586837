import { isClient } from '@vueuse/core';
import { computed } from 'vue';

import { AppVariation } from '../variation/interfaces';
import useHostVariation from '../variation/use-host-variation';

/**
 * @description
 * ИСПОЛЬЗОВАТЬ ТОЛЬКО ДЛЯ ОТЛАДКИ
 *
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DebugProdUrls = {
  [AppVariation.Ru]: 'https://api.viju.ru/',
  [AppVariation.Am]: 'https://api.viju.am/',
};
/**
 * @description
 * ИСПОЛЬЗОВАТЬ ТОЛЬКО ДЛЯ ОТЛАДКИ
 *
 * @deprecated
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DebugFlagsProdUrls = {
  [AppVariation.Ru]: 'https://flags.viju.ru/',
  [AppVariation.Am]: 'https://flags.viju.am/',
};

export default function useEnvironment() {
  const runtimeConfig = useRuntimeConfig();
  const { appVariation } = useHostVariation();

  const {
    API_BASE_URL,
    API_FLAGS_BASE_URL,
    API_BASE_URL_AM,
    API_FLAGS_BASE_URL_AM,
    IS_DEV,
    IS_RELEASE_MODE,
    IS_STAND_RELEASE,
    DSML_PASSWORD,
    DSML_PARTNER_ID,
    APP_VERSION,
    IS_TEST,
  } = runtimeConfig.public;

  const isDev = IS_DEV;
  const isStandRelease = IS_STAND_RELEASE === 'true';
  const isRelease = IS_RELEASE_MODE === 'true';
  const isTest = IS_TEST;

  const dsmlLogin = DSML_PARTNER_ID;
  const dsmlPassword = DSML_PASSWORD;
  const appVersion = APP_VERSION as string;

  const apiBaseUrlRu = (() => {
    if (!isRelease && isClient) {
      return API_BASE_URL;
    }

    return API_BASE_URL;
  })();

  const apiBaseUrlAm = (() => {
    if (!isRelease && isClient) {
      return API_BASE_URL_AM;
    }

    return API_BASE_URL_AM;
  })();

  const apiFlagsBaseUrlRu = (() => {
    if (!isRelease && isClient) {
      return API_FLAGS_BASE_URL;
    }

    return API_FLAGS_BASE_URL;
  })();

  const apiFlagsBaseUrlAm = (() => {
    if (!isRelease && isClient) {
      return API_FLAGS_BASE_URL_AM;
    }

    return API_FLAGS_BASE_URL_AM;
  })();

  const normalizedApiBaseUrl = computed(() => (appVariation.value === AppVariation.Am ? apiBaseUrlAm : apiBaseUrlRu));
  const normalizedApiFlagsBaseUrl = computed(() =>
    appVariation.value === AppVariation.Am ? apiFlagsBaseUrlAm : apiFlagsBaseUrlRu,
  );

  return {
    normalizedApiBaseUrl,
    normalizedApiFlagsBaseUrl,
    isDev,
    isStandRelease,
    apiBaseUrlAm,
    apiFlagsBaseUrlAm,
    apiBaseUrlRu,
    apiFlagsBaseUrlRu,
    appVersion,
    isRelease,
    dsmlLogin,
    dsmlPassword,
    isTest,
  };
}
