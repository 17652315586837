<template>
  <app-modal-wrapper :max-width="360">
    <modal-title :class="$style.title" :title="$t('shareLinkModal.title')" />
    <div :class="$style.wrapper">
      <app-input
        v-model="modalOptions.link"
        variation="primary"
        :has-button="true"
        :button-text="$t('shareLinkModal.button')"
        @click="onClick"
      />

      <transition name="fade">
        <app-label v-if="isNotificationShown" :class="$style.label" position="absolute" :top="-56">
          {{ $t('page.myChannel.tooltip.linkCopySuccess') }}
        </app-label>
      </transition>
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { copyToClipboard } from '@package/sdk/src/core';
import { ref } from 'vue';

import { useLayoutStore } from '../../stores/use-layout-store';
import AppInput from '../ui/AppInput.vue';
import AppLabel from '../ui/AppLabel.vue';
import ModalTitle from './ModalTitle.vue';

const { modalOptions } = useLayoutStore();
let timeoutId: number;

const isNotificationShown = ref(false);

const onClick = () => {
  copyToClipboard(modalOptions.link);

  if (isNotificationShown.value) {
    isNotificationShown.value = false;
    window.setTimeout(() => {
      isNotificationShown.value = true;
    }, 200);

    window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => {
      isNotificationShown.value = false;
    }, 3000);

    return;
  }

  isNotificationShown.value = true;
  timeoutId = window.setTimeout(() => {
    isNotificationShown.value = false;
  }, 3000);
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.title {
  margin-bottom: var(--g-spacing-24);
}

.wrapper {
  position: relative;
}

.label {
  @include fonts.WebBody-2;
  right: 63px;
  z-index: 2;
  margin: 0 0 0 auto;
  width: fit-content;
  height: 40px;
  background-color: var(--color-bg-primary);
}
</style>
