import type { FeatureToggleValue } from './feature';

export enum FeatureToggle {
  QuizModal = 'web_quiz_modal',
  MatchPercent = 'web_match_percent',
  ImdbRating = 'web_imdb_rating',

  SmartCaptcha = 'web_smart_captcha',
  OAuthVK = 'web_oauth_vk',
  OAuthYandex = 'web_oauth_yandex',

  TvChannelOnMain = 'web_is_tv_channel_on_main',
  FlocktoryExchange = 'web_flocktory_exchange',
  IsCloudPaymentsEnabled = 'web_is_cloudpayments_enabled',

  ChromecastEnabled = 'web_chromecast_enabled',
  ButtonVersion = 'web_button_version',

  IsReferralProgramPopup = 'isReferralProgramPopup',
}

export const defaultFeatureToggleValues: FeatureToggleValue[] = [
  { name: FeatureToggle.ChromecastEnabled, status: false },
  { name: FeatureToggle.QuizModal, status: false },
  { name: FeatureToggle.ImdbRating, status: true },
  { name: FeatureToggle.SmartCaptcha, status: true },
  { name: FeatureToggle.OAuthVK, status: true },
  { name: FeatureToggle.OAuthYandex, status: true },
  { name: FeatureToggle.TvChannelOnMain, status: false },
  { name: FeatureToggle.FlocktoryExchange, status: true },
  { name: FeatureToggle.IsCloudPaymentsEnabled, status: false },
  { name: FeatureToggle.ButtonVersion, status: false },
  { name: FeatureToggle.IsReferralProgramPopup, status: false },
];

export interface FeatureToggleVariantTypeMap {
  [FeatureToggle.ButtonVersion]: 'group_a' | 'group_b';
  [key: string]: string;
}
