<template>
  <div :class="$style.authInput">
    <app-input
      :ref="(comp) => (loginInputEl = comp?.$el)"
      v-model="loginModel"
      :type="type"
      :autocomplete="autocomplete"
      :name="name"
      :autofocus="true"
      aria-autocomplete="inline"
      :has-clear-icon="loginModel.length > 0"
      :mask="mask"
      :placeholder="placeholder"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Auth, 'loginInput')"
      :button-data-id="$getTestElementIdentifier($ElementTestIdentifierScope.Auth, 'clearLoginButton')"
      :error-message="errorMessage"
      @clear="onClearLoginButtonClick"
    />
    <input
      v-if="autocomplete !== 'off'"
      aria-hidden="true"
      :class="$style.hidden"
      name="hiddenPassword"
      tabindex="-1"
      type="password"
    />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import regex from '../../code/auth/regex';
import { isFirefox } from '../../platform/base/platform';
import { isContainsLetters, onlyDigits } from '../../platform/base/string';
import AppInput from '../ui/AppInput.vue';

const props = withDefaults(
  defineProps<{
    modelValue: string;
    autofocus?: boolean;
    name?: 'login';
    type?: 'email' | 'text';
    autocomplete?: 'email' | 'new-password' | 'current-password' | 'off';
    placeholder?: string;
  }>(),
  {
    type: 'text',
    name: 'login',
    autocomplete: 'email',
    autofocus: false,
  },
);

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void;
}>();

const errorMessage = ref('');
const loginInputEl = ref<HTMLInputElement>();

defineExpose({
  errorMessage,
});

const onClearLoginButtonClick = () => {
  loginModel.value = '';
  errorMessage.value = '';

  loginInputEl.value?.focus();
};

const loginModel = computed({
  get: () => props.modelValue,
  set: (val: string) => {
    if (isContainsLetters(val)) {
      if (val[0] === '+') {
        return emit('update:modelValue', val.replace('+', ''));
      }

      return emit('update:modelValue', val);
    }

    emit('update:modelValue', val);
  },
});

const phoneMasks = {
  n12: '+############',
  n11: '###########',
  c_ccc_nnn_nn_nn: '+# (###) ### ## ##',
  cc_ccc_nnn_nn_nn: '+## (###) ### ## ##',
  ccc_cc_nn_nn: '+### (##) ### ## ##',
  ccc_cc_nnn_nnn: '+### (##) ### ###',
  ccc_cc_nnn_nn_nn: '+### #########',
  ccc_n8: '+### ########',
  ccc_n9: '+### #########',
  ccc_ccc_nn_nn_nn: '+### (###) ## ## ##',
};

interface IPhoneData {
  code: string;
  country: string;
  mask: string;
}

const phoneDataList: IPhoneData[] = [
  {
    code: '7',
    country: 'russia',
    mask: phoneMasks.c_ccc_nnn_nn_nn,
  },
  {
    code: '77',
    country: 'kazakhstan',
    mask: phoneMasks.c_ccc_nnn_nn_nn,
  },
  {
    code: '373',
    country: 'moldova',
    mask: phoneMasks.ccc_cc_nnn_nnn,
  },
  {
    code: '374',
    country: 'armenia',
    mask: phoneMasks.ccc_n8,
  },
  {
    code: '375',
    country: 'belarus',
    mask: phoneMasks.ccc_cc_nnn_nn_nn,
  },
  {
    code: '380',
    country: 'ukraine',
    mask: phoneMasks.ccc_cc_nnn_nn_nn,
  },
  {
    code: '992',
    country: 'tajikistan',
    mask: phoneMasks.ccc_cc_nnn_nn_nn,
  },
  {
    code: '994',
    country: 'azerbaijan',
    mask: phoneMasks.ccc_cc_nnn_nn_nn,
  },
  {
    code: '996',
    country: 'kyrgyzstan',
    mask: phoneMasks.ccc_ccc_nn_nn_nn,
  },
  {
    code: '998',
    country: 'uzbekistan',
    mask: phoneMasks.ccc_cc_nnn_nn_nn,
  },
];

const getMaskForPhone = (phoneNumber: string): string | undefined => {
  const phoneDigits = onlyDigits(phoneNumber);

  // Hack for russian and kazakh numbers
  if (phoneNumber.startsWith('8')) {
    return phoneMasks.n11;
  }

  return phoneDataList.find((item) => item.code === phoneDigits.substring(0, item.code.length))?.mask;
};

const digitsPhoneModel = computed(() => onlyDigits(loginModel.value));

const isPhone = computed(() => {
  // в хроме при подстановке автокомплита props.modelValue равен '',
  // но при этом match(regex.phone) возвращает совпадение пустой строки,
  // поэтому дополнительно проверяем на пустую строку
  if (props.modelValue) {
    return loginModel.value.match(regex.phone);
  }

  return false;
});

const mask = computed(() => {
  if (isFirefox || !isPhone.value) {
    // удаляем пробелы из строки
    loginModel.value = loginModel.value.replace(' ', '');

    return undefined;
  }

  return getMaskForPhone(digitsPhoneModel.value) || phoneMasks.n12;
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.authInput {
  position: relative;
  width: 100%;
  margin-bottom: var(--g-spacing-16);
}

.authClear {
  position: absolute;
  top: 20px;
  right: 15px;
}

.loginError {
  @include fonts.WebBody-2;
  width: 100%;
  color: var(--color-text-negative);
}

.hidden {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
</style>
