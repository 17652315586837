export enum DurationUnit {
  Hour = 'hour',
  Day = 'day',
  Month = 'month',
}

export enum RedirectSymbol {
  Offers = 'offers',
  OfferPromo = 'offer-promo',
  Index = 'index',
  Profile = 'profile',
  Subscription = 'subscription',
  Auth = 'auth',
}
