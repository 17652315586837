import 'core-js/modules/es.array.at';
import 'core-js/modules/es.string.at-alternative';

import { isDefined } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { getClientIp } from 'request-ip';
import { v4 } from 'uuid';

import { useAdmitadUserId } from '@/code/admitad/use-admitad-user-id';
import CodeApplication from '@/code/application-instance/code-application';
import useAuthActions from '@/code/auth/use-auth-actions';
import { useAppTheme } from '@/code/layout/use-app-theme';
import { usePageMeta } from '@/code/seo/use-page-meta';
import useTvPromoPopupActions from '@/code/tv/use-tv-promo-popup-actions';
import { useVpnNoticeApi } from '@/code/vpn/use-vpn-notice-api';
import { executeWithSafeClientContext } from '@/platform/base/function';
import { CookieName, cookies, maxAge } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { useLogger } from '@/platform/logger/use-logger';
import { useHostname } from '@/platform/network/use-hostname';
import { RouteQuery } from '@/platform/router/query';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useLocalStorage } from '@/platform/storage/use-local-storage';
import useHostVariation from '@/platform/variation/use-host-variation';
import { useCatalogStore } from '@/stores/use-catalog-store';
import { useFeaturesStore } from '@/stores/use-features-store';
import { useOffersStore } from '@/stores/use-offers-store';
import { usePromocodeStore } from '@/stores/use-promocode-store';
import { useSessionStore } from '@/stores/use-session-store';
import { type AppConfig, useSystemStore } from '@/stores/use-system-store';
import { CACHE_VERSION } from '@/stores/use-system-store';

declare const window: Window &
  typeof globalThis & {
    startTime?: number;
    accessToken?: string;
  };

export default defineNuxtPlugin(async (app) => {
  const { appVariation } = useHostVariation();
  const { $http } = useNuxtApp();

  console.info('%c INFO', 'color: #33f', `APP_VARIATION - ${appVariation.value}`);

  const route = useRoute();
  const offersStore = useOffersStore();
  const featuresStore = useFeaturesStore();
  const sessionStore = useSessionStore();
  const catalogStore = useCatalogStore();
  const promocodeStore = usePromocodeStore();
  const localStorage = useLocalStorage();
  const hostName = useHostname();

  const { sendAdmitadEvent } = useAdmitadUserId();
  const tvPromoPopupActions = useTvPromoPopupActions();
  const { restoreSavedPromocode } = useAuthActions();
  const { setupDefaultSeoMetadata } = usePageMeta();
  const { checkVpnStatus } = useVpnNoticeApi();
  const { isVpnDetected, config } = storeToRefs(useSystemStore());

  const logger = useLogger();

  const { isAuth } = storeToRefs(sessionStore);
  const { promocodeCheckInfo } = storeToRefs(promocodeStore);

  const accessToken = route.query[RouteQuery.AccessToken] as string;
  const application = new CodeApplication();
  const { initializeTheme } = useAppTheme();

  const createVisitorId = () => {
    useAppCookie(CookieName.VisitorId, { maxAge: cookies.visitorId.maxAge, path: '/', default: () => v4() });
  };

  initializeTheme();
  setupDefaultSeoMetadata();
  createVisitorId();

  const resolveSmartTvCode = () => {
    const tvCodeQuery = route.query[RouteQuery.TvSmartCode] as string;

    if (!tvCodeQuery) {
      return;
    }

    sessionStore.setSavedSmartTvCode(tvCodeQuery);
  };

  const loadSessionFromQueryAccessToken = async (token: string) => {
    try {
      await sessionStore.loginUserWithAccessToken(token);
    } catch (error) {
      await sessionStore.clearSession();

      throw error;
    }
  };

  const fetchStaticInformation = async () => {
    const isCountriesLoaded = catalogStore.countries?.length > 0;
    const isGenresLoaded = catalogStore.genres?.length > 0;
    const isFeaturesLoaded = featuresStore.features?.length > 0;
    const isPromocodeCheckInfoLoaded = isDefined(promocodeCheckInfo.value);
    const isYearsLoaded = catalogStore.years?.length > 0;

    await Promise.all([
      isCountriesLoaded ? Promise.resolve(undefined) : catalogStore.fetchCountries(),
      isGenresLoaded ? Promise.resolve(undefined) : catalogStore.fetchGenres(),
      isFeaturesLoaded ? Promise.resolve(undefined) : featuresStore.fetchFeatures(),
      isPromocodeCheckInfoLoaded ? Promise.resolve(undefined) : restoreSavedPromocode(),
      isYearsLoaded ? Promise.resolve(undefined) : catalogStore.fetchYears(),
    ]);
  };

  const bootSession = async () => {
    resolveSmartTvCode();

    const isMustLoadSessionFromToken = process.server && Boolean(accessToken);
    const isOffersLoaded = Boolean(offersStore._offers?.length);

    const loadSessionByTokenPromise = isMustLoadSessionFromToken
      ? loadSessionFromQueryAccessToken(accessToken)
      : Promise.resolve(undefined);

    const loadNormalSessionPromise = !isMustLoadSessionFromToken
      ? sessionStore.loadSession({ forceLoadUser: true })
      : Promise.resolve(undefined);

    const offersLoadPromise = !isOffersLoaded ? offersStore.loadOffer() : Promise.resolve(undefined);

    try {
      await Promise.all([loadSessionByTokenPromise, loadNormalSessionPromise, offersLoadPromise]);
    } catch (error) {
      logger.error(error);
    }
  };

  const detectVpn = () => {
    checkVpnStatus().then(({ possibleVpn }) => {
      isVpnDetected.value = possibleVpn;
    });
  };

  const checkCacheVarsion = async () => {
    const currentCacheVersion = await $http.cache.readEntry('CACHE_VERSION');

    if (isClient) {
      try {
        const configJson = await $fetch<AppConfig>(`${hostName}/config.json`);

        config.value = configJson;

        if (config.value.clientCacheVersion !== currentCacheVersion?.value) {
          $http.cache.clear();
          await $http.cache.addEntry(CACHE_VERSION, config.value.clientCacheVersion);
        }
      } catch (error) {
        logger.error(error);
      }
    }
  };

  // Загружаем основную сессию
  await bootSession();

  if (isClient) {
    detectVpn();
  }

  // Грузим всякие словари, фильтры, фича тоггла
  await fetchStaticInformation();
  await application.init();

  executeWithSafeClientContext(() => {
    const queryPartnerUserId = route.query[RouteQuery.AdmitadUid] as string;
    const shouldShowTvPromoPopup = route.query[RouteQuery.TvPopupPromo] === '1';
    // Только для неавторизованнхы пророводим всю эту логику с ТВ попапом
    if (shouldShowTvPromoPopup) {
      tvPromoPopupActions.addTvPromoPopupFlag();
    }

    if (queryPartnerUserId) {
      localStorage.setValue(LocalStorageKey.AdmitadUid, {
        value: queryPartnerUserId,
        expires: maxAge.NINETY_DAYS,
      });
    }

    if (isAuth.value) {
      sendAdmitadEvent();
    }

    window.startTime = window.performance.now();
    console.info('%c INFO', 'color: #33f', `startLoadMainWorkbench ${window.startTime.toFixed(2)} ms.`);
  });

  if (process.server && app.ssrContext?.event) {
    sessionStore._userIp = getClientIp(app.ssrContext.event.node.req) || '';
  }

  await checkCacheVarsion();
});
