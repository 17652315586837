import { isClient } from '@vueuse/core';
import type { $Fetch, NitroFetchOptions, NitroFetchRequest } from 'nitropack';
import type { NuxtApp } from 'nuxt/app';
import { callWithNuxt } from 'nuxt/app';
import type { FetchOptions } from 'ofetch';
import * as qs from 'qs';

import { useSessionStore } from '../../stores/use-session-store';
import { ensureStartSlash, replaceURLVariables } from '../base/string';
import { ApiError, HTTPStatusCode } from './errors';
import { HttpClientCacheController } from './http-client-cache-controller';
import { HttpInterceptor } from './http-interceptor';
import type { HttpRequestBody, HttpRequestEndpoint, HttpRequestOptions } from './http-request';
import { HttpRequestMethod } from './http-request';

export class HttpClient {
  private readonly _fetch: $Fetch<unknown, NitroFetchRequest>;
  private readonly _requestControllers = new Map<string, AbortController>();
  public readonly cache: HttpClientCacheController;

  constructor(
    private readonly nuxtApp: NuxtApp,
    baseURL: string,
    private readonly interceptors: HttpInterceptor[],
  ) {
    const headers = {};

    const fetchOptions = { baseURL, headers, timeout: 15000 } as FetchOptions;

    this.addInterceptor('onRequest', fetchOptions, interceptors);
    this.addInterceptor('onRequestError', fetchOptions, interceptors);
    this.addInterceptor('onResponse', fetchOptions, interceptors);
    this.addInterceptor('onResponseError', fetchOptions, interceptors);

    this._fetch = $fetch.create(fetchOptions);

    this.cache = new HttpClientCacheController();
  }

  public cancelRequest(path: string) {
    this._requestControllers.get(path)?.abort();
    this._requestControllers.delete(path);
  }

  public get<T>(endpoint: HttpRequestEndpoint, options?: HttpRequestOptions) {
    return this.sendRequest<T>(HttpRequestMethod.GET, endpoint, null, options);
  }

  public post<T>(endpoint: HttpRequestEndpoint, body: HttpRequestBody, options?: HttpRequestOptions) {
    return this.sendRequest<T>(HttpRequestMethod.POST, endpoint, body, options);
  }

  public put<T>(endpoint: HttpRequestEndpoint, body: HttpRequestBody, options?: HttpRequestOptions) {
    return this.sendRequest<T>(HttpRequestMethod.PUT, endpoint, body, options);
  }

  public patch<T>(endpoint: HttpRequestEndpoint, body: HttpRequestBody, options?: HttpRequestOptions) {
    return this.sendRequest<T>(HttpRequestMethod.PATCH, endpoint, body, options);
  }

  public delete<T>(endpoint: HttpRequestEndpoint, options?: HttpRequestOptions) {
    return this.sendRequest<T>(HttpRequestMethod.DELETE, endpoint, null, options);
  }

  private async processRequest<T>(
    endpoint: HttpRequestEndpoint,
    requestType: 'raw' | 'default',
    fetchOptions: NitroFetchOptions<any>,
    options?: HttpRequestOptions,
    retryCount = 1,
  ): Promise<T> {
    const { method } = fetchOptions;
    const { path: _path, cacheStrategy, cacheTimeMilliseconds } = endpoint;

    const isCacheableRequest =
      isClient && (cacheStrategy === 'default' || cacheStrategy === 'max-age') && method === 'GET';

    const normalizedCacheTimeMilliseconds = (cacheStrategy === 'default' ? 86400000 * 7 : cacheTimeMilliseconds) || 0;

    const path = this.applyParamsToPath(_path, fetchOptions);

    this.addAbortController(endpoint, fetchOptions, new AbortController());

    if (isCacheableRequest) {
      const cached = await this.cache.readEntry<T>(path);

      if (cached) {
        const isCacheOutdated = Date.now() >= cached.expires;

        if (!isCacheOutdated) {
          return cached.value;
        }

        this.cache.removeEntry(path);
      }
    }

    const serializedHeaders: Record<string, string> = {};

    try {
      // @ts-ignore
      const fetchFunc = requestType === 'raw' ? this._fetch.raw : this._fetch;

      const options = {
        ...fetchOptions,
        params: undefined,
        query: undefined,
      };

      const data = await fetchFunc<T>(path, options);

      data?.headers?.forEach((value, key) => {
        serializedHeaders[key] = value;
      });

      if (isCacheableRequest) {
        if (requestType === 'raw') {
          this.cache.addEntry(
            path,
            {
              data: data._data,
              headers: serializedHeaders,
            },
            { expires: normalizedCacheTimeMilliseconds },
          );
        } else {
          this.cache.addEntry(path, data, { expires: normalizedCacheTimeMilliseconds });
        }
      }

      if (requestType === 'raw') {
        return {
          headers: serializedHeaders,
          data: data._data,
        } as unknown as Promise<T>;
      }

      return data as unknown as Promise<T>;
    } catch (error) {
      const isSessionRequest = path.includes('session');

      let updatedResponseData: any;

      if (retryCount < 3) {
        if (error instanceof ApiError && error.is(HTTPStatusCode.Unauthorized)) {
          await callWithNuxt(this.nuxtApp, async () => {
            const sessionStore = useSessionStore();
            await sessionStore.refreshSession();

            if (isClient && !isSessionRequest) {
              updatedResponseData = await this.processRequest(
                endpoint,
                requestType,
                fetchOptions,
                options,
                retryCount + 1,
              );
            }
          });
        }
      }

      if (updatedResponseData) {
        return updatedResponseData;
      }

      throw error;
    }
  }

  public sendRequest<T>(
    method: HttpRequestMethod | string,
    endpoint: HttpRequestEndpoint,
    body?: HttpRequestBody,
    options?: HttpRequestOptions,
  ) {
    const { requestId, headers, signRequest, params, query, retry, response, parseResponse, isForceAPIAuthToken } =
      options || {};

    const fetchOptions = {
      method,
      body,
      requestId,
      headers,
      signRequest,
      params,
      query,
      retry,
      response,
      parseResponse,
      isForceAPIAuthToken,
    } as NitroFetchOptions<any>;

    return this.processRequest<T>(endpoint, 'default', fetchOptions, options);
  }

  public sendRawRequest<T>(
    method: HttpRequestMethod | string,
    path: HttpRequestEndpoint,
    body?: HttpRequestBody,
    options?: HttpRequestOptions,
  ) {
    const { requestId, headers, signRequest, params, query, retry, response, parseResponse, isForceAPIAuthToken } =
      options || {};

    const fetchOptions = {
      method,
      body,
      requestId,
      headers,
      signRequest,
      params,
      query,
      retry,
      response,
      parseResponse,
      isForceAPIAuthToken,
    } as NitroFetchOptions<any>;

    return this.processRequest<T>(path, 'raw', fetchOptions, options);
  }

  private addAbortController(
    endpoint: Pick<HttpRequestEndpoint, 'path'>,
    fetchOptions: Pick<FetchOptions, 'signal'>,
    controller: AbortController,
  ) {
    this._requestControllers.set(endpoint.path, controller);
    fetchOptions.signal = controller.signal;
  }

  private addInterceptor(
    method: 'onRequest' | 'onResponse' | 'onRequestError' | 'onResponseError',
    options: FetchOptions,
    interceptors: HttpInterceptor[],
  ) {
    options[method] = (ctx) => interceptors.forEach((i) => i[method]?.(ctx));
  }

  private applyParamsToPath(path: string, options: FetchOptions) {
    let _path = path;

    if (options.params) {
      _path = replaceURLVariables(path, options.params);
    }

    if (options.query) {
      const queryStr = decodeURIComponent(qs.stringify(options.query, { arrayFormat: 'brackets' }));

      _path = _path + '?' + queryStr;
    }

    return ensureStartSlash(_path);
  }
}
