import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import type { Ref } from 'vue';
import { inject } from 'vue';

export default function useSafeScrollableBodyElement() {
  const bodyEl = inject<Ref<HTMLElement>>('bodyEl');

  if (!bodyEl) {
    throw new UnexpectedComponentStateError('bodyEl');
  }

  return bodyEl;
}
