<template>
  <section :class="$style.errorPage">
    <div :class="$style.errorPageWrapper">
      <div :class="$style.textBlockWrapper">
        <app-link :to="{ name: $AppRoute.Index }" :aria-label="$t('page.error.goToMain')" :class="$style.logo">
          <icon-logo :size="$isMobile ? 'mini' : 'normal'" />
        </app-link>
        <div :class="$style.textBlock">
          <div :class="$style.iconWrapper">
            <SVGVijuPage v-if="isClientError" :class="$style.iconServer" />
            <SVGServer v-else :class="$style.iconServer" />
            <span :class="$style.iconGradient"></span>
          </div>
          <p :class="$style.status">{{ $t('errorPage.errorText', { statusCode: String(statusCode) }) }}</p>
          <p :class="$style.text">{{ errorText }}</p>
          <pre v-if="!isRelease" :class="$style.card">{{ error }}</pre>
          <app-button
            :text="$t('common.back')"
            variation="button-secondary"
            :aria-label="$t('common.back')"
            :class="$style.backButton"
            @click="goBack()"
          />
        </div>
      </div>
      <div :class="$style.imageWrapper">
        <img
          :alt="$t('errorPage.errorText', { statusCode: String(statusCode) })"
          :class="$style.image"
          src="/images/error-image.webp"
        />
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { NuxtError } from 'nuxt/app';
import type { PropType } from 'vue';

import SVGServer from '../../web/static/icons/server.svg?component';
import SVGVijuPage from '../../web/static/icons/viju-page.svg?component';
import IconLogo from './components/icons/IconLogo.vue';
import AppButton from './components/ui/AppButton.vue';
import AppLink from './components/ui/AppLink.vue';
import useEnvironment from './platform/environment/use-environment';
import { HTTPStatusCode } from './platform/http/errors';
import useLocale from './platform/localization/use-locale';

const props = defineProps({
  error: { type: Object as PropType<NuxtError>, required: true },
});

const { isRelease } = useEnvironment();
const { translate } = useLocale();

const statusCode = props.error?.statusCode || 400;

const isClientError =
  statusCode === HTTPStatusCode.BadRequest ||
  statusCode === HTTPStatusCode.Unauthorized ||
  statusCode === HTTPStatusCode.PaymentRequired ||
  statusCode === HTTPStatusCode.Forbidden ||
  statusCode === HTTPStatusCode.NotFound;

const errorText = (() => {
  switch (statusCode) {
    case HTTPStatusCode.BadRequest:
      return translate('error.page.400');
    case HTTPStatusCode.Unauthorized:
      return translate('error.page.401');
    case HTTPStatusCode.PaymentRequired:
      return translate('error.page.402');
    case HTTPStatusCode.Forbidden:
      return translate('error.page.403');
    case HTTPStatusCode.NotFound:
      return translate('error.page.404');
    case HTTPStatusCode.InternalServerError:
      return translate('error.page.500');
    case HTTPStatusCode.BadGateway:
    case HTTPStatusCode.ServiceUnavailable:
    case HTTPStatusCode.GatewayTimeout:
      return translate('error.page.503');
  }

  return props.error.message;
})();

const goBack = () => clearError({ redirect: '/' });
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;
@use 'assets/breakpoints' as breakpoints;

.errorPage {
  height: 100vh;
  overflow-y: auto;
}

.errorPageWrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  min-height: 650px;
  background-color: var(--color-bg-primary);
  color: var(--color-text-primary);
}

.textBlockWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  flex-shrink: 0;
}

.iconWrapper {
  position: relative;
  margin-bottom: 65px;
}

.iconGradient {
  position: absolute;
  top: 0;
  left: -85px;
  z-index: -1;
  width: 155px;
  height: 155px;
  border-radius: 30% 70% 70% 30% / 30% 30% 70% 70%;
  background-color: var(--color-states-bg-accent-hover); // поправить цвета на странице
  filter: blur(85px);
}

.textBlock {
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: var(--g-spacing-10);
  height: 100%;
}

.logo {
  position: absolute;
  top: 18px;
  left: 50px;
}

.text {
  @include fonts.WebHeadline-2;
  margin-bottom: 30px;
  color: var(--color-text-primary);
}

.card {
  min-width: 0;
  max-width: 900px;
  border-radius: 0.25rem;
  border: 1px solid var(--color-stroke-div-primary);
  background-color: var(--color-bg-secondary);
  background-clip: border-box;
  color: var(--color-text-secondary);
}

.backButton {
  width: fit-content;

  &:hover:not([disabled]),
  &:focus:not([disabled]) {
    background-color: var(--color-bg-secondary);
    color: var(--color-text-primary);
  }
}

.status {
  @include fonts.WebTitle-1;
  margin-bottom: var(--g-spacing-10);
}

.imageWrapper {
  display: flex;
  height: 100%;
  overflow: hidden;
}

.image {
  max-width: initial;
  height: 100%;
}

@media screen and (max-width: 800px) and (min-height: 540px) {
  .errorPage {
    overflow: hidden;
  }

  .errorPageWrapper {
    flex-direction: column;
    justify-content: space-between;
    min-height: 470px;
  }

  .logo {
    top: 9px;
    left: 16px;
  }

  .textBlockWrapper {
    margin-top: 165px;
    margin-bottom: 60px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-left: 104px;
    width: 100%;
  }

  .textBlock {
    padding-right: var(--g-spacing-10);
    height: initial;
  }

  .imageWrapper {
    justify-content: flex-end;
    width: 100%;
  }

  .image {
    position: absolute;
    left: 95px;
    max-width: 100%;
    height: initial;
  }
}

@include breakpoints.max-width-540 {
  .iconWrapper {
    margin-bottom: var(--g-spacing-32);
  }

  .iconServer {
    width: 48px;
    height: 48px;
  }
  .textBlockWrapper {
    padding-left: var(--g-spacing-40);
  }

  .status {
    margin: 0;
  }
}

@media screen and (max-height: 500px) {
  .errorPageWrapper {
    height: 100%;
    min-height: 375px;
  }

  .iconWrapper {
    margin-bottom: var(--g-spacing-32);
  }

  .iconServer {
    width: 48px;
    height: 48px;
  }

  .card {
    max-height: 100px;
  }
}

@include breakpoints.max-width-375 {
  .card {
    max-width: 300px;
  }
}
</style>
