import type { ApiBonus, ApiCancelReason, Bonus, CancelReason } from '@package/sdk/src/api';
import { BonusMapper, CancelReasonMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export interface ICancelSubscriptionPayload {
  subscriptionId: string;
  // Список причин, почему решил подписаться
  cancel_reason_ids?: string[];
  // Если причина - это просто текст из поля ввода
  cancel_reason?: string;
}

export function useSubscriptionCancelApi() {
  const { $http } = useNuxtApp();

  const fetchReasons = (): Promise<CancelReason[]> => {
    return $http.get<ApiCancelReason[]>(ENDPOINTS.SUBSCRIPTION_CANCEL_REASONS).then(CancelReasonMapper.mapMany);
  };

  const applyCancelBonuses = (payload: ICancelSubscriptionPayload): Promise<Bonus> => {
    const { cancel_reason_ids, cancel_reason, subscriptionId } = payload;

    return $http
      .post<ApiBonus>(
        ENDPOINTS.SUBSCRIPTION_CANCEL_BONUSES_APPLY,
        { cancel_reason_ids, cancel_reason },
        { params: { id: subscriptionId } },
      )
      .then(BonusMapper.map);
  };

  const checkCancelBonuses = (subscriptionId: string): Promise<boolean> => {
    return (
      $http
        .post<void>(ENDPOINTS.SUBSCRIPTION_CANCEL_BONUSES_CHECK, {}, { params: { id: subscriptionId } })
        // Endpoint ничего не возвращает, 200 статус говорит о том что можно дать юзеру бонус
        .then(() => true)
        .catch(() => false)
    );
  };

  return { fetchReasons, applyCancelBonuses, checkCancelBonuses };
}
