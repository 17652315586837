import type { Profile, Session, User } from '@package/sdk/src/api';
import { ParentalControlModalState, ParentalControlStatus, UserType } from '@package/sdk/src/api';
import { isDefined, timeout, UnexpectedComponentStateError } from '@package/sdk/src/core';
import * as Sentry from '@sentry/vue';
import { isClient } from '@vueuse/core';
import { getUnixTime } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { callWithNuxt, useNuxtApp } from 'nuxt/app';
import { defineStore, storeToRefs } from 'pinia';
import { v4 } from 'uuid';
import { computed, ref } from 'vue';

import { useSessionActions } from '@/code/auth/use-session-actions';
import useDeviceInfo from '@/code/environment/use-device-info';
import useParentalControlApi from '@/code/profile/use-parental-control-api';
import { useProfilesApi } from '@/code/profile/use-profiles-api';
import { useSessionsApi } from '@/code/user/use-sessions-api';
import type { IUserCreateSession } from '@/code/user/use-users-api';
import { useUsersApi } from '@/code/user/use-users-api';
import { CookieName, cookies } from '@/platform/cookies/cookies';
import useAppCookie from '@/platform/cookies/use-app-cookie';
import { ApiError, HTTPStatusCode } from '@/platform/http/errors';
import { useLogger } from '@/platform/logger/use-logger';
import { useJwtDecoder } from '@/platform/network/use-jwt-decoder';
import { AppRoute } from '@/platform/router/routes';
import { LocalStorageKey } from '@/platform/storage/local-storage';
import { useLocalStorage } from '@/platform/storage/use-local-storage';

import useParentalControlStore from './use-parental-control-store';

export interface SetParentalControlStatusOptions {
  code?: string;
  isChildrenAccess: boolean;
}

export interface LoadSessionOptions {
  forceLoadUser?: boolean;
  forceRefreshToken?: boolean;
}

export const useSessionStore = defineStore('session', () => {
  const jwtDecoder = useJwtDecoder();
  const localStorage = useLocalStorage();

  const logger = useLogger();
  const sessionsApi = useSessionsApi();
  const deviceInfo = useDeviceInfo();
  const parentalControlApi = useParentalControlApi();
  const profilesApi = useProfilesApi();
  const app = useNuxtApp();
  const { $emitter, $http } = app;

  const { pinCode } = storeToRefs(useParentalControlStore());

  const { fetchCurrentUser } = useUsersApi();
  const { requestLogout, requestLogin, requestRegistration, requestLoginSocialAuth } = useSessionActions();

  const _session = ref<Session>();
  const _user = ref<User>();

  const _gaClientId = ref('');
  const _ymUserId = ref('');
  const _userIp = ref('');

  const user = computed(() => _user.value);
  const userIp = computed(() => _userIp.value);

  const gaClientId = computed(() => _gaClientId.value);
  const ymUserId = computed(() => _ymUserId.value);

  const session = computed(() => _session.value);
  const isAuth = computed(() => isDefined(_session.value));

  const visitorIdCookie = useAppCookie(CookieName.VisitorId, { maxAge: cookies.visitorId.maxAge, path: '/' });
  const userIdCookie = useAppCookie(CookieName.UserId, { maxAge: cookies.userId.maxAge, path: '/' });
  const authTokensCookie = useAppCookie(CookieName.Auth, { maxAge: cookies.auth.maxAge, path: '/' });

  const sessionId = computed(() => {
    const token = _session.value?.auth?.token;

    if (!token) {
      return;
    }

    return jwtDecoder.decode(token)?.sub;
  });

  const userIpKey = computed(() => (_userIp.value?.includes(':') ? 'userIpV6' : 'userIpV4'));
  const isPartnerUser = computed(() => _user.value?.userType === UserType.PARTNER);

  const savedSmartTvCode = ref('');
  const setSavedSmartTvCode = (code: string) => {
    savedSmartTvCode.value = code;
  };

  const storeSession = (session?: Session) =>
    callWithNuxt(app, () => {
      if (!session) {
        return;
      }

      const tokenPayload = jwtDecoder.decode(session.auth.token);

      if (!tokenPayload) {
        throw new UnexpectedComponentStateError('tokenPayload');
      }

      authTokensCookie.value = { ...session.auth, expiresAt: tokenPayload.exp };

      _session.value = session;
      _user.value = session.user;

      userIdCookie.value = session.user.id || '';
      visitorIdCookie.value = tokenPayload.visitor_id || visitorIdCookie.value;

      const { currentDevice } = session.user;
      const status = currentDevice.parentalControlStatus || ParentalControlStatus.NotSet;

      parentalControlStatusUpdated(status);

      if (isClient) {
        Sentry.configureScope((scope) => {
          scope.setTag('user_id', _user.value?.id);
          scope.setTag('user_login', _user.value?.email || _user.value?.phoneNumber);
        });

        window.$dsml?.setUser({
          visitorId: visitorIdCookie.value as string,
          profileId: _user.value.currentProfileId,
          userId: _session.value.user.id,
          [userIpKey.value]: _userIp.value,
        });
      }
    });

  const clearSession = () => {
    _session.value = undefined;
    _user.value = undefined;

    visitorIdCookie.value = v4();
    authTokensCookie.value = undefined;
    userIdCookie.value = undefined;

    savedSmartTvCode.value = '';

    pinCode.value = '';

    if (isClient) {
      window.$dsml?.setUser({
        visitorId: visitorIdCookie.value as string,
        profileId: undefined,
        userId: undefined,
        [userIpKey.value]: _userIp.value,
      });
    }

    localStorage.remove(LocalStorageKey.ParentalControlModalState);
    $emitter.emit('offer.loadRequest');
  };

  const loadSession = async (options: LoadSessionOptions = { forceLoadUser: false, forceRefreshToken: false }) => {
    await callWithNuxt(app, async () => {
      const { forceLoadUser, forceRefreshToken } = options;

      const authTokensCookie = useAppCookie(CookieName.Auth, {
        maxAge: cookies.auth.maxAge,
        path: '/',
      });

      if (!authTokensCookie.value) {
        _session.value = undefined;
        _user.value = undefined;
        userIdCookie.value = undefined;
        return;
      }

      try {
        const currentTime = getUnixTime(utcToZonedTime(new Date(), 'Etc/UTC'));
        const authTokenExpiresTime = Number(authTokensCookie.value.expiresAt);

        if (forceRefreshToken || currentTime >= authTokenExpiresTime) {
          return refreshSession();
        }

        const user = forceLoadUser && _user.value ? _user.value : ((await updateCurrentUser()) as User);
        const session: Session = { auth: authTokensCookie.value, user };

        _session.value = session;
        _user.value = session.user;

        await resolveSession(session);

        userIdCookie.value = user.id || '';
        return session;
      } catch (error) {
        if (error instanceof ApiError) {
          const { status } = error;

          if (status === HTTPStatusCode.Unauthorized || status === HTTPStatusCode.Forbidden) {
            await clearSession();
          }
        }
      }
    });
  };

  const updateCurrentUser = async () => {
    try {
      _user.value = await fetchCurrentUser();
    } catch (error) {
      _user.value = await fetchCurrentUser();
    }

    return _user.value;
  };

  const refreshSession = async () => {
    await callWithNuxt(app, async () => {
      try {
        const authTokensCookie = useAppCookie(CookieName.Auth, {
          maxAge: cookies.auth.maxAge,
          path: '/',
        });

        const refreshToken = _session.value?.auth?.refreshToken || authTokensCookie.value?.refreshToken;

        if (!refreshToken) {
          throw new UnexpectedComponentStateError('refreshToken');
        }

        const session = await sessionsApi.refresh(refreshToken);

        storeSession(session);
        return session;
      } catch (err) {
        logger.error(err);

        // обрабатываем удаление сессии с другого устройства
        if (err instanceof ApiError && err.is(HTTPStatusCode.UnprocessableEntity)) {
          clearSession();
          return navigateTo({ name: AppRoute.Index });
        }

        if (isClient && !_session.value) {
          authTokensCookie.value = undefined;
        }
      }
    });
  };

  const deleteSession = async () => {
    if (!_session.value) {
      throw new UnexpectedComponentStateError('session');
    }

    try {
      await clearSession();
    } catch (error) {
      logger.error(error);
    }
  };

  const forgetSession = () => {
    if (!_session.value) {
      throw new UnexpectedComponentStateError('session');
    }

    const id = (sessionId.value as string).toString();

    clearSession();

    requestLogout(id).catch((error) => {
      if (error instanceof ApiError) {
        const { status } = error;

        if (status === HTTPStatusCode.NotFound) {
          clearSession();
        }
      }
    });
  };

  const loginUser = async (userParameters: Partial<IUserCreateSession>) => {
    const session = await requestLogin(userParameters);
    await resolveSession(session);
  };

  const loginUserWithAccessToken = async (token: string) => {
    const session = await sessionsApi.restore(token, deviceInfo);
    await resolveSession(session);
  };

  const loginUserSocialAuth = async (signUpBody: Partial<IUserCreateSession>) => {
    const session = await requestLoginSocialAuth(signUpBody);
    await resolveSession(session);
    return session;
  };

  const registerUser = async (signUpBody: Partial<IUserCreateSession>) => {
    const session = await requestRegistration(signUpBody);
    await resolveSession(session);
  };

  const resolveSession = async (session: Session) => {
    storeSession(session);

    await resolveInitialParentalControlModalState();
    await timeout(10);

    $emitter.emit('offer.loadRequest');
  };

  const isAccountParentalControlSettled = computed(() => isDefined(user.value?.parentalControl));

  const parentalControlStatus = ref(ParentalControlStatus.NotSet);
  const parentalControlModalState = ref(ParentalControlModalState.None);

  const profiles = computed(() => user.value?.profiles);
  const currentDeviceParentalControlStatus = computed(() => parentalControlStatus.value);

  const parentalControlModalStateUpdated = (value: ParentalControlModalState) => {
    parentalControlModalState.value = value;

    localStorage.setValue(LocalStorageKey.ParentalControlModalState, { value });
  };

  const parentalControlStatusUpdated = (status: ParentalControlStatus) => {
    parentalControlStatus.value = status;
  };

  const setParentalControlStatus = async (options: SetParentalControlStatusOptions) => {
    if (!isAuth.value) {
      return;
    }

    const { code, isChildrenAccess } = options;

    // Если доступ у детей к аккаунту есть
    if (isChildrenAccess) {
      await parentalControlApi.setChildrenAccess(code as string);
      parentalControlStatusUpdated(ParentalControlStatus.ChildrenAccess);
      return;
    }

    // если нет
    await parentalControlApi.setNoChildrenAccess();
    parentalControlStatusUpdated(ParentalControlStatus.NoChildrenAccess);
  };

  const resolveInitialParentalControlModalState = () => {
    const val = localStorage.getValue(LocalStorageKey.ParentalControlModalState) as {
      value: ParentalControlModalState | undefined;
    };

    parentalControlModalStateUpdated(val?.value ?? ParentalControlModalState.None);
  };

  const setProfile = async (profile: Profile) => {
    if (!user.value) {
      return;
    }

    $http.cache.clear();

    await profilesApi.setProfile(profile.id);

    if (session.value) {
      await refreshSession();
      storeSession(session.value);
    }

    $emitter.emit('user.profile.updated');
  };

  return {
    profiles,
    parentalControlModalState,
    currentDeviceParentalControlStatus,
    isAccountParentalControlSettled,
    isAuth,
    _userIp,
    _session,
    _user,
    _gaClientId,
    user,
    userIp,
    userIpKey,
    session,
    ymUserId,
    _ymUserId,
    sessionId,
    gaClientId,
    isPartnerUser,
    savedSmartTvCode,
    setSavedSmartTvCode,
    deleteSession,
    loginUserSocialAuth,
    registerUser,
    setProfile,
    setParentalControlStatus,
    resolveInitialParentalControlModalState,
    parentalControlStatusUpdated,
    parentalControlModalStateUpdated,
    loginUser,
    loginUserWithAccessToken,
    updateCurrentUser,
    loadSession,
    refreshSession,
    storeSession,
    forgetSession,
    clearSession,
    resolveSession,
  };
});
