import type { RouterConfig } from '@nuxt/schema';
import { isObject } from '@vueuse/core';
import { storeToRefs } from 'pinia';

import { AppRoute } from '@/platform/router/routes';
import { useLayoutStore } from '@/stores/use-layout-store';

const SCROLLABLE_BODY_ELEMENT_ID = '#inner-body';

const savingPositionRoutes = [AppRoute.Movies, AppRoute.MovieFilter, AppRoute.Serials, AppRoute.SerialFilter];

const routesWithoutScrollTop = [AppRoute.SerialSeasonCard];

/**
 * @description
 *
 * Кастомный scrollBehaviour, так как у нас в приложение скролится теперь не body, а <div id="inner-body">
 */
export default <RouterConfig>{
  strict: false,
  scrollBehavior(to) {
    const bodyEl = document.querySelector(SCROLLABLE_BODY_ELEMENT_ID);
    const layoutStore = useLayoutStore();
    const { scrollPositionMap } = storeToRefs(layoutStore);
    const hashElement = document.getElementById(to.hash);

    if (hashElement) {
      hashElement.scrollIntoView({ behavior: 'smooth' });
      return;
    }

    if (
      isObject(scrollPositionMap?.value) &&
      Reflect.has(scrollPositionMap.value, to.name as AppRoute) &&
      savingPositionRoutes.includes(to.name as AppRoute)
    ) {
      window.setTimeout(() => bodyEl?.scrollTo(scrollPositionMap.value[to.name as AppRoute]), 300);
    } else if (routesWithoutScrollTop.includes(to.name as AppRoute)) {
      return { top: 0 };
    } else {
      bodyEl?.scrollTo({ top: 0 });
    }

    return { top: 0 };
  },
};
