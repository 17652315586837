import type { FetchContext } from 'ofetch';

import { executeWithSafeClientContext } from '../base/function';
import { useLogger } from '../logger/use-logger';
import { ApiError, HTTPStatusCode } from './errors';
import { HttpInterceptor } from './http-interceptor';

export class ErrorInterceptor extends HttpInterceptor {
  onResponse({ response }: FetchContext) {
    const { status, url, _data } = response || {};

    if (status === HTTPStatusCode.Found || status === HTTPStatusCode.NotFound) {
      throw new ApiError(url as string, status as HTTPStatusCode, _data);
    }
  }

  onResponseError({ response, error, options }: FetchContext) {
    const logger = useLogger();
    const { url, _data, status } = response || {};

    logger.error(`[API][${options.method?.toUpperCase()} ${status}] ${url}`, _data);

    // @NOTE: remove when the critical status added to the required services
    const isLikeRequest = response?.url.includes('/like');

    if (
      error &&
      response &&
      response.status &&
      !isLikeRequest &&
      response.status >= HTTPStatusCode.InternalServerError &&
      response.status <= 599
    ) {
      return executeWithSafeClientContext(() => {
        window.location.href = '/503';
      });
    }

    throw new ApiError(url as string, status as HTTPStatusCode, _data);
  }
}
