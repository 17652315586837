<template>
  <app-modal-wrapper :with-padding="false">
    <img :alt="$t('alt.modals.exploreMyChannel.promo')" src="/images/explore-my-channel.png" />

    <div :class="$style.contentBlock">
      <modal-title :title="$t('exploreMyChannelModal.title')" :is-html="true" />

      <modal-description
        :class="$style.description"
        :description="$t('exploreMyChannelModal.description')"
        :is-html="true"
      />

      <app-button
        :class="$style.button"
        :text="$t('exploreMyChannelModal.button')"
        :aria-label="$t('exploreMyChannelModal.button')"
        :title="$t('exploreMyChannelModal.button')"
        @click="onClick()"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import { AnalyticPageName, useKinomAnalytics } from '@package/sdk/src/analytics';
import { onMounted } from 'vue';

import { AppRoute } from '../../platform/router/routes';
import { useLayoutStore } from '../../stores/use-layout-store';
import AppButton from '../ui/AppButton.vue';
import ModalDescription from './ModalDescription.vue';
import ModalTitle from './ModalTitle.vue';

const layoutStore = useLayoutStore();
const router = useRouter();
const kinomAnalytics = useKinomAnalytics();

const onClick = () => {
  layoutStore.setCurrentModalName(null);

  router.push({ name: AppRoute.MyChannel });
};

onMounted(() => {
  kinomAnalytics.onShowMyChannelOnboardPopup({ page: AnalyticPageName.MyChannel });
});
</script>

<style lang="scss" module>
.contentBlock {
  padding: var(--g-spacing-32);
}

.description {
  margin-bottom: var(--g-spacing-32);
}

.button {
  width: 100%;
}
</style>
