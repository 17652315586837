import { ProcessingMapper } from '../base/processing-mapper';
import { ApiPromocodeData } from './bonus-types/promo-code-data';
import { PromocodeData } from './promocode/promo-code-data';

export class PromocodeDataMapper {
  static map({
    card_required,
    available,
    message,
    redirect_page,
    redirect_message,
    promo_offer,
  }: ApiPromocodeData): PromocodeData {
    return ProcessingMapper.process({
      cardRequired: card_required,
      available,
      message,
      redirectPage: redirect_page,
      redirectMessage: redirect_message,
      promoOffer: promo_offer
        ? {
            subscribeButtonSubtext: promo_offer.subscribe_button_subtext,
            subscribeButtonText: promo_offer.subscribe_button_text,
            id: promo_offer.id,
            duration: promo_offer.duration,
            durationUnit: promo_offer.duration_unit,
            description: promo_offer.description,
            title: promo_offer.title,
            subtitle: promo_offer.subtitle,
            benefits: promo_offer.benefits,
            horizontalImage: promo_offer.horizontal_image,
            verticalImage: promo_offer.vertical_image,
          }
        : undefined,
    });
  }
}
