import { ensureEndSlash, isEndsWithSlash } from '../platform/base/string';
import { HTTPStatusCode } from '../platform/http/errors';

export default defineNuxtRouteMiddleware((route) => {
  const path = route.path as string;
  const pathWithSlash = ensureEndSlash(path);
  const isRouteEndsWithSlash = isEndsWithSlash(path);
  const hasQuery = Object.keys(route.query).length;

  if (!isRouteEndsWithSlash && !hasQuery) {
    return navigateTo(pathWithSlash, { redirectCode: HTTPStatusCode.MovedPermanently });
  }
});
