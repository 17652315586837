import { watchOnce } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { onBeforeUnmount } from 'vue';

import useMobile from '../../platform/layout/use-mobile';
import { AppRoute } from '../../platform/router/routes';
import { LocalStorageKey } from '../../platform/storage/local-storage';
import { useLocalStorage } from '../../platform/storage/use-local-storage';
import { useLayoutStore } from '../../stores/use-layout-store';
import { useMyChannelStore } from '../../stores/use-my-channel-store';

export type MyChannelPopupKey =
  | LocalStorageKey.MyChannelOnboardingPopupShown
  | LocalStorageKey.MyChannelRegistrationPopupShown
  | LocalStorageKey.MyChannelLikePopupShown
  | LocalStorageKey.MyChannelDislikePopupShown
  | LocalStorageKey.MyChannelWatchAndRatePopupShown
  | LocalStorageKey.MyChannelCollectionPopupShown;

export default function useMyChannelOnboarding() {
  const localStorage = useLocalStorage();
  const layoutStore = useLayoutStore();
  const myChannelStore = useMyChannelStore();
  const isMobile = useMobile();
  const route = useRoute();

  const { myChannelPopupState } = storeToRefs(myChannelStore);

  const MY_CHANNEL_ONBOARDING_POPUP_TIMEOUT = 6000;

  const closeMyChannelPopup = (key: MyChannelPopupKey) => {
    myChannelStore.setMyChannelPopupState(key, false);
  };

  let myChannelPopupTimeoutId: number;

  const showMyChannelPopup = (key: MyChannelPopupKey) => {
    const isPopupShown = localStorage.getValue(key)?.value;

    if (isPopupShown) {
      return;
    }

    if (myChannelPopupTimeoutId) {
      window.clearTimeout(myChannelPopupTimeoutId);
    }

    myChannelStore.setMyChannelPopupState(key, true);
    localStorage.setValue(key, { value: true });

    myChannelPopupTimeoutId = window.setTimeout(() => {
      closeMyChannelPopup(key);
    }, MY_CHANNEL_ONBOARDING_POPUP_TIMEOUT);
  };

  const hiddenMyChannelOnboardingRoutes = [
    AppRoute.MyChannel,
    AppRoute.MyChannelMoment,
    AppRoute.AuthEnter,
    AppRoute.AuthEnterPassword,
    AppRoute.Offers,
  ];

  const resolveMyChannelOnboardingModal = () => {
    // На странице моего канала и авторизации, модалку не засчитываем как отображенную (ну и не показываем ее)
    if (hiddenMyChannelOnboardingRoutes.includes(route.name)) {
      return;
    }

    const isMyChannelOnboardingShownValue = localStorage.getValue(LocalStorageKey.MyChannelOnboardingPopupShown)?.value;

    if (!isMyChannelOnboardingShownValue) {
      if (isMobile) {
        // В мобильной версии отображаем и засчитываем отображение модалки только на главной
        if (route.name !== AppRoute.Index) {
          return;
        }

        layoutStore.setCurrentModalName('ExploreMyChannelModal', { hasCloseIcon: true });
      } else {
        showMyChannelPopup(LocalStorageKey.MyChannelOnboardingPopupShown);
      }

      localStorage.setValue(LocalStorageKey.MyChannelOnboardingPopupShown, { value: true });
    }
  };

  watchOnce(
    () => route.name,
    () => {
      if (myChannelPopupState.value[LocalStorageKey.MyChannelOnboardingPopupShown] && myChannelPopupTimeoutId) {
        window.clearTimeout(myChannelPopupTimeoutId);
      }
    },
  );

  onBeforeUnmount(() => {
    if (myChannelPopupTimeoutId) {
      window.clearTimeout(myChannelPopupTimeoutId);
    }
  });

  return {
    resolveMyChannelOnboardingModal,
    myChannelPopupState,
    showMyChannelPopup,
    closeMyChannelPopup,
  };
}
