import type { ApiQrSignature, PaymentOptions } from '@package/sdk/src/api';
import { PaymentQrSignatureMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export function usePaymentsApi() {
  const { $http } = useNuxtApp();

  const extractPaymentQRSignature = async (payload: string): Promise<PaymentOptions> => {
    return await $http
      .get<ApiQrSignature>(ENDPOINTS.PAYMENTS_QR_SIGNATURE, { params: { payload } })
      .then(PaymentQrSignatureMapper.map);
  };

  return { extractPaymentQRSignature };
}
