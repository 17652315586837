import type { ApiMedia, Media } from '@package/sdk/src/api';
import { MediaMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export function useSimilarContentApi() {
  const { $http } = useNuxtApp();

  const fetchItems = (id: string): Promise<Media[]> => {
    return $http.get<ApiMedia[]>(ENDPOINTS.CONTENTS_SIMILAR, { params: { id } }).then(MediaMapper.mapMany);
  };

  return { fetchItems };
}
