import { isUndefined } from '@package/sdk/src/core';

import regex from '../../code/auth/regex';

/**
 * Добавляет начальный '/', если его нет.
 *
 * @example ensureStartSlash('abc') -> '/abc'
 * @example ensureStartSlash('/abc') -> '/abc'
 * @param {string} path
 * @returns {string}
 */
export function ensureStartSlash(path: string) {
  if (path.startsWith('/')) {
    return path;
  }

  return `/${path}`;
}

export function ensureEndSlash(path: string) {
  if (path.endsWith('/')) {
    return path;
  }

  return `${path}/`;
}

/**
 * Добавляет начальный '/' и конечные '/', если его нет.
 *
 * @example ensureStartSlash('abc') -> '/abc'
 * @example ensureStartSlash('/abc') -> '/abc'
 * @param {string} path
 * @returns {string}
 */
export function ensureStartAndEndSlash(path: string) {
  if (path.startsWith('/') && path.endsWith('/')) {
    return path;
  }

  if (path.startsWith('/') && !path.endsWith('/')) {
    return `${path}/`;
  }

  if (!path.startsWith('/') && path.endsWith('/')) {
    return `/${path}`;
  }

  return `/${path}/`;
}

export const EMAIL_REGEX = /^[-a-z0-9.~!$%^&*_=+}{'?]+@[a-z0-9_][-a-z0-9_]*\.[.-a-z0-9_]+$/i;

export const HAS_NUMBER_REGEX = /[0-9]/;

export function isOnlyLatinLetters(input: string): boolean {
  const char = !!input.match(/[a-zA-Z]/);

  return !!input.match(/^[a-zA-Z0-9\x21-\x7E]*$/) && char;
}

export function isContainsNumber(input: string): boolean {
  return HAS_NUMBER_REGEX.test(input);
}

export function isContainsLetters(input: string): boolean {
  return /[a-z]/i.test(input);
}

export function isEmail(input: string): boolean {
  return EMAIL_REGEX.test(input);
}

export function isEndsWithSlash(str: string) {
  return str.slice(-1) === '/';
}

export function removeHttps(url: string) {
  return url.replace(/^https?:\/\//, '');
}

export function removeHttp(url: string) {
  return url.replace(/^http?:\/\//, '');
}

export function replaceURLVariables(url: string, params?: Record<string, string>): string {
  if (isUndefined(params)) {
    return url;
  }

  let normalizedURL = url;

  const entries = Object.entries(params);

  entries.forEach(([keyToReplace, value]) => {
    try {
      if (normalizedURL) {
        normalizedURL = normalizedURL.replaceAll(`{ ${keyToReplace} }`, value?.toString());
      }

      if (normalizedURL) {
        normalizedURL = normalizedURL.replaceAll(`{${keyToReplace}}`, value?.toString());
      }
    } catch (error) {
      return normalizedURL;
    }
  });

  return normalizedURL;
}

export const onlyLetters = (value: string): string => {
  return value.replace(/[^a-zA-Z]+/g, '');
};

export const onlyDigits = (value: string): string => value.replace(regex.digits, '');

export function toPlainObject(value: any) {
  return JSON.parse(JSON.stringify(value));
}
