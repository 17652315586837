import { isClient } from '@vueuse/core';

import { CookieName } from '../../platform/cookies/cookies';
import useAppCookie from '../../platform/cookies/use-app-cookie';
import { RouteQuery } from '../../platform/router/query';
import { usePageMeta } from '../seo/use-page-meta';

export enum AppTheme {
  Dark = 'dark',
  Light = 'light',
}

const DEFAULT_THEME = AppTheme.Dark;

const getCSSClassByThemeName = (theme: AppTheme): string => {
  switch (theme) {
    case AppTheme.Light:
      return 'themeLight';
    case AppTheme.Dark:
    default:
      return 'themeDark';
  }
};

const themes = [AppTheme.Dark, AppTheme.Light];

const validateTheme = (theme: unknown): theme is AppTheme => themes.includes(theme as AppTheme);

export function useAppTheme() {
  const appThemeCookie = useAppCookie(CookieName.AppTheme, { default: () => DEFAULT_THEME });
  const route = useRoute();
  const { useHeadRaw } = usePageMeta();

  const setAppTheme = (theme: AppTheme) => {
    const oldClassName = getCSSClassByThemeName(appThemeCookie.value);

    const className = getCSSClassByThemeName(theme);
    appThemeCookie.value = theme;

    if (isClient) {
      document.documentElement.classList.remove(oldClassName);
      document.documentElement.classList.add(className);
      return;
    }

    useHeadRaw({ htmlAttrs: { class: className } });
  };

  const initializeTheme = () => {
    const themeQuery = route.query[RouteQuery.AppTheme];

    const isValidQueryTheme = validateTheme(themeQuery);
    if (isValidQueryTheme) {
      setAppTheme(themeQuery);
      return;
    }

    setAppTheme(appThemeCookie.value);
  };

  return {
    initializeTheme,
    setAppTheme,
  };
}
