import { LikeState } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';
import type { ILikeState } from '../collections/likes';

export function useLikesApi() {
  const { $http } = useNuxtApp();

  const postLike = (id: string, rate: LikeState): Promise<void> => {
    return $http.post<void>(ENDPOINTS.MOMENTS_LIKE, { rate }, { params: { id } });
  };

  const postLikes = ({ like, dislike, cancel }: ILikeState): Promise<void> => {
    return $http.post<void>(ENDPOINTS.MOMENTS_MULTIPLE_LIKES, { like, dislike, cancel });
  };

  return { postLike, postLikes };
}
