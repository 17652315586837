import { ProcessingMapper } from '../base/processing-mapper';
import { ApiPromoCodeStatus } from './bonus-types/promo-code-status';
import { PromoCodeStatus } from './promocode/promo-code-status';

export class PromoCodeStatusMapper {
  static map({
    available,
    metadata,
    promo_show,
    message,
    redirect_page,
    redirect_message,
  }: ApiPromoCodeStatus): PromoCodeStatus {
    return ProcessingMapper.process({
      available,
      metadata: {
        cardRequired: metadata?.card_required,
      },
      promoShow: promo_show,
      message,
      redirectPage: redirect_page,
      redirectMessage: redirect_message,
    });
  }
}
