import type { ApiDirector, Director } from '@SDK/api';
import { getPersonName } from '@SDK/api';

import { ProcessingMapper } from '../base/processing-mapper';

export class DirectorMapper {
  static mapMany(directors: ApiDirector[]): Director[] {
    return ProcessingMapper.process(directors.map(DirectorMapper.map));
  }

  static map({ first_name, last_name, ...rest }: ApiDirector): Director {
    return ProcessingMapper.process({
      name: getPersonName(first_name, last_name),
      ...rest,
    });
  }
}
