<template>
  <app-modal-wrapper :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'deleteAccountConfirmModal')">
    <modal-title :title="$t('lk.deleteAccount.confirmModalTitle')" />
    <modal-description :description="$t('lk.deleteAccount.confirmModalDescription')" />

    <div :class="$style.buttons">
      <app-button
        variation="button-primary"
        :text="$t('common.cancel')"
        :title="$t('common.cancel')"
        :aria-label="$t('common.cancel')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'rejectRemoveButton')"
        @click="closeModal"
      />

      <app-button
        variation="link-alert"
        :text="$t('common.delete')"
        :title="$t('common.delete')"
        :aria-label="$t('common.delete')"
        aria-haspopup="true"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.LK, 'confirmRemoveButton')"
        @click="onConfirmDelete"
      />
    </div>
  </app-modal-wrapper>
</template>

<script lang="ts" setup>
import useAuthActions from '../../code/auth/use-auth-actions';
import { useLayoutStore } from '../../stores/use-layout-store';
import AppButton from '../ui/AppButton.vue';
import ModalDescription from './ModalDescription.vue';
import ModalTitle from './ModalTitle.vue';

const layoutStore = useLayoutStore();
const { deleteUser } = useAuthActions();

const onConfirmDelete = async () => {
  await deleteUser();

  layoutStore.setCurrentModalName('DeleteAccountSuccessModal', { hasCloseIcon: true });
};

const closeModal = () => {
  layoutStore.setCurrentModalName(null);
};
</script>

<style lang="scss" module>
.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: var(--g-spacing-8);
  margin-top: var(--g-spacing-24);
}
</style>
