export const maxAge = {
  ONE_HOUR: 60 * 60,
  ONE_DAY: 60 * 60 * 24,
  NINETY_DAYS: 60 * 60 * 24 * 90,
  ONE_WEEK: 60 * 60 * 24 * 7,
  ONE_MONTH: 60 * 60 * 24 * 30,
  ONE_YEAR: 60 * 60 * 24 * 365,
};

export enum CookieName {
  DebugProductionApiForced = 'viju_debug.production_api_forced',
  GoogleAnalyticsId = '_ga',
  IsBarrierPassed = 'isBarrierPassed',
  VisitorId = 'visitor-id',
  UserId = 'user-id',
  Auth = 'auth',
  ReferralModalShowingDate = 'referral-popup-show-date',
  BookmarkAddInfoCounter = 'bookmarkAddInfoCounter',
  BookmarkRemoveInfoCounter = 'bookmarkRemoveInfoCounter',
  AppLanguage = 'viju.app_language',
  AppTheme = 'viju.app_theme',
  Collection = 'collection',
  QuizShowed = 'quizShowed',
  Promocode = 'viju.promocode_info',
}

export const cookies = {
  visitorId: {
    maxAge: maxAge.ONE_YEAR,
  },
  userId: {
    maxAge: maxAge.ONE_YEAR,
  },
  auth: {
    maxAge: maxAge.ONE_YEAR + maxAge.ONE_MONTH,
  },
  referralPopupShowDate: {
    maxAge: maxAge.ONE_MONTH + maxAge.ONE_WEEK,
  },
  quizShowed: {
    maxAge: maxAge.ONE_YEAR,
  },
  promoCode: {
    maxAge: maxAge.ONE_WEEK,
  },
};
