import { ENDPOINTS } from '../../platform/http';
import { useLogger } from '../../platform/logger/use-logger';

export default function useParentalControlApi() {
  const { $http } = useNuxtApp();
  const logger = useLogger();

  const setNoChildrenAccess = (): Promise<void> => {
    return $http.post(ENDPOINTS.PARENTAL_SET_NO_CHILDREN_ACCESS, null);
  };

  const setChildrenAccess = (code: string): Promise<void> => {
    return $http.post(ENDPOINTS.PARENTAL_SET_CHILDREN_ACCESS, { code });
  };

  const checkPinCode = async (code: string): Promise<boolean> => {
    try {
      await $http.get(ENDPOINTS.PARENTAL_CHECK_PIN_CODE, { query: { code } });
      return true;
    } catch (error) {
      logger.error('useParentalControlApi#checkpinCode', error);

      return false;
    }
  };

  return { setChildrenAccess, setNoChildrenAccess, checkPinCode };
}
