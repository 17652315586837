import type {
  ApiMovie,
  ApiStreamManifest,
  FilteredMedia,
  IGetCatalogContentParams,
  StreamManifest,
  Year,
} from '@package/sdk/src/api';
import { MediaContentType, MovieMapper, StreamManifestMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';
import { RequestId } from '../requestId';
import { useSeoApi } from '../seo/use-seo-api';

export function useMovieApi() {
  const { $http } = useNuxtApp();
  const seoApi = useSeoApi();

  const getStreamManifest = (id: string): Promise<StreamManifest> => {
    return $http
      .get<ApiStreamManifest>(ENDPOINTS.CONTENT_MOVIE_MANIFEST, { params: { id } })
      .then(StreamManifestMapper.map);
  };

  const fetchMovie = (id: string) => {
    return $http
      .get<ApiMovie>(ENDPOINTS.CONTENT_MOVIES_ID, {
        params: { id },
        query: {
          with_content_match_percent_by_profile: true,
          platform: 'web',
        },
      })
      .then(MovieMapper.map);
  };

  const fetchMovies = async ({
    order,
    page,
    years,
    countries,
    genres,
    with_locked,
    per_page,
  }: IGetCatalogContentParams): Promise<FilteredMedia> => {
    const params = {
      years: years?.map((year: Year) => ({ from: year.from, to: year.to })),
      genres_slugs: genres?.map((genre) => genre.replaceAll('_', '-')),
      countries_slugs: countries?.map((country) => country.replaceAll('_', '-')),
      per_page,
      order,
      page,
      with_locked,
    };

    const result = await seoApi.fetchCatalogSeoData({ contentType: MediaContentType.MOVIE, query: params });

    const { data, headers } = await $http.sendRawRequest<{ data: ApiMovie[]; headers: Record<string, any> }>(
      'GET',
      ENDPOINTS.CONTENT_MOVIES,
      undefined,
      { requestId: RequestId.GetMovieList, query: params },
    );

    return {
      contents: (data || []).map(MovieMapper.map),
      pageTitle: result?.base,
      totalCount: Number(headers['total-count'] || 0),
    } as FilteredMedia;
  };

  return { fetchMovie, getStreamManifest, fetchMovies };
}
