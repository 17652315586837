import type {
  ApiBonus,
  ApiPromocodeData,
  ApiPromoCodeStatus,
  Bonus,
  PromocodeData,
  PromoCodeStatus,
} from '@package/sdk/src/api';
import { BonusMapper, PromocodeDataMapper, PromoCodeStatusMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export function usePromocodeApi() {
  const { $http } = useNuxtApp();

  const applyPromocode = (promocode: string): Promise<Bonus> => {
    return $http.post<ApiBonus>(ENDPOINTS.BONUS_PROMO_CODES_APPLY, { code: promocode }).then(BonusMapper.map);
  };

  const checkPromocode = (code: string, userId?: string): Promise<PromoCodeStatus> => {
    const body = { code };

    if (userId) {
      Reflect.set(body, 'user_id', userId);
    }

    return $http
      .post<ApiPromoCodeStatus>(ENDPOINTS.BONUS_PROMO_CODES_CHECK, body, { signRequest: true })
      .then(PromoCodeStatusMapper.map);
  };

  const getPromocodeData = (code: string): Promise<PromocodeData> => {
    return $http
      .get<ApiPromocodeData>(ENDPOINTS.OFFERS_BY_PROMO, { query: { code }, signRequest: true })
      .then(PromocodeDataMapper.map);
  };

  return { applyPromocode, checkPromocode, getPromocodeData };
}
