<template>
  <div :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'checkPinCodeModal')">
    <modal-title :title="$t('parentalControl.modal.checkPinCode')" />
    <modal-description :description="$t('parentalControl.modal.checkPinCodeTitle')" />

    <pin-code-input
      :model-value="pinCode"
      autofocus
      :class="$style.pinCodeInput"
      :is-error="error !== null"
      :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'checkPinCodeInput')"
      @update:model-value="updatePinCode"
      @submit="onCheckSubmit"
    />

    <div v-if="error !== null" :class="$style.pinCodeError">
      <template v-if="error === PinCodeError.Wrong">
        {{ $t('parentalControl.modal.wrongPinCode') }}
      </template>
      <template v-else>{{ $t('parentalControl.modal.unexpectedError') }}</template>
    </div>

    <div :class="$style.profileLostMessage">
      {{ $t('parentalControl.modal.lostControl') }}
      <a :class="$style.email" :href="'mailto:' + company.supportEmail">{{ company.supportEmail }}</a>
    </div>

    <div :class="$style.pinCodeModalActions">
      <app-button
        v-if="cancelType === 'logout'"
        variation="link-alert"
        :text="$t('auth.accountLogout')"
        :aria-label="$t('ariaLabel.parentalControl.logout')"
        :title="$t('ariaLabel.parentalControl.logout')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'logoutButton')"
        @click="onLogoutClick"
      />
      <app-button
        v-else-if="cancelType === 'cancel'"
        variation="link-primary"
        :text="$t('common.cancel')"
        :aria-label="$t('ariaLabel.parentalControl.cancel')"
        :title="$t('ariaLabel.parentalControl.cancel')"
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.ParentalModal, 'cancelButton')"
        @click="$emit('cancel')"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useParentalControlAnalytics } from '@package/sdk/src/analytics';
import { storeToRefs } from 'pinia';
import { ref, watch } from 'vue';

import useAuthActions from '../../code/auth/use-auth-actions';
import useParentalControlApi from '../../code/profile/use-parental-control-api';
import { useLogger } from '../../platform/logger/use-logger';
import useHostVariation from '../../platform/variation/use-host-variation';
import { getCompanyInformation } from '../../platform/variation/variation';
import { useLayoutStore } from '../../stores/use-layout-store';
import useParentalControlStore from '../../stores/use-parental-control-store';
import ModalDescription from '../modals/ModalDescription.vue';
import ModalTitle from '../modals/ModalTitle.vue';
import AppButton from '../ui/AppButton.vue';
import PinCodeInput from './PinCodeInput.vue';

enum PinCodeError {
  Wrong,
  Unknown,
}

withDefaults(
  defineProps<{
    cancelType?: 'cancel' | 'logout';
  }>(),
  {
    cancelType: 'logout',
  },
);

const emit = defineEmits<{
  (e: 'cancel'): void;
  (e: 'success'): void;
  (e: 'unsuccessful'): void;
}>();

const { onAutoParentalCodeEnterDisplayed } = useParentalControlAnalytics();
const layoutStore = useLayoutStore();

const { appVariation, appLanguage } = useHostVariation();
const { pinCode } = storeToRefs(useParentalControlStore());

const company = getCompanyInformation(appVariation.value, appLanguage.value);
const error = ref<PinCodeError | null>(null);

const isPinCodeRequestLoading = ref(false);

const updatePinCode = (value: string) => {
  pinCode.value = value;
};

const onLogoutClick = () => {
  logoutUser();
  layoutStore.setCurrentModalName(null);
};

watch(pinCode, () => {
  error.value = null;
});

const { logoutUser } = useAuthActions();
const parentalApi = useParentalControlApi();
const logger = useLogger();

onMounted(() => {
  onAutoParentalCodeEnterDisplayed();
});

const onCheckSubmit = async () => {
  isPinCodeRequestLoading.value = true;

  try {
    const isSuccess = await parentalApi.checkPinCode(pinCode.value);

    if (!isSuccess) {
      error.value = PinCodeError.Wrong;
      emit('unsuccessful');
      return;
    }

    emit('success');
  } catch (err) {
    logger.error(err);
    error.value = PinCodeError.Unknown;
    return;
  } finally {
    isPinCodeRequestLoading.value = false;
  }
};
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.pinCodeModalActions {
  display: grid;
  row-gap: var(--g-spacing-8);
  width: 100%;
  margin-top: var(--g-spacing-32);
}

.pinCodeInput {
  margin: var(--g-spacing-24) 0;
}

.pinCodeError {
  margin-top: var(--g-spacing-8);
  color: var(--color-text-negative);
}

.profileLostMessage {
  @include fonts.WebBody-3;
  max-width: 400px;
  margin-top: var(--g-spacing-12);
  color: var(--color-text-tertiary);
}

.email {
  color: var(--color-text-link);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--color-states-link-hover);
  }
}
</style>
