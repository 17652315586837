import { computed } from 'vue';

import { pick } from '../../platform/base/function';
import { UtmParams } from '../../platform/base/utm';
import type { IUserCreateSession } from '../user/use-users-api';
import { OAuthProviderType } from './o-auth';
import type { OAuthResponse } from './types/o-auth';

export function useOAuthResponse() {
  const route = useRoute();

  const utmParams = computed(() => {
    const query = route.query;
    return pick(query, Object.values(UtmParams));
  });

  const mapResponseToAuthBody = ({ provider, token, uuid }: OAuthResponse): Partial<IUserCreateSession> => ({
    credentials: {
      type: provider,
      silent_token: provider === OAuthProviderType.VK ? token : undefined,
      oauth_token: provider === OAuthProviderType.Yandex ? token : undefined,
      uuid,
    },
    user_parameters: !Object.values(utmParams.value).length ? undefined : utmParams.value,
  });

  return { mapResponseToAuthBody };
}
