import { Media } from '../../api/content/types/media';
import { AnalyticEventNameNew } from '../analytic-event-name';
import { AnalyticPayloadLabelName } from '../analytic-label-name';
import { AnalyticPageName } from '../analytic-page-name';

interface OnGotoSearchPagePayload {
  request: string;
  from: string;
  page: AnalyticPageName;
}

export function useSearchPageAnalytics() {
  const onSearchRequestEntering = (searchText: string) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSearchRequestEntering,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.From,
          value: window.$dsmlService.getCurrentAnalyticPageValue(),
        },
        {
          label: AnalyticPayloadLabelName.Query,
          value: searchText,
        },
      ],
    });
  };

  const onClickSearchRequestCancel = (request: string) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.ClickSearchRequestCancel,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: request,
        },
      ],
    });
  };

  const onSearchResultsDisplayed = (searchText: string, contents: Media[], isSemanticRequest: boolean) => {
    const ids: string[] = [];

    contents.forEach((content) => {
      ids.push(content.id);
    });

    window.$dsmlService.sendAnalyticEvent({
      event: isSemanticRequest
        ? AnalyticEventNameNew.AutoSearchResultsFullDisplayed
        : AnalyticEventNameNew.AutoSearchResultsPreviewDisplayed,
      page: AnalyticPageName.Search,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: searchText,
        },
        {
          label: AnalyticPayloadLabelName.ListItemId,
          value: ids,
        },
      ],
    });
  };

  const onShowSearchPage = () => {
    window.$dsmlService.sendAnalyticEvent({
      name: AnalyticEventNameNew.ShowSearchPage,
      page: AnalyticPageName.Search,
    });
  };

  const onGotoSearchPage = ({ request, from, page }: OnGotoSearchPagePayload) => {
    window.$dsmlService.sendAnalyticEvent({
      event: AnalyticEventNameNew.GotoSearchPage,
      page,
      values: [
        {
          label: AnalyticPayloadLabelName.Request,
          value: request,
        },
        {
          label: AnalyticPayloadLabelName.From,
          value: from,
        },
      ],
    });
  };

  return {
    onSearchRequestEntering,
    onClickSearchRequestCancel,
    onSearchResultsDisplayed,
    onShowSearchPage,
    onGotoSearchPage,
  };
}
