import { AnalyticPageName, useKinomAnalytics } from '@package/sdk/src/analytics';
import type { Moment } from '@package/sdk/src/api';
import { storeToRefs } from 'pinia';

import { useSessionStore } from '../../stores/use-session-store';
import type { LikesContext } from './likes';
import { useLocalLikes } from './use-local-likes';
import { useRemoteLikes } from './use-remote-likes';

export function useLikes() {
  const { isAuth } = storeToRefs(useSessionStore());
  const { context: remoteLikesContext } = useRemoteLikes();
  const { context: localLikesContext, uploadLocalLikes } = useLocalLikes();
  const kinomAnalytics = useKinomAnalytics();
  const { $http } = useNuxtApp();

  const likesContext: LikesContext = isAuth.value ? remoteLikesContext : localLikesContext;

  const like = (moment: Moment) => {
    const { id } = moment;

    kinomAnalytics.onClickLikeKinomSet({
      page: AnalyticPageName.MyChannel,
      moment,
    });

    $http.cache.clear();

    return likesContext.addLike(id);
  };

  const dislike = (moment: Moment) => {
    const { id } = moment;

    kinomAnalytics.onClickKinomDislikeSet({
      page: AnalyticPageName.MyChannel,
      moment,
    });

    $http.cache.clear();

    return likesContext.addDislike(id);
  };

  const removeLike = (moment: Moment) => {
    const { id } = moment;

    kinomAnalytics.onClickLikeKinomRemove({
      page: AnalyticPageName.MyChannel,
      moment,
    });

    $http.cache.clear();

    return likesContext.removeLike(id);
  };

  const removeDislike = (moment: Moment) => {
    const { id } = moment;

    kinomAnalytics.onClickKinomDislikeRemove({
      page: AnalyticPageName.MyChannel,
      moment,
    });

    $http.cache.clear();

    return likesContext.removeDislike(id);
  };

  return {
    like,
    dislike,
    removeLike,
    removeDislike,
    uploadLocalLikes,
  };
}
