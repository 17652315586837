import { UTCDate } from '@date-fns/utc';
import { isDefined } from '@package/sdk/src/core';
import CryptoJS from 'crypto-js';
import Base64 from 'crypto-js/enc-base64';
import Utf16 from 'crypto-js/enc-utf16';
import MD5 from 'crypto-js/md5';
import { callWithNuxt } from 'nuxt/app';
import type { FetchContext } from 'ofetch';
import { storeToRefs } from 'pinia';
import { v4 } from 'uuid';

import { useLayoutStore } from '../../stores/use-layout-store';
import { ensureStartSlash } from '../base/string';
import { CookieName, cookies } from '../cookies/cookies';
import useAppCookie from '../cookies/use-app-cookie';
import { getLanguageByISO639Format } from '../localization/language';
import { AppVariation } from '../variation/interfaces';
import useVariationVariables from '../variation/use-variation-variables';
import { HttpInterceptor } from './http-interceptor';

const keyForEncr = '0YasTYdd8KR31crx4ZfUhyJv4yINTX74l7kpmDAeJhv9aH3Ur0rYFzEMPo04LpFdikDjBuexGStXOUqPwOYcjA==';

const doSignRequest = (context: FetchContext, headers: Headers) => {
  const { options, request } = context;

  const requestType = options.method?.toUpperCase() || 'POST';

  const baseRequestString = request.toString();
  // Важно чтобы строка запроса начиналась с /, иначе токен будет невалидный, и 403
  const encodedURIString = ensureStartSlash(baseRequestString);

  const encReqBody = Base64.stringify(MD5(JSON.stringify(options.body)));
  const reqCTHeader = 'application/json';
  const platformDate = new UTCDate().toUTCString();

  const encryptedParts = [requestType, reqCTHeader, encReqBody, encodedURIString, platformDate];

  const stringForEncrypted = encryptedParts.join(',');
  const stringForEncryptedUtf16 = Utf16.stringify(Base64.parse(Base64.stringify(Utf16.parse(stringForEncrypted))));

  const platformLogin = 'web';
  const platformToken = Base64.stringify(CryptoJS.HmacSHA1(stringForEncryptedUtf16, keyForEncr));

  headers.set('Content-Type', reqCTHeader);
  headers.set('Http-Date', platformDate);
  headers.set('Content-MD5', encReqBody);
  headers.set('Authorization', `APIAuth ${platformLogin}:${platformToken}`);
};

export class AuthInterceptor extends HttpInterceptor {
  onRequest(context: FetchContext) {
    return callWithNuxt(this.nuxtApp, () => {
      const { request, options } = context;
      const isRequestSigned = isDefined(options.signRequest) ? options.signRequest : false;
      const isForceAPIAuthToken = options.isForceAPIAuthToken;
      const { getAppVariation } = useVariationVariables();
      const timezoneOffset = new UTCDate().getTimezoneOffset();

      const { currentAppLanguage } = storeToRefs(useLayoutStore());

      const { headers: contextHeaders } = options;
      const headers = new Headers(contextHeaders);

      const accessToken = useAppCookie(CookieName.Auth, { maxAge: cookies.auth.maxAge, path: '/' });
      const visitorId = useAppCookie(CookieName.VisitorId, {
        maxAge: cookies.visitorId.maxAge,
        path: '/',
        default: () => v4(),
      });

      const setupServerHeaders = () => {
        const requestHeaders = useRequestEvent(this.nuxtApp)?.node.req.headers;

        if (!requestHeaders) {
          return;
        }

        const host = requestHeaders.host;

        if (host) {
          const variation = getAppVariation(host);

          const variationHeader = (() => {
            if (variation === AppVariation.Am) {
              return 'AM';
            }

            return 'RU';
          })();

          headers.set('X-Viju-Domain', variationHeader);
        }

        const entries = Object.entries(requestHeaders).filter(([key]) => key !== 'host');

        entries.forEach(([key, value]) => headers.set(key, value as string));
      };

      const signRequest = () => {
        if (isRequestSigned) {
          doSignRequest(context, headers);
        }

        if (!isForceAPIAuthToken && accessToken.value?.token) {
          headers.set('Authorization', `Bearer ${accessToken.value.token}`);
        }
      };

      signRequest();

      if (process.server) {
        setupServerHeaders();
      }

      headers.set('Accept-Language', getLanguageByISO639Format(currentAppLanguage.value));
      headers.set('VisitorId', visitorId.value);
      headers.set('X-TimeZone', String(timezoneOffset / 60));

      options.headers = headers;

      if (process.server) {
        console.info('%c INFO', 'color: #33f', 'Request Path', request.toString());
      }
    });
  }
}
