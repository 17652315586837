import type { ISubscriptionCard, Offer } from '@package/sdk/src/api';
import { SubscriptionPlatform } from '@package/sdk/src/api';
import { UnexpectedComponentStateError } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';

import useLocale from '../../platform/localization/use-locale';
import { useOffersStore } from '../../stores/use-offers-store';
import { useSessionStore } from '../../stores/use-session-store';
import useAuthActions from '../auth/use-auth-actions';
import { useOffersApi } from '../offer/use-offers-api';

export function useSubscriptionManagement() {
  const offersApi = useOffersApi();
  const sessionStore = useSessionStore();
  const { subscription, currentUserPlatform, isSubscriptionRecurrent } = storeToRefs(useOffersStore());
  const { formatDate } = useLocale();
  const { getBullets } = useAuthActions();
  const { translate } = useLocale();

  function getCancellationSubscriptionText(platform: SubscriptionPlatform) {
    const { translate } = useLocale();

    switch (platform) {
      case SubscriptionPlatform.Apple:
        return translate('lkPage.account.appleSubscriptionCancel');
      case SubscriptionPlatform.Google:
        return translate('lkPage.account.googleSubscriptionCancel');
      case SubscriptionPlatform.Partner:
        return translate('lkPage.account.partnerSubscriptionCancel');
      default:
        return '';
    }
  }

  function getRenewalSubscriptionText(platform: SubscriptionPlatform) {
    const { translate } = useLocale();

    switch (platform) {
      case SubscriptionPlatform.Partner:
        return translate('lkPage.account.partnerSubscriptionRenewal');
      default:
        return '';
    }
  }

  const getPaymentDate = () => {
    const endsAt = subscription.value?.endsAt;

    if (!endsAt) {
      throw new UnexpectedComponentStateError('endsAt');
    }

    return formatDate(new Date(endsAt));
  };

  const mapToSubscriptionCard = (offer: Offer): ISubscriptionCard => ({
    title: offer.lkSubscriptionTitle || '',
    subtitle: `${
      isSubscriptionRecurrent.value
        ? translate('lk.subscriptionCard.autoRenewal')
        : translate('lk.subscriptionCard.activeAt')
    } ${getPaymentDate()}`,
    bullets: getBullets(offer),
    image: offer.images?.lkSubscriptionBanner || '',
    cancellationInfo: getCancellationSubscriptionText(currentUserPlatform.value),
  });

  const toggleAutoRenew = async (cancelReasonsIds?: string[], reasonText?: string) => {
    return await offersApi
      .toggleAutoRenew(subscription.value?.id, cancelReasonsIds, reasonText)
      .then(async () => await sessionStore.updateCurrentUser());
  };

  const cancelSberAutoRenew = async () => {
    return await offersApi
      .cancelSberAutoRenew(subscription.value?.id)
      .then(async () => await sessionStore.updateCurrentUser());
  };

  return {
    getPaymentDate,
    mapToSubscriptionCard,
    toggleAutoRenew,
    cancelSberAutoRenew,
    getRenewalSubscriptionText,
    getCancellationSubscriptionText,
  };
}
