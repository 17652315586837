<template>
  <abstract-my-channel-popup :class="$style.myChannelOnboarding">
    <p :class="$style.title">{{ $t('header.myChannel.onboarding.title') }}</p>
    <p :class="$style.text">{{ $t('header.myChannel.onboarding.description') }}</p>
  </abstract-my-channel-popup>
</template>

<script lang="ts" setup>
import { useKinomAnalytics } from '@package/sdk/src/analytics';
import { onMounted } from 'vue';

import getAnalyticPageValue from '../../code/kmza/get-kmza-page-value';
import AbstractMyChannelPopup from './AbstractMyChannelPopup.vue';

const kinomAnalytics = useKinomAnalytics();
const route = useRoute();

onMounted(() => {
  kinomAnalytics.onShowMyChannelOnboardPopup({ page: getAnalyticPageValue(route.name) });
});
</script>

<style lang="scss" module>
@use '@package/ui/src/styles/fonts.scss' as fonts;

.myChannelOnboarding {
  --padding-and-offset: 68px;
  position: absolute;
  top: var(--padding-and-offset);
}

.title {
  @include fonts.WebSubtitle-2;
  margin-bottom: var(--g-spacing-12);
}

.text {
  @include fonts.WebBody-2;
}
</style>
