import type { ApiPageStrings, PageSlug, PageStrings } from '@package/sdk/src/api';
import { PageStringsMapper } from '@package/sdk/src/api';

import { ENDPOINTS } from '../../platform/http';

export function useFrontendPagesApi() {
  const { $http } = useNuxtApp();

  const fetchPage = (slug: PageSlug): Promise<PageStrings> => {
    return $http
      .get<ApiPageStrings>(ENDPOINTS.PAGES_FRONTEND_SLUG, { params: { slug, platform: 'web' } })
      .then(PageStringsMapper.map);
  };

  return { fetchPage };
}
