<template>
  <section
    ref="notificationEl"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Notification, 'section')"
    :class="{
      [$style.notificationContainer]: true,
      [$style.mobile]: $isMobile,
      [$style.top]: position === 'top',
      [$style.bottom]: position === 'bottom',
    }"
  >
    <div :class="$style.wrapper">
      <p :class="$style.text" v-html="currentNotification.message"></p>
      <app-slot-button
        :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Notification, 'closeButton')"
        :class="$style.iconClose"
        @click="onCloseButtonClick()"
      >
        <icon-close size="small" />
      </app-slot-button>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { KeyCode, UnexpectedComponentStateError } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';

import useSafeScrollableBodyElement from '../../code/layout/use-safe-scrollbable-body-element';
import type { NotificationPosition } from '../../code/notifications/notification';
import useMobile from '../../platform/layout/use-mobile';
import { useLayoutStore } from '../../stores/use-layout-store';
import IconClose from '../icons/IconClose.vue';
import AppSlotButton from '../ui/AppSlotButton.vue';

const layoutStore = useLayoutStore();
const { $keyboardHandler } = useNuxtApp();
const isMobile = useMobile();
const { currentNotification } = storeToRefs(layoutStore);
const bodyEl = useSafeScrollableBodyElement();

const position = computed<NotificationPosition>(() => currentNotification.value?.position || 'top');
const hideTimeoutMs = computed(() => currentNotification.value?.hideTimeoutMs || 3000);
let hideTimeoutId: number | undefined;

const notificationEl = ref<HTMLElement>();

interface IModalCouponState {
  left: number;
  initialTouchX: number;
  isMoving: boolean;
  offsetWidth: number;
}

const state: IModalCouponState = {
  left: 0,
  initialTouchX: 0,
  isMoving: false,
  offsetWidth: 0,
};

const doRemoveNotification = () => {
  if (!currentNotification.value) {
    throw new UnexpectedComponentStateError('currentNotification');
  }

  layoutStore.removeNotification(currentNotification.value.id);
};

const onPointerStart = (event: PointerEvent) => {
  state.initialTouchX = event.clientX;
  state.isMoving = true;
  bodyEl.value.classList.add('hide-global-overflow');
};

const onPointerMove = (event: PointerEvent) => {
  const { initialTouchX, isMoving } = state;

  if (!isMoving) {
    return;
  }

  event.preventDefault();

  const touchX = event.clientX;
  const diffX = touchX - initialTouchX!;

  notificationEl.value!.style.transform = `translateX(${diffX}px)`;

  state.left = diffX;
};

const onPointerEnd = () => {
  const { offsetWidth } = state;
  bodyEl.value.classList.remove('hide-global-overflow');

  if (!state.isMoving) {
    return;
  }

  state.isMoving = false;

  if (Math.abs(state.left!) > offsetWidth! / 2) {
    doRemoveNotification();
  } else {
    notificationEl.value!.style.transform = 'translateX(0px)';
  }
};

const keyboardHandler = $keyboardHandler.on(KeyCode.Escape, () => {
  doRemoveNotification();
});

onMounted(() => {
  if (!notificationEl.value) {
    throw new UnexpectedComponentStateError('notificationEl');
  }

  if (isMobile) {
    notificationEl.value.addEventListener('pointerdown', onPointerStart);
    notificationEl.value.addEventListener('pointermove', onPointerMove);
    notificationEl.value.addEventListener('pointerup', onPointerEnd);
  }

  state.offsetWidth = notificationEl.value.offsetWidth;

  hideTimeoutId = window.setTimeout(() => {
    doRemoveNotification();
  }, hideTimeoutMs.value);
});

onBeforeUnmount(() => {
  if (hideTimeoutId) {
    window.clearTimeout(hideTimeoutId);
  }

  keyboardHandler.dispose();

  if (!notificationEl.value) {
    throw new UnexpectedComponentStateError('notificationEl');
  }

  if (isMobile) {
    notificationEl.value.removeEventListener('pointerdown', onPointerStart);
    notificationEl.value.removeEventListener('pointermove', onPointerMove);
    notificationEl.value.removeEventListener('pointerup', onPointerEnd);
  }
});

const onCloseButtonClick = () => {
  doRemoveNotification();
};
</script>

<style lang="scss" module>
.notificationContainer {
  position: fixed;
  z-index: var(--z-index-tooltip);
  display: block;
  max-width: 450px;
  overflow: hidden;
}

.top {
  top: var(--app-header-height);
  right: 80px;
}

.mobile.top {
  top: var(--app-header-height--mobile);
  left: 40px;
  right: 40px;
}

.bottom {
  bottom: var(--app-header-height);
  right: 80px;
}

.mobile.bottom {
  bottom: 40px;
  left: 40px;
  right: 40px;
}

.iconClose {
  margin-left: var(--g-spacing-10);
  color: var(--color-text-primary);
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px var(--g-spacing-20);
  border-radius: var(--g-border-round-10);
  background-color: var(--color-bg-secondary);
}

.text {
  color: var(--color-text-primary);

  & > a {
    color: var(--color-text-link);
  }
}
</style>
