<template>
  <app-icon :width="bounds.width" :height="bounds.height" :class="$style.icon">
    <svg :viewBox="getSVGViewboxByIconSize(props.size)" fill="none" xmlns="http://www.w3.org/2000/svg">
      <template v-if="size === 'extra-small' && filled">
        <path
          d="M2.14884 2.85953C3.40573 1.35847 5.13087 2.14915 5.91658 2.78912C5.96517 2.82869 6.03483 2.82869 6.08342 2.78912C6.86913 2.14915 8.59427 1.35847 9.85116 2.85953C11.1546 4.41621 10.1968 6.30218 9.55492 7.05058C9.04262 7.61525 7.70465 8.88619 6.26198 9.83256C6.10277 9.93699 5.89723 9.93699 5.73802 9.83256C4.29535 8.88619 2.95738 7.61525 2.44508 7.05058C1.80322 6.30218 0.845372 4.41621 2.14884 2.85953Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && filled">
        <path
          d="M2.22326 3.28929C4.10859 1.03771 6.69631 2.22373 7.87487 3.18368C7.94776 3.24304 8.05224 3.24304 8.12513 3.18368C9.30369 2.22373 11.8914 1.03771 13.7767 3.28929C15.7319 5.62432 14.2952 8.45327 13.3324 9.57587C12.5639 10.4229 10.557 12.3293 8.39297 13.7488C8.15416 13.9055 7.84584 13.9055 7.60703 13.7488C5.44302 12.3293 3.43607 10.4229 2.66763 9.57587C1.70484 8.45327 0.268058 5.62432 2.22326 3.28929Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && filled">
        <path
          d="M4.29768 5.71906C6.81146 2.71695 10.2617 4.2983 11.8332 5.57824C11.9303 5.65739 12.0697 5.65739 12.1668 5.57824C13.7383 4.2983 17.1885 2.71695 19.7023 5.71906C22.3093 8.83243 20.3936 12.6044 19.1098 14.1012C18.0852 15.2305 15.4093 17.7724 12.524 19.6651C12.2055 19.874 11.7945 19.874 11.476 19.6651C8.5907 17.7724 5.91476 15.2305 4.89017 14.1012C3.60645 12.6044 1.69074 8.83243 4.29768 5.71906Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && filled">
        <path
          d="M5.08838 7.10183C8.64956 2.84884 13.5375 5.0891 15.7637 6.90234C15.9013 7.01447 16.0987 7.01447 16.2363 6.90234C18.4625 5.0891 23.3504 2.84884 26.9116 7.10183C30.6048 11.5124 27.8909 16.856 26.0723 18.9765C24.6208 20.5764 20.8298 24.1774 16.7423 26.8588C16.2912 27.1547 15.7088 27.1547 15.2577 26.8588C11.1702 24.1774 7.37924 20.5764 5.92774 18.9765C4.10914 16.856 1.39522 11.5124 5.08838 7.10183Z"
          fill="currentColor"
        />
      </template>

      <template v-if="size === 'extra-small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M4.04689 3.26083C3.73707 3.2202 3.43221 3.2739 3.10723 3.66202C2.75121 4.08719 2.69456 4.53498 2.79167 5.00553C2.89587 5.51048 3.17314 5.9748 3.38356 6.22462C3.79896 6.67939 4.83823 7.67098 6 8.49751C7.16177 7.67098 8.20104 6.67939 8.61645 6.22462C8.82687 5.97479 9.10413 5.51048 9.20833 5.00553C9.30544 4.53498 9.24879 4.08719 8.89277 3.66202C8.56779 3.2739 8.26293 3.2202 7.95311 3.26083C7.56352 3.31192 7.14666 3.53527 6.87283 3.75831C6.36456 4.1723 5.63544 4.1723 5.12717 3.75831C4.85334 3.53527 4.43648 3.31192 4.04689 3.26083ZM9.55492 7.05058C10.1968 6.30218 11.1546 4.41621 9.85116 2.85953C8.59427 1.35847 6.86913 2.14915 6.08342 2.78912C6.03483 2.82869 5.96517 2.82869 5.91658 2.78912C5.13087 2.14915 3.40573 1.35847 2.14884 2.85953C0.845372 4.41621 1.80322 6.30218 2.44508 7.05058C2.95738 7.61525 4.29535 8.88619 5.73802 9.83256C5.89723 9.93699 6.10277 9.93699 6.26198 9.83256C7.70465 8.88619 9.04262 7.61525 9.55492 7.05058Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'small' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.08658 3.76731C4.58418 3.70143 4.08282 3.79437 3.565 4.41278C3.00141 5.08586 2.91346 5.79882 3.06508 6.53355C3.22663 7.31636 3.65477 8.03232 3.98136 8.41939C4.61708 9.11575 6.21827 10.6426 8 11.8995C9.78172 10.6426 11.3829 9.11575 12.0186 8.41939C12.3452 8.03232 12.7734 7.31637 12.9349 6.53355C13.0865 5.79882 12.9986 5.08586 12.435 4.41278C11.9172 3.79437 11.4158 3.70143 10.9134 3.76731C10.2993 3.84783 9.65295 4.19629 9.2303 4.54054C8.51387 5.12408 7.48613 5.12408 6.7697 4.54054C6.34705 4.19629 5.70068 3.84783 5.08658 3.76731ZM13.3324 9.57587C14.2952 8.45327 15.7319 5.62432 13.7767 3.28929C11.8914 1.03771 9.30369 2.22373 8.12513 3.18368C8.05224 3.24304 7.94776 3.24304 7.87487 3.18368C6.69631 2.22373 4.10859 1.03771 2.22326 3.28929C0.268058 5.62432 1.70484 8.45327 2.66763 9.57587C3.43607 10.4229 5.44302 12.3293 7.60703 13.7488C7.84584 13.9055 8.15416 13.9055 8.39297 13.7488C10.557 12.3293 12.5639 10.4229 13.3324 9.57587Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'medium' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.21556 6.03385C7.42007 5.91552 6.61585 6.06585 5.8311 7.00304C5.00079 7.99466 4.87558 9.05538 5.09366 10.1121C5.32303 11.2236 5.92658 12.2301 6.39131 12.7792C7.27269 13.7456 9.52093 15.887 12 17.6061C14.4791 15.887 16.7273 13.7456 17.6087 12.7792C18.0734 12.2301 18.677 11.2236 18.9063 10.1121C19.1244 9.05538 18.9992 7.99466 18.1689 7.00304L19.7023 5.71906C22.3093 8.83243 20.3936 12.6044 19.1098 14.1012C18.0852 15.2305 15.4093 17.7724 12.524 19.6651C12.2055 19.874 11.7945 19.874 11.476 19.6651C8.5907 17.7724 5.91476 15.2305 4.89017 14.1012C3.60645 12.6044 1.69074 8.83243 4.29768 5.71906C6.8671 2.6505 10.4149 4.37055 11.9347 5.66273C11.9729 5.6952 12.0271 5.6952 12.0653 5.66273L13.3608 7.18643C12.5757 7.85397 11.4243 7.85397 10.6392 7.18642C10.0738 6.7057 9.14215 6.17168 8.21556 6.03385ZM12.0653 5.66273L13.3608 7.18643C13.9262 6.7057 14.8579 6.17168 15.7844 6.03385C16.5799 5.91552 17.3842 6.06585 18.1689 7.00304L19.7023 5.71906C17.1329 2.6505 13.5851 4.37055 12.0653 5.66273Z"
          fill="currentColor"
        />
      </template>
      <template v-if="size === 'large' && !filled">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M14.1848 8.84072L14.1848 8.84071C13.3098 8.12799 11.953 7.38323 10.6016 7.20603C9.40981 7.04975 8.17883 7.30515 7.00516 8.70682C5.75002 10.2058 5.56471 11.8186 5.88956 13.3927C6.22851 15.0352 7.11623 16.5129 7.80396 17.3238C9.08641 18.731 12.3854 21.8706 16 24.3466C19.6146 21.8706 22.9136 18.731 24.196 17.3238C24.8838 16.5129 25.7715 15.0352 26.1104 13.3927C26.4353 11.8186 26.25 10.2058 24.9948 8.70682C23.8212 7.30515 22.5902 7.04975 21.3984 7.20603C20.047 7.38323 18.6902 8.12799 17.8152 8.84072C16.7581 9.70168 15.2419 9.70167 14.1848 8.84072ZM26.0723 18.9765C27.8909 16.856 30.6048 11.5124 26.9116 7.10183C23.3504 2.84884 18.4625 5.0891 16.2363 6.90234C16.0987 7.01447 15.9013 7.01447 15.7637 6.90234C13.5375 5.0891 8.64956 2.84884 5.08838 7.10183C1.39522 11.5124 4.10914 16.856 5.92774 18.9765C7.37924 20.5764 11.1702 24.1774 15.2577 26.8588C15.7088 27.1547 16.2912 27.1547 16.7423 26.8588C20.8298 24.1774 24.6208 20.5764 26.0723 18.9765Z"
          fill="currentColor"
        />
      </template>
    </svg>
  </app-icon>
</template>

<script lang="ts" setup>
import { computed } from 'vue';

import AppIcon from './AppIcon.vue';
import { type IconSize, getBoundsByIconSize, getSVGViewboxByIconSize } from './icon-types';

const props = defineProps<{
  size: IconSize;
  filled: boolean;
}>();

const bounds = computed(() => getBoundsByIconSize(props.size));
</script>

<style lang="scss" module>
.icon {
  transition: var(--g-animation-color-normal);
}
</style>
