import { ApplicationError } from '@package/sdk/src/core';

export function onUnexpectedError(e: unknown) {
  return console.error(e);
}

export class PromocodeValidationError extends ApplicationError {
  public readonly name = 'PromocodeValidationError';

  constructor(
    message: string,
    private readonly promocode: string,
  ) {
    super(message);
  }

  public toJSON(): Record<string, any> {
    const { promocode } = this;

    return {
      promocode,
    };
  }
}
