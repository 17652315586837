export enum ElementTestIdentifierScope {
  Header = 'header',
  Footer = 'footer',
  Auth = 'auth',
  Catalog = 'catalog',
  CancelSubscriptionModal = 'cancel-subscription-modal',
  Collections = 'compilations',
  Content = 'content',
  LK = 'lk',
  MyCollection = 'my-collection',
  MyChannel = 'my-channel',
  Notification = 'notification',
  ParentalModal = 'parental-modal',
  Playlists = 'playlists',
  Referral = 'referral',
  TV = 'tv',
  ThankYou = 'thank-you',
  Main = 'main',
  Contacts = 'contacts',
  Search = 'search',
  TvModal = 'tv-modal',
  QuizModal = 'quiz-modal',
  WatchAllEpisodeModal = 'watch-all-episodes-modal',
  Common = 'common',
}

export const TestIdentifierElementMap = {
  [ElementTestIdentifierScope.Header]: {
    mobileBurgerMenu: 'mobileBurgerMenu',
    appLogo: 'appLogo',
    promocodeButton: 'promocodeButton',
    openSearchButton: 'openSearchButton',
    navBlock: 'navBlock',
    navBlockLink: 'navBlockLink',
    bookmarkButton: 'bookmarkButton',
    accountButton: 'accountButton',
  },
  [ElementTestIdentifierScope.Auth]: {
    loginInput: 'loginInput',
    promocodeInput: 'promocodeInput',
    passwordInput: 'passwordInput',
    continueButton: 'continueButton',
    submitAuthButton: 'submitAuthButton',
    forgotPasswordButton: 'forgotPasswordButton',
    clearLoginButton: 'clearLoginButton',
    applyPromocodeButton: 'applyPromocodeButton',
    clearPromocodeButton: 'clearPromocodeButton',
    backButton: 'backButton',
    vkAuthButton: 'vkAuthButton',
    yandexAuthButton: 'yandexAuthButton',
    phoneCodeElement: 'phoneCodeElement',
    resendCodeButton: 'resendCodeButton',
    enterButton: 'enterButton',
  },
  [ElementTestIdentifierScope.Footer]: {
    block: 'block',
    breadcrumbs: 'breadcrumbs',
    breadcrumbsItem: 'breadcrumbsItem',
    compilationsLink: 'compilationsLink',
    myChannelLink: 'myChannelLink',
    moviesLink: 'moviesLink',
    seriesLink: 'seriesLink',
    tvLink: 'tvLink',
    userAgreementLink: 'userAgreementLink',
    privacyPolicyLink: 'privacyPolicyLink',
    recommendationTechnologiesLink: 'recommendationTechnologiesLink',
    contactsLink: 'contactsLink',
    storeItem: 'storeItem',
    socialsItem: 'socialsItem',
  },
  [ElementTestIdentifierScope.Collections]: {
    contentCardItem: 'contentCardItem',
    removeContentItem: 'removeContentItem',
    compilationCard: 'compilationCard',
  },
  [ElementTestIdentifierScope.Catalog]: {
    moviesTab: 'moviesTab',
    serialsTab: 'serialsTab',
    filterSidebar: 'filterSidebar',
    filterBlockCountries: 'filterBlockCountries',
    filterBlockGenres: 'filterBlockGenres',
    filterBlockYears: 'filterBlockYears',
    countriesListFilter: 'countriesListFilter',
    suggestCountry: 'suggestCountry',
    genreFilterCheckbox: 'genreFilterCheckbox',
    yearFilterCheckbox: 'yearFilterCheckbox',
    contentCard: 'contentCard',
    selectedTagsWrapper: 'selectedTagsWrapper',
  },
  [ElementTestIdentifierScope.Content]: {
    backgroundImage: 'backgroundImage',
    mainMomentPlayer: 'mainMomentPlayer',
    playPauseMomentButton: 'playPauseMomentButton',
    genresLinksBlock: 'genresLinksBlock',
    countriesLinksBlock: 'countriesLinksBlock',
    watchButton: 'watchButton',
    addToCollectionButton: 'addToCollectionButton',
    kinomsList: 'kinomsList',
    compilationList: 'compilationList',
    similarContentList: 'similarContentList',
    playerContainer: 'playerContainer',
    genreLink: 'genreLink',
    countryLink: 'countryLink',
    infoSection: 'infoSection',
    yearLink: 'yearLink',
    tagline: 'tagline',
    ageLimit: 'ageLimit',
    navigationBar: 'navigationBar',
    navigateToMoments: 'navigateToMoments',
    navigateToDescription: 'navigateToDescription',
    navigateToRecommendations: 'navigateToRecommendations',
    navigateToCompilation: 'navigateToCompilation',
    seriesSection: 'seriesSection',
    seasonItem: 'seasonItem',
    episodeItem: 'episodeItem',
    momentsSection: 'momentsSection',
    momentsTitle: 'momentsTitle',
    advancedDescription: 'advancedDescription',
    kinomItem: 'kinomItem',
    spoilerButton: 'spoilerButton',
    descriptionItem: 'descriptionItem',
    descriptionItemLink: 'descriptionItemLink',
  },
  [ElementTestIdentifierScope.LK]: {
    deleteAccountButton: 'deleteAccountButton',
    deleteAccountSuccessModal: 'deleteAccountSuccessModal',
    deleteAccountConfirmModal: 'deleteAccountConfirmModal',
    deleteAccountHasActiveSubscriptionModal: 'deleteAccountHasActiveSubscriptionModal',
    accountNavItem: 'accountNavItem',
    devicesNavItem: 'devicesNavItem',
    subscriptionNavItem: 'subscriptionNavItem',
    bonusesNavItem: 'bonusesNavItem',
    profilesNavItem: 'profilesNavItem',
    languageNavItem: 'languageNavItem',
    logoutNavItem: 'logoutNavItem',
    linkTvInput: 'linkTvInput',
    linkTvButton: 'linkTvButton',
    linkedDevicesList: 'linkedDevicesList',
    manageSubscriptionButton: 'manageSubscriptionButton',
    enableAutoRenewalButton: 'enableAutoRenewalButton',
    continueSubscriptionButton: 'continueSubscriptionButton',
    subscribeButton: 'subscribeButton',
    bonusesTabsBlock: 'bonusesTabsBlock',
    promocodeInput: 'promocodeInput',
    referralInput: 'referralInput',
    applyPromocodeButton: 'applyPromocodeButton',
    applyPeriodButton: 'applyPeriodButton',
    switchModeButton: 'switchModeButton',
    confirmLogoutButton: 'confirmLogoutButton',
    cancelLogoutButton: 'cancelLogoutButton',
    contactItemEmail: 'contactItemEmail',
    contactItemPhone: 'contactItemPhone',
    editContactButton: 'editContactButton',
    saveContactButton: 'saveContactButton',
    deleteDeviceButton: 'deleteDeviceButton',
    subscriptionManageBackButton: 'subscriptionManageBackButton',
    subscriptionManageControlButton: 'subscriptionManageControlButton',
    bonusesTab: 'bonusesTab',
    copyReferalLinkButton: 'copyReferalLinkButton',
    profileItemButton: 'profileItemButton',
    profileSupportMailLink: 'profileSupportMailLink',
    logoutModalWrapper: 'logoutModalWrapper',
    logoutLeaveButton: 'logoutLeaveButton',
    logoutStayButton: 'logoutStayButton',
    removeDeviceModalWrapper: 'removeDeviceModalWrapper',
    confirmRemoveButton: 'confirmRemoveButton',
    rejectRemoveButton: 'rejectRemoveButton',
  },
  [ElementTestIdentifierScope.CancelSubscriptionModal]: {
    cancelReasonsModalWrapper: 'cancelReasonsModalWrapper',
    cancelReasonsItem: 'cancelReasonsItem',
    cancelReasonsTextarea: 'cancelReasonsTextarea',
    cancelReasonsLimitError: 'cancelReasonsLimitError',
    rejectDisableRenewButton: 'rejectDisableRenewButton',
    disableSubscriptionButton: 'disableSubscriptionButton',
    confirmDisableAutoRenewModalWrapper: 'confirmDisableAutoRenewModalWrapper',
    cancelDisableButton: 'cancelDisableButton',
    confirmDisableButton: 'confirmDisableButton',
    bonusModalWrapper: 'bonusModalWrapper',
    watchWithBonusButton: 'watchWithBonusButton',
    unsubscribeAnywayButton: 'unsubscribeAnywayButton',
  },
  [ElementTestIdentifierScope.ParentalModal]: {
    childrenAccessModal: 'childrenAccessModal',
    haveAccessButton: 'haveAccessButton',
    noAccessButton: 'noAccessButton',
    setPinCodeModal: 'setPinCodeModal',
    setPinCodeInput: 'setPinCodeInput',
    setPinCodeButton: 'setPinCodeButton',
    logoutButton: 'logoutButton',
    chooseProfileModal: 'chooseProfileModal',
    chooseProfileButton: 'chooseProfileButton',
    checkPinCodeModal: 'checkPinCodeModal',
    checkPinCodeInput: 'checkPinCodeInput',
    continueButton: 'continueButton',
    cancelButton: 'cancelButton',
    enterPinCodeModal: 'enterPinCodeModal',
    enterPinCodeInput: 'enterPinCodeInput',
    successPinCodeModal: 'successPinCodeModal',
    successButton: 'successButton',
  },
  [ElementTestIdentifierScope.MyCollection]: {
    tabsItem: 'tabsItem',
    momentsSection: 'momentsSection',
    contentsSection: 'contentsSection',
    momentItem: 'momentItem',
    contentItem: 'contentItem',
    removeItemButton: 'removeItemButton',
  },
  [ElementTestIdentifierScope.MyChannel]: {
    likeButton: 'likeButton',
    dislikeButton: 'dislikeButton',
    addToCollectionButton: 'addToCollectionButton',
    shareButton: 'shareButton',
    sidebarCategoriesDropdown: 'sidebarCategoriesDropdown',
    sidebarKinomCard: 'sidebarKinomCard',
    kinomPrimaryButton: 'kinomPrimaryButton',
    contentPoster: 'contentPoster',
    contentTitle: 'contentTitle',
    contentDescription: 'contentDescription',
  },
  [ElementTestIdentifierScope.Playlists]: {
    itemsListBlock: 'itemsListBlock',
    linkTitle: 'linkTitle',
    watchButton: 'watchButton',
    contentTitle: 'contentTitle',
    selectedContent: 'selectedContent',
    contentItem: 'contentItem',
  },
  [ElementTestIdentifierScope.Referral]: {
    modalWrapper: 'modalWrapper',
    inviteFriendButton: 'inviteFriendButton',
  },
  [ElementTestIdentifierScope.TV]: {
    promoBanner: 'promoBanner',
    categoryBlock: 'categoryBlock',
    channelCard: 'channelCard',
    channelPlayerContainer: 'channelPlayerContainer',
    promoSliderPrimaryButton: 'promoSliderPrimaryButton',
  },
  [ElementTestIdentifierScope.ThankYou]: {
    cardBlock: 'cardBlock',
    continueButton: 'continueButton',
    flocktoryButton: 'flocktoryButton',
    recommendationSection: 'recommendationSection',
    tvSection: 'tvSection',
    momentsSection: 'momentsSection',
  },
  [ElementTestIdentifierScope.Main]: {
    promoBlock: 'promoBlock',
    continueWatch: 'continueWatch',
    continueWatchItem: 'continueWatchItem',
    recommendations: 'recommendations',
    belt: 'belt',
    beltItem: 'beltItem',
    momentsList: 'momentsList',
    playlist: 'playlist',
    promoBanner: 'promoBanner',
    channelsList: 'channelsList',
    tvChannelCard: 'tvChannelCard',
    kinomSliderPrimaryButton: 'kinomSliderPrimaryButton',
  },
  [ElementTestIdentifierScope.Contacts]: {
    emailButton: 'emailButton',
    vkButton: 'vkButton',
    telegramButton: 'telegramButton',
    copyButton: 'copyButton',
  },
  [ElementTestIdentifierScope.Search]: {
    inputWrapper: 'inputWrapper',
    contentWrapper: 'contentWrapper',
    contentItem: 'contentItem',
    allResultsLink: 'allResultsLink',
    resultsSection: 'resultsSection',
  },
  [ElementTestIdentifierScope.QuizModal]: {
    modalWrapper: 'modalWrapper',
    genreItem: 'genreItem',
    contentItem: 'contentItem',
    nextButton: 'nextButton',
    backButton: 'backButton',
  },
  [ElementTestIdentifierScope.TvModal]: {
    modalWrapper: 'modalWrapper',
    startWatchingButton: 'startWatchingButton',
    getSubscriptionButton: 'getSubscriptionButton',
  },
  [ElementTestIdentifierScope.WatchAllEpisodeModal]: {
    modalWrapper: 'modalWrapper',
    startWatchingButton: 'startWatchingButton',
  },
  [ElementTestIdentifierScope.Notification]: {
    section: 'section',
    closeButton: 'closeButton',
  },
  [ElementTestIdentifierScope.Common]: {
    navNextSlide: 'navNextSlide',
    navPrevSlide: 'navPrevSlide',
    offerButton: 'offerButton',
    dropdownItem: 'dropdownItem',
    removeButton: 'removeButton',
    closeModal: 'closeModal',
  },
};

export function getTestElementIdentifier<T extends ElementTestIdentifierScope>(
  scope: T,
  key: keyof (typeof TestIdentifierElementMap)[T],
  additionalAlias?: string,
): string | undefined {
  const alias = TestIdentifierElementMap[scope][key] as string;

  if (!alias) {
    return undefined;
  }

  return `${scope}_${alias}${additionalAlias || ''}`;
}
