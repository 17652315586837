import { ApplicationError, Disposable } from '@package/sdk/src/core';
import { isClient } from '@vueuse/core';

class IndexedDBError extends ApplicationError {
  public override readonly name = 'IndexedDBError';

  constructor(message?: string) {
    super(message);
  }

  public toJSON(): Record<string, any> {
    return {};
  }

  public [Symbol.toPrimitive](hint: 'string' | 'number' | 'default'): unknown {
    return super[Symbol.toPrimitive](hint);
  }
}

export interface IndexedDBStorageOptions {
  timeoutForReading: number;
  tableName: string;
  databaseName: string;
}

export interface IndexedDBItem<T> {
  value: T;
  expires: number;
  time: number;
  key: string;
}

const INITIAL_DB_VERSION = 1;

export class IndexedDBStorage extends Disposable {
  private readonly timeout: number;
  private readonly tableName: string;
  private readonly databaseName: string;

  constructor(options: IndexedDBStorageOptions) {
    super();

    this.timeout = options.timeoutForReading;
    this.tableName = options.tableName;
    this.databaseName = options.databaseName;
  }

  // Ключевой метод записи в базу данных
  public async write(key: string, value: unknown, options?: { expires: number }): Promise<void> {
    const db = await this.internalOpen();
    try {
      await this.internalWrite(db, key, value, options?.expires);
    } catch (error) {
      console.error(error);
    } finally {
      await this.internalClose(db);
    }
  }

  // Ключевой метод чтения из базы данных
  public async read<T>(key: string): Promise<IndexedDBItem<T> | undefined> {
    const db = await this.internalOpen();
    try {
      return await this.internalRead<T>(db, key);
    } catch (error) {
      console.error(error);
    } finally {
      await this.internalClose(db);
    }
  }

  public async delete(key: string): Promise<void> {
    const db = await this.internalOpen();
    try {
      await this.internalDelete(db, key);
    } catch (error) {
      console.error(error);
    } finally {
      await this.internalClose(db);
    }
  }

  // Вроде понятно что делает и что возвращает
  private internalOpen(): Promise<IDBDatabase> {
    try {
      return new Promise<IDBDatabase>((resolve, reject) => {
        const request: IDBOpenDBRequest = indexedDB.open(this.databaseName, INITIAL_DB_VERSION);
        request.onerror = () => reject(new IndexedDBError(request.error?.message));
        request.onsuccess = () => resolve(request.result);
        request.onblocked = () => reject(new IndexedDBError(`Database ${this.databaseName} is blocked`));
        request.onupgradeneeded = (ev) => {
          const db = request.result;
          try {
            // Тут писать все модификации базы последовательно, указывая увеличенную версию при вызове indexedDB.open()
            if (ev.oldVersion < INITIAL_DB_VERSION) {
              db.createObjectStore(this.tableName, { keyPath: 'key' });
            }
          } catch (e) {
            if (e instanceof Error) {
              reject(new IndexedDBError(e.message));
            }

            reject(new IndexedDBError('unknown'));
          }
        };
      });
    } catch (e) {
      if (e instanceof Error) {
        return Promise.reject(new IndexedDBError(e.message));
      }

      return Promise.reject(new IndexedDBError('unknown'));
    }
  }

  // Вроде понятно что делает
  public internalClose(db: IDBDatabase): Promise<void> {
    try {
      db.close();
      return Promise.resolve();
    } catch (e) {
      if (e instanceof Error) {
        return Promise.reject(new IndexedDBError(e.message));
      }

      return Promise.reject(new IndexedDBError('unknown'));
    }
  }

  // Пишем в базу db для ключа key значение value, по дефолту храним неделю (если не указано другого)
  private internalWrite(db: IDBDatabase, key: IDBValidKey, value: unknown, expires = 604800000): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const trans = db.transaction(this.tableName, 'readwrite');
      const store = trans.objectStore(this.tableName);

      const currentTime = Date.now();

      const query = store.put({
        key,
        value,
        time: currentTime,
        expires: currentTime + expires,
      } as IndexedDBItem<unknown>);

      query.onsuccess = () => resolve();
      trans.onerror = () => reject(new IndexedDBError(trans.error?.message));
    });
  }

  // Читаем из базы db для ключа key его значение
  private internalRead<T>(db: IDBDatabase, key: IDBValidKey): Promise<IndexedDBItem<T>> {
    return new Promise<IndexedDBItem<T>>((resolve, reject) => {
      const trans = db.transaction(this.tableName, 'readonly');
      const store = trans.objectStore(this.tableName);
      const query = store.get(key);

      const timeoutHandle = window.setTimeout(() => {
        query.onsuccess = null;
        trans.onerror = null;

        reject(new IndexedDBError('ResourceStorage.internalRead: read timeout'));
      }, this.timeout);

      query.onsuccess = () => {
        if (timeoutHandle) {
          window.clearTimeout(timeoutHandle);
        }

        resolve(query.result);
      };

      trans.onerror = () => {
        if (timeoutHandle) {
          window.clearTimeout(timeoutHandle);
        }

        reject(new IndexedDBError(trans.error?.message));
      };
    });
  }

  // Удаляем из базы db значение для заданного ключа
  private internalDelete(db: IDBDatabase, key: IDBValidKey): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const trans = db.transaction(this.tableName, 'readwrite');
      const store = trans.objectStore(this.tableName);
      const query = store.delete(key);
      query.onsuccess = () => resolve();
      trans.onerror = () => reject(new IndexedDBError(trans.error?.message));
    });
  }

  public async clear() {
    const db = await this.internalOpen();

    return new Promise<void>((resolve, reject) => {
      const trans = db.transaction(this.tableName, 'readwrite');
      const store = trans.objectStore(this.tableName);
      const query = store.clear();
      query.onsuccess = () => resolve();
      trans.onerror = () => reject(new IndexedDBError(trans.error?.message));
    });
  }
}

export class HttpClientCacheController extends Disposable {
  private readonly indexedDB = new IndexedDBStorage({
    timeoutForReading: 2000,
    databaseName: 'cache',
    tableName: 'http-resources',
  });

  constructor() {
    super();
  }

  public addEntry(key: string, value: unknown, options?: { expires: number }) {
    if (!isClient) {
      return;
    }

    return this.indexedDB.write(key, value, options);
  }

  public removeEntry(key: string) {
    if (!isClient) {
      return undefined;
    }

    return this.indexedDB.delete(key);
  }

  public readEntry<T>(key: string): Promise<IndexedDBItem<T> | undefined> {
    if (!isClient) {
      return Promise.resolve(undefined);
    }

    return this.indexedDB.read<T>(key);
  }

  public clear() {
    this.indexedDB.clear();
  }
}
