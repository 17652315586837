export enum RouteQuery {
  CloudpaymentsEnabled = 'cp',
  TvPopupPromo = 'tv_promo',
  TvSmartCode = 'smart_code',
  VideoStartOffset = 't',
  SearchQuery = 'q',
  AuthStep = 'step',
  AuthType = 'type',
  RestorePasswordToken = 'token',
  AdmitadUid = 'admitad_uid',
  AccessToken = 'access_token',
  InvitationToken = 'invitation_token',
  BackRoute = 'b',
  MomentId = 'moment_id',
  OfferId = 'offer_id',
  Promocode = 'promocode',
  NavigationTab = 'lk_nav',
  SubscriptionNav = 'sub_nav',
  BonusProgram = 'bonus_program',
  Referral = 'referral',
  Autoplay = 'auto',
  PageFrom = 'from',
  PageNumber = 'page',
  Captcha = 'captcha',
  AuthBackHideButton = 'auth_hide_button',
  FromPromocodeButton = 'from_promo',
  AppLang = 'lang',
  AppTheme = 'theme',
  SelectionPosition = 'selection_position',
  Category = 'mc_playlist', // my_channel_category (покороче + чтобы не было нигде еще пересечений)
  IsMyChannelRedirectFromMainPage = 'mc_redirect_main',
  PlayerFullscreen = 'fullscreen',
  ClickId = 'click_id',
}
