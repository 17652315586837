import type { ContentMoment, Country, FilterItem, Genre, Moment, Year } from '@package/sdk/src/api';
import { MediaContentType } from '@package/sdk/src/api';
import { watchPausable } from '@vueuse/core';
import { defineStore } from 'pinia';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { useCountriesApi } from '../code/countries/use-countries-api';
import { useGenresApi } from '../code/genres/use-genres-api';
import { useYearsApi } from '../code/years/use-years-api';
import { AppRoute } from '../platform/router/routes';

const catalogRoutes = [AppRoute.SerialFilter, AppRoute.MovieFilter, AppRoute.Movies, AppRoute.Serials];

export const useCatalogStore = defineStore('catalog', () => {
  const route = useRoute();
  const router = useRouter();
  const countriesApi = useCountriesApi();
  const genresApi = useGenresApi();
  const yearsApi = useYearsApi();

  const countries = ref<Country[]>([]);
  const genres = ref<Genre[]>([]);
  const years = ref<Year[]>([]);

  const selectedCountrySlugs = ref<Country[]>([]);
  const selectedGenreSlugs = ref<Genre[]>([]);
  const selectedYearSlugs = ref<Year[]>([]);

  const allSelectedSlugs = computed(() => [
    ...selectedCountrySlugs.value,
    ...selectedGenreSlugs.value,
    ...selectedYearSlugs.value,
  ]);

  const isAnyFilterApplied = computed(() => Boolean(allSelectedSlugs.value.length));

  const selectedContentType = computed(() =>
    route.name === AppRoute.Movies || route.name === AppRoute.MovieFilter
      ? MediaContentType.MOVIE
      : MediaContentType.SERIAL,
  );

  const isMovieContentTypeSelected = computed(() => selectedContentType.value === MediaContentType.MOVIE);

  const queryParams = computed(() => router.currentRoute.value.params.f as string);

  const currentLinkedContentKinom = ref<ContentMoment | Moment>();
  const setCurrentLinkedContentKinom = (moment: ContentMoment | Moment | undefined) => {
    currentLinkedContentKinom.value = moment;
  };

  const fetchCountries = async () => {
    countries.value = await countriesApi.fetchCountries();
  };

  const fetchGenres = async () => {
    genres.value = await genresApi.fetchGenres();
  };

  const fetchYears = async () => {
    years.value = await yearsApi.fetchYears();
  };

  const parseSlugs = (str: string): string[] => {
    const result: string[] = [];
    const parts = str.split('-');

    parts.forEach((part) => {
      result.push(part);
    });

    return result;
  };

  const resetSlugs = () => {
    selectedCountrySlugs.value = [];
    selectedGenreSlugs.value = [];
    selectedYearSlugs.value = [];
  };

  const stringifySlugs = (slugs: FilterItem[]) => {
    const result = slugs
      .map((el) => {
        const year = el as Year;

        if (year.from) {
          return year.from;
        }

        return el.slug;
      })
      .join('-');

    return result;
  };

  const onChangeFilters = () => {
    if (!catalogRoutes.includes(route.name as AppRoute)) {
      return;
    }

    if (!stringifySlugs(allSelectedSlugs.value)) {
      return router.replace({ name: isMovieContentTypeSelected.value ? AppRoute.Movies : AppRoute.Serials });
    }

    navigateTo(
      {
        name: isMovieContentTypeSelected.value ? AppRoute.MovieFilter : AppRoute.SerialFilter,
        params: { f: stringifySlugs(allSelectedSlugs.value) },
        query: route.query,
      },
      {
        replace: true,
      },
    );
  };

  const selectedSlugsWatcher = watchPausable(allSelectedSlugs, onChangeFilters);

  return {
    currentLinkedContentKinom,
    selectedSlugsWatcher,
    setCurrentLinkedContentKinom,
    allSelectedSlugs,
    countries,
    fetchCountries,
    fetchGenres,
    fetchYears,
    genres,
    isAnyFilterApplied,
    isMovieContentTypeSelected,
    parseSlugs,
    queryParams,
    resetSlugs,
    selectedContentType,
    selectedCountrySlugs,
    selectedGenreSlugs,
    selectedYearSlugs,
    stringifySlugs,
    years,
  };
});
