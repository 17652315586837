<template>
  <app-button
    :class="$style.promocodeButton"
    :aria-label="$t('ariaLabel.header.promocodeButton')"
    :data-tid="$getTestElementIdentifier($ElementTestIdentifierScope.Header, 'promocodeButton')"
    :text="text"
    size="small"
    variation="button-secondary"
    @click="onPromocodeButtonClick()"
  >
    <icon-gift v-if="isGiftIconShown" size="small" />
  </app-button>
</template>

<script lang="ts" setup>
import AppButton from '@package/ui/src/components/AppButton.vue';

import { usePromocodeActivationButton } from '@/code/promocode/use-promocode-activation-button';

import IconGift from '../icons/IconGift.vue';

const { isShownPromocodeButton, onClick: onPromocodeButtonClick } = usePromocodeActivationButton();

defineProps<{
  isGiftIconShown: boolean;
  text: string;
}>();

defineExpose({
  isShownPromocodeButton,
});
</script>

<style lang="scss" module>
.promocodeButton {
  white-space: nowrap;
  flex-shrink: 0;
  border-radius: var(--g-border-round-8);
  background-color: var(--color-bg-secondary-80);

  &:hover:not([disabled]) {
    background-color: var(--color-states-bg-secondary-hover-70);
  }
}
</style>
