import { getFirstElement } from '@package/sdk/src/core';
import { storeToRefs } from 'pinia';
import { computed } from 'vue';

import { useLayoutStore } from '../../stores/use-layout-store';
import { removeHttps } from '../base/string';
import { AppLanguage, LanguageTitle } from '../localization/language';
import { useHostname } from '../network/use-hostname';
import { AppCurrency, AppVariation } from './interfaces';
import useVariationVariables from './use-variation-variables';

type LanguageItem = {
  title: LanguageTitle;
  value: AppLanguage;
};

export const availableLanguages = {
  [AppVariation.Ru]: [AppLanguage.RU],
  [AppVariation.Am]: [AppLanguage.EN, AppLanguage.RU, AppLanguage.AM],
};

const languageTitleMap = {
  [AppLanguage.RU]: LanguageTitle.Ru,
  [AppLanguage.EN]: LanguageTitle.En,
  [AppLanguage.AM]: LanguageTitle.Am,
};

const generateLanguageList = (appLanguages: AppLanguage[]): LanguageItem[] => {
  return appLanguages.map((lang) => ({
    value: lang,
    title: languageTitleMap[lang],
  }));
};

export default function useHostVariation() {
  const { AM_VARIATION_HOSTNAMES } = useVariationVariables();
  const hostName = removeHttps(useHostname());
  const { currentAppLanguage } = storeToRefs(useLayoutStore());

  const appVariation = computed(() => {
    if (AM_VARIATION_HOSTNAMES.includes(hostName)) {
      return AppVariation.Am;
    }

    return AppVariation.Ru;
  });

  const isRuVariation = computed(() => appVariation.value === AppVariation.Ru);

  const marketLanguages = availableLanguages[appVariation.value];

  const languageList = computed<LanguageItem[]>(() => {
    if (marketLanguages.length > 0) {
      return generateLanguageList(marketLanguages);
    }

    return [];
  });

  const baseTemplateTitle = computed(() => {
    if (appVariation.value === AppVariation.Am) {
      return `Movies and series online in HD quality - look at ${hostName}`;
    }

    return `Фильмы и сериалы онлайн в HD качестве - смотрите на ${hostName}`;
  });

  const appLanguage = computed(() => {
    if (currentAppLanguage.value) {
      return currentAppLanguage.value;
    }

    return getFirstElement(marketLanguages) as AppLanguage;
  });

  const appCurrency = computed(() => {
    if (appVariation.value === AppVariation.Am) {
      return AppCurrency.Dram;
    }

    return AppCurrency.Rub;
  });

  const isPromocodeEnabled = computed(() => true);

  const isRecommendationsTechShown = computed(() => appVariation.value === AppVariation.Ru);

  return {
    hostName,
    isRecommendationsTechShown,
    languageList,
    baseTemplateTitle,
    isPromocodeEnabled,
    appCurrency,
    appVariation,
    appLanguage,
    isRuVariation,
  };
}
