import { default as index7gCogU6l2iMeta } from "/srv/app/apps/web/src/pages/account/bonuses/index.vue?macro=true";
import { default as promocodegfUv1qSO4uMeta } from "/srv/app/apps/web/src/pages/account/bonuses/promocode.vue?macro=true";
import { default as referralnXjXktM2KhMeta } from "/srv/app/apps/web/src/pages/account/bonuses/referral.vue?macro=true";
import { default as deviceso7CvxA5tRsMeta } from "/srv/app/apps/web/src/pages/account/devices.vue?macro=true";
import { default as indexVSiD5SQG6tMeta } from "/srv/app/apps/web/src/pages/account/index.vue?macro=true";
import { default as infoRRyvG10DGFMeta } from "/srv/app/apps/web/src/pages/account/info.vue?macro=true";
import { default as languagezp5HpXFEWGMeta } from "/srv/app/apps/web/src/pages/account/language.vue?macro=true";
import { default as profilesc9BpKwS063Meta } from "/srv/app/apps/web/src/pages/account/profiles.vue?macro=true";
import { default as subscription_45infopydwlvVLkIMeta } from "/srv/app/apps/web/src/pages/account/subscription-info.vue?macro=true";
import { default as subscription_45manageRNzmFZHhmPMeta } from "/srv/app/apps/web/src/pages/account/subscription-manage.vue?macro=true";
import { default as enter_45passwordNTm5ROJsCkMeta } from "/srv/app/apps/web/src/pages/auth/enter-password.vue?macro=true";
import { default as enterDVgF0G1yNOMeta } from "/srv/app/apps/web/src/pages/auth/enter.vue?macro=true";
import { default as indexceiqIMRYDAMeta } from "/srv/app/apps/web/src/pages/auth/index.vue?macro=true";
import { default as password_45link_45expiredTa5HBJdAgeMeta } from "/srv/app/apps/web/src/pages/auth/password-link-expired.vue?macro=true";
import { default as password_45restore_45emailQ40qjnbt8lMeta } from "/srv/app/apps/web/src/pages/auth/password-restore-email.vue?macro=true";
import { default as password_45restore_45phonePMJlvGAl5XMeta } from "/srv/app/apps/web/src/pages/auth/password-restore-phone.vue?macro=true";
import { default as restore_45passwordxgAKKE90GaMeta } from "/srv/app/apps/web/src/pages/auth/restore-password.vue?macro=true";
import { default as indexdV3REfCl6BMeta } from "/srv/app/apps/web/src/pages/code/index.vue?macro=true";
import { default as _91id_93dg6SDa1wqXMeta } from "/srv/app/apps/web/src/pages/collections/[id].vue?macro=true";
import { default as indexvKb51fk8HGMeta } from "/srv/app/apps/web/src/pages/collections/index.vue?macro=true";
import { default as contacts28hwrRsJWVMeta } from "/srv/app/apps/web/src/pages/contacts.vue?macro=true";
import { default as certificatesFu1sEu8B6nMeta } from "/srv/app/apps/web/src/pages/docs/certificates.vue?macro=true";
import { default as mailing_45rulesybe9iwY0RMMeta } from "/srv/app/apps/web/src/pages/docs/mailing-rules.vue?macro=true";
import { default as privacy_45policyyEcKnTbRh0Meta } from "/srv/app/apps/web/src/pages/docs/privacy-policy.vue?macro=true";
import { default as recommendation_45technologiesG4rafIYeY5Meta } from "/srv/app/apps/web/src/pages/docs/recommendation-technologies.vue?macro=true";
import { default as user_45agreement6pJZQUwT8yMeta } from "/srv/app/apps/web/src/pages/docs/user-agreement.vue?macro=true";
import { default as index3J2ibNc8fkMeta } from "/srv/app/apps/web/src/pages/download/index.vue?macro=true";
import { default as mob_45appmn80IozXbxMeta } from "/srv/app/apps/web/src/pages/download/mob-app.vue?macro=true";
import { default as _91f_93_45hdT9LIrLjsgqMeta } from "/srv/app/apps/web/src/pages/filmy/[f]-hd.vue?macro=true";
import { default as _91id_93c72KOUcHaDMeta } from "/srv/app/apps/web/src/pages/filmy/[id].vue?macro=true";
import { default as indexXWozuKwahQMeta } from "/srv/app/apps/web/src/pages/filmy/index.vue?macro=true";
import { default as indexZmcyRTqV8cMeta } from "/srv/app/apps/web/src/pages/gotv/index.vue?macro=true";
import { default as indexXOZw9ZogxIMeta } from "/srv/app/apps/web/src/pages/index.vue?macro=true";
import { default as accountRbeoBFVMRVMeta } from "/srv/app/apps/web/src/pages/lk/account.vue?macro=true";
import { default as indexersfmUwsfuMeta } from "/srv/app/apps/web/src/pages/lk/bonuses/index.vue?macro=true";
import { default as promocodegA8PpYlxxVMeta } from "/srv/app/apps/web/src/pages/lk/bonuses/promocode.vue?macro=true";
import { default as referralz0rOizzNw1Meta } from "/srv/app/apps/web/src/pages/lk/bonuses/referral.vue?macro=true";
import { default as devices5FWvFiKvWgMeta } from "/srv/app/apps/web/src/pages/lk/devices.vue?macro=true";
import { default as indexSO3Kscxta8Meta } from "/srv/app/apps/web/src/pages/lk/index.vue?macro=true";
import { default as profilesRj2TNP1UAcMeta } from "/srv/app/apps/web/src/pages/lk/profiles.vue?macro=true";
import { default as subscription_45infouItSIRtiSiMeta } from "/srv/app/apps/web/src/pages/lk/subscription-info.vue?macro=true";
import { default as subscription_45manageIkhNUM8pRaMeta } from "/srv/app/apps/web/src/pages/lk/subscription-manage.vue?macro=true";
import { default as _91f_93_45hd33uRL5xnyLMeta } from "/srv/app/apps/web/src/pages/movies/[f]-hd.vue?macro=true";
import { default as _91id_93OHZlkzM7pwMeta } from "/srv/app/apps/web/src/pages/movies/[id].vue?macro=true";
import { default as index2qcwD9SoUcMeta } from "/srv/app/apps/web/src/pages/movies/index.vue?macro=true";
import { default as _91id_93YSzF8VWyqdMeta } from "/srv/app/apps/web/src/pages/my-channel/[id].vue?macro=true";
import { default as indexCqIo9oCSImMeta } from "/srv/app/apps/web/src/pages/my-channel/index.vue?macro=true";
import { default as contentslmL1TDbRgaMeta } from "/srv/app/apps/web/src/pages/my-collection/contents.vue?macro=true";
import { default as index09reTnVFBbMeta } from "/srv/app/apps/web/src/pages/my-collection/index.vue?macro=true";
import { default as momentsLb4ZoRrn6xMeta } from "/srv/app/apps/web/src/pages/my-collection/moments.vue?macro=true";
import { default as indexQkTRmCMHOXMeta } from "/srv/app/apps/web/src/pages/offers/index.vue?macro=true";
import { default as _91payload_933XKIoQy5fHMeta } from "/srv/app/apps/web/src/pages/offers/pay/[payload].vue?macro=true";
import { default as _91promocode_93DbBHnVPnLqMeta } from "/srv/app/apps/web/src/pages/offers/promo/[promocode].vue?macro=true";
import { default as _91id_93Uy3pUQ5GQdMeta } from "/srv/app/apps/web/src/pages/playlists/[id].vue?macro=true";
import { default as indexZbTh1DoAVcMeta } from "/srv/app/apps/web/src/pages/playlists/index.vue?macro=true";
import { default as _91id_93kZrluRP0hfMeta } from "/srv/app/apps/web/src/pages/playlisty/[id].vue?macro=true";
import { default as indexkvJltEUdSkMeta } from "/srv/app/apps/web/src/pages/playlisty/index.vue?macro=true";
import { default as indexnERVKg0yslMeta } from "/srv/app/apps/web/src/pages/referral/index.vue?macro=true";
import { default as iconsbi1yc6g8HeMeta } from "/srv/app/apps/web/src/pages/sandbox/icons.vue?macro=true";
import { default as storybookZDQblHyyzqMeta } from "/srv/app/apps/web/src/pages/sandbox/storybook.vue?macro=true";
import { default as use_45checkbox_45mock584ryU69jiMeta } from "/srv/app/apps/web/src/pages/sandbox/use-checkbox-mock.ts?macro=true";
import { default as use_45dropdown_45mock2cPq6Hrl6oMeta } from "/srv/app/apps/web/src/pages/sandbox/use-dropdown-mock.ts?macro=true";
import { default as search7dHurUYdwwMeta } from "/srv/app/apps/web/src/pages/search.vue?macro=true";
import { default as _91f_93_45hdEflklYDSIQMeta } from "/srv/app/apps/web/src/pages/serialy/[f]-hd.vue?macro=true";
import { default as _91episodeId_93_45seriyaU3XO4ITZuFMeta } from "/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon/[episodeId]-seriya.vue?macro=true";
import { default as _91seasonId_93_45sezonndlF3x6FrvMeta } from "/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon.vue?macro=true";
import { default as _91id_93V0dgUcbNxqMeta } from "/srv/app/apps/web/src/pages/serialy/[id].vue?macro=true";
import { default as indexRmkPCMXQNYMeta } from "/srv/app/apps/web/src/pages/serialy/index.vue?macro=true";
import { default as _91f_93_45hdqD5ArFG3p5Meta } from "/srv/app/apps/web/src/pages/series/[f]-hd.vue?macro=true";
import { default as ep_45_91episodeId_93xoTMoMTwiNMeta } from "/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/ep-[episodeId].vue?macro=true";
import { default as season_45_91seasonId_932qWLK0V8rxMeta } from "/srv/app/apps/web/src/pages/series/[id]/season-[seasonId].vue?macro=true";
import { default as _91id_93AYAbYRdhdEMeta } from "/srv/app/apps/web/src/pages/series/[id].vue?macro=true";
import { default as indexYlDXFjffKrMeta } from "/srv/app/apps/web/src/pages/series/index.vue?macro=true";
import { default as sitemapZoxZcSxrPkMeta } from "/srv/app/apps/web/src/pages/sitemap.vue?macro=true";
import { default as _91id_93fYESM7Js8TMeta } from "/srv/app/apps/web/src/pages/telekanaly/[id].vue?macro=true";
import { default as indexspne8hEeJKMeta } from "/srv/app/apps/web/src/pages/telekanaly/index.vue?macro=true";
import { default as indexk5OkSYLPbkMeta } from "/srv/app/apps/web/src/pages/thank-you/index.vue?macro=true";
import { default as _91id_93QFcSwoB6hyMeta } from "/srv/app/apps/web/src/pages/tv-channels/[id].vue?macro=true";
import { default as indexfqBhcGBlk9Meta } from "/srv/app/apps/web/src/pages/tv-channels/index.vue?macro=true";
import { default as component_45stubO2wKbmSt1iMeta } from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1._ljwzreykdbhgeouasws5nlv2qe/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubO2wKbmSt1i } from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1._ljwzreykdbhgeouasws5nlv2qe/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "account-bonuses",
    path: "/account/bonuses",
    meta: index7gCogU6l2iMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "account-bonuses-promocode",
    path: "/account/bonuses/promocode",
    meta: promocodegfUv1qSO4uMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/bonuses/promocode.vue").then(m => m.default || m)
  },
  {
    name: "account-bonuses-referral",
    path: "/account/bonuses/referral",
    meta: referralnXjXktM2KhMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/bonuses/referral.vue").then(m => m.default || m)
  },
  {
    name: "account-devices",
    path: "/account/devices",
    meta: deviceso7CvxA5tRsMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/devices.vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/srv/app/apps/web/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-info",
    path: "/account/info",
    meta: infoRRyvG10DGFMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/info.vue").then(m => m.default || m)
  },
  {
    name: "account-language",
    path: "/account/language",
    meta: languagezp5HpXFEWGMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/language.vue").then(m => m.default || m)
  },
  {
    name: "account-profiles",
    path: "/account/profiles",
    meta: profilesc9BpKwS063Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/profiles.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-info",
    path: "/account/subscription-info",
    meta: subscription_45infopydwlvVLkIMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/subscription-info.vue").then(m => m.default || m)
  },
  {
    name: "account-subscription-manage",
    path: "/account/subscription-manage",
    meta: subscription_45manageRNzmFZHhmPMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/account/subscription-manage.vue").then(m => m.default || m)
  },
  {
    name: "auth-enter-password",
    path: "/auth/enter-password",
    meta: enter_45passwordNTm5ROJsCkMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/enter-password.vue").then(m => m.default || m)
  },
  {
    name: "auth-enter",
    path: "/auth/enter",
    meta: enterDVgF0G1yNOMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/enter.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexceiqIMRYDAMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-link-expired",
    path: "/auth/password-link-expired",
    meta: password_45link_45expiredTa5HBJdAgeMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/password-link-expired.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-restore-email",
    path: "/auth/password-restore-email",
    meta: password_45restore_45emailQ40qjnbt8lMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/password-restore-email.vue").then(m => m.default || m)
  },
  {
    name: "auth-password-restore-phone",
    path: "/auth/password-restore-phone",
    meta: password_45restore_45phonePMJlvGAl5XMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/password-restore-phone.vue").then(m => m.default || m)
  },
  {
    name: "auth-restore-password",
    path: "/auth/restore-password",
    meta: restore_45passwordxgAKKE90GaMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/auth/restore-password.vue").then(m => m.default || m)
  },
  {
    name: "code",
    path: "/code",
    component: () => import("/srv/app/apps/web/src/pages/code/index.vue").then(m => m.default || m)
  },
  {
    name: "collections-id",
    path: "/collections/:id()",
    meta: _91id_93dg6SDa1wqXMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: "collections",
    path: "/collections",
    meta: indexvKb51fk8HGMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "contacts",
    path: "/contacts",
    meta: contacts28hwrRsJWVMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "docs-certificates",
    path: "/docs/certificates",
    component: () => import("/srv/app/apps/web/src/pages/docs/certificates.vue").then(m => m.default || m)
  },
  {
    name: "docs-mailing-rules",
    path: "/docs/mailing-rules",
    component: () => import("/srv/app/apps/web/src/pages/docs/mailing-rules.vue").then(m => m.default || m)
  },
  {
    name: "docs-privacy-policy",
    path: "/docs/privacy-policy",
    component: () => import("/srv/app/apps/web/src/pages/docs/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: "docs-recommendation-technologies",
    path: "/docs/recommendation-technologies",
    component: () => import("/srv/app/apps/web/src/pages/docs/recommendation-technologies.vue").then(m => m.default || m)
  },
  {
    name: "docs-user-agreement",
    path: "/docs/user-agreement",
    component: () => import("/srv/app/apps/web/src/pages/docs/user-agreement.vue").then(m => m.default || m)
  },
  {
    name: "download",
    path: "/download",
    meta: index3J2ibNc8fkMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/download/index.vue").then(m => m.default || m)
  },
  {
    name: "download-mob-app",
    path: "/download/mob-app",
    component: () => import("/srv/app/apps/web/src/pages/download/mob-app.vue").then(m => m.default || m)
  },
  {
    name: "filmy-f-hd",
    path: "/filmy/:f()-hd",
    component: () => import("/srv/app/apps/web/src/pages/filmy/[f]-hd.vue").then(m => m.default || m)
  },
  {
    name: "filmy-id",
    path: "/filmy/:id()",
    component: () => import("/srv/app/apps/web/src/pages/filmy/[id].vue").then(m => m.default || m)
  },
  {
    name: "filmy",
    path: "/filmy",
    component: () => import("/srv/app/apps/web/src/pages/filmy/index.vue").then(m => m.default || m)
  },
  {
    name: "gotv",
    path: "/gotv",
    component: () => import("/srv/app/apps/web/src/pages/gotv/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexXOZw9ZogxIMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "lk-account",
    path: "/lk/account",
    component: () => import("/srv/app/apps/web/src/pages/lk/account.vue").then(m => m.default || m)
  },
  {
    name: "lk-bonuses",
    path: "/lk/bonuses",
    component: () => import("/srv/app/apps/web/src/pages/lk/bonuses/index.vue").then(m => m.default || m)
  },
  {
    name: "lk-bonuses-promocode",
    path: "/lk/bonuses/promocode",
    component: () => import("/srv/app/apps/web/src/pages/lk/bonuses/promocode.vue").then(m => m.default || m)
  },
  {
    name: "lk-bonuses-referral",
    path: "/lk/bonuses/referral",
    component: () => import("/srv/app/apps/web/src/pages/lk/bonuses/referral.vue").then(m => m.default || m)
  },
  {
    name: "lk-devices",
    path: "/lk/devices",
    component: () => import("/srv/app/apps/web/src/pages/lk/devices.vue").then(m => m.default || m)
  },
  {
    name: "lk",
    path: "/lk",
    component: () => import("/srv/app/apps/web/src/pages/lk/index.vue").then(m => m.default || m)
  },
  {
    name: "lk-profiles",
    path: "/lk/profiles",
    component: () => import("/srv/app/apps/web/src/pages/lk/profiles.vue").then(m => m.default || m)
  },
  {
    name: "lk-subscription-info",
    path: "/lk/subscription-info",
    component: () => import("/srv/app/apps/web/src/pages/lk/subscription-info.vue").then(m => m.default || m)
  },
  {
    name: "lk-subscription-manage",
    path: "/lk/subscription-manage",
    component: () => import("/srv/app/apps/web/src/pages/lk/subscription-manage.vue").then(m => m.default || m)
  },
  {
    name: "movies-f-hd",
    path: "/movies/:f()-hd",
    meta: _91f_93_45hd33uRL5xnyLMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/[f]-hd.vue").then(m => m.default || m)
  },
  {
    name: "movies-id",
    path: "/movies/:id()",
    meta: _91id_93OHZlkzM7pwMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/[id].vue").then(m => m.default || m)
  },
  {
    name: "movies",
    path: "/movies",
    meta: index2qcwD9SoUcMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/movies/index.vue").then(m => m.default || m)
  },
  {
    name: "my-channel-id",
    path: "/my-channel/:id()",
    meta: _91id_93YSzF8VWyqdMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-channel/[id].vue").then(m => m.default || m)
  },
  {
    name: "my-channel",
    path: "/my-channel",
    meta: indexCqIo9oCSImMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-channel/index.vue").then(m => m.default || m)
  },
  {
    name: "my-collection-contents",
    path: "/my-collection/contents",
    meta: contentslmL1TDbRgaMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-collection/contents.vue").then(m => m.default || m)
  },
  {
    name: "my-collection",
    path: "/my-collection",
    component: () => import("/srv/app/apps/web/src/pages/my-collection/index.vue").then(m => m.default || m)
  },
  {
    name: "my-collection-moments",
    path: "/my-collection/moments",
    meta: momentsLb4ZoRrn6xMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/my-collection/moments.vue").then(m => m.default || m)
  },
  {
    name: "offers",
    path: "/offers",
    meta: indexQkTRmCMHOXMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: "offers-pay-payload",
    path: "/offers/pay/:payload()",
    meta: _91payload_933XKIoQy5fHMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/offers/pay/[payload].vue").then(m => m.default || m)
  },
  {
    name: "offers-promo-promocode",
    path: "/offers/promo/:promocode()",
    component: () => import("/srv/app/apps/web/src/pages/offers/promo/[promocode].vue").then(m => m.default || m)
  },
  {
    name: "playlists-id",
    path: "/playlists/:id()",
    meta: _91id_93Uy3pUQ5GQdMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/playlists/[id].vue").then(m => m.default || m)
  },
  {
    name: "playlists",
    path: "/playlists",
    meta: indexZbTh1DoAVcMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/playlists/index.vue").then(m => m.default || m)
  },
  {
    name: "playlisty-id",
    path: "/playlisty/:id()",
    component: () => import("/srv/app/apps/web/src/pages/playlisty/[id].vue").then(m => m.default || m)
  },
  {
    name: "playlisty",
    path: "/playlisty",
    component: () => import("/srv/app/apps/web/src/pages/playlisty/index.vue").then(m => m.default || m)
  },
  {
    name: "referral",
    path: "/referral",
    meta: indexnERVKg0yslMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/referral/index.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-icons",
    path: "/sandbox/icons",
    meta: iconsbi1yc6g8HeMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/sandbox/icons.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-storybook",
    path: "/sandbox/storybook",
    meta: storybookZDQblHyyzqMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/sandbox/storybook.vue").then(m => m.default || m)
  },
  {
    name: "sandbox-use-checkbox-mock",
    path: "/sandbox/use-checkbox-mock",
    component: () => import("/srv/app/apps/web/src/pages/sandbox/use-checkbox-mock.ts").then(m => m.default || m)
  },
  {
    name: "sandbox-use-dropdown-mock",
    path: "/sandbox/use-dropdown-mock",
    component: () => import("/srv/app/apps/web/src/pages/sandbox/use-dropdown-mock.ts").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    meta: search7dHurUYdwwMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/search.vue").then(m => m.default || m)
  },
  {
    name: "serialy-f-hd",
    path: "/serialy/:f()-hd",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[f]-hd.vue").then(m => m.default || m)
  },
  {
    name: "serialy-id",
    path: "/serialy/:id()",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "serialy-id-seasonId-sezon",
    path: ":seasonId()-sezon",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon.vue").then(m => m.default || m),
    children: [
  {
    name: "serialy-id-seasonId-sezon-episodeId-seriya",
    path: ":episodeId()-seriya",
    component: () => import("/srv/app/apps/web/src/pages/serialy/[id]/[seasonId]-sezon/[episodeId]-seriya.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "serialy",
    path: "/serialy",
    component: () => import("/srv/app/apps/web/src/pages/serialy/index.vue").then(m => m.default || m)
  },
  {
    name: "series-f-hd",
    path: "/series/:f()-hd",
    meta: _91f_93_45hdqD5ArFG3p5Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[f]-hd.vue").then(m => m.default || m)
  },
  {
    name: "series-id",
    path: "/series/:id()",
    meta: _91id_93AYAbYRdhdEMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "series-id-season-seasonId",
    path: "season-:seasonId()",
    meta: season_45_91seasonId_932qWLK0V8rxMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id]/season-[seasonId].vue").then(m => m.default || m),
    children: [
  {
    name: "series-id-season-seasonId-ep-episodeId",
    path: "ep-:episodeId()",
    meta: ep_45_91episodeId_93xoTMoMTwiNMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/[id]/season-[seasonId]/ep-[episodeId].vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: "series",
    path: "/series",
    meta: indexYlDXFjffKrMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/series/index.vue").then(m => m.default || m)
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: sitemapZoxZcSxrPkMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/sitemap.vue").then(m => m.default || m)
  },
  {
    name: "telekanaly-id",
    path: "/telekanaly/:id()",
    component: () => import("/srv/app/apps/web/src/pages/telekanaly/[id].vue").then(m => m.default || m)
  },
  {
    name: "telekanaly",
    path: "/telekanaly",
    component: () => import("/srv/app/apps/web/src/pages/telekanaly/index.vue").then(m => m.default || m)
  },
  {
    name: "thank-you",
    path: "/thank-you",
    meta: indexk5OkSYLPbkMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/thank-you/index.vue").then(m => m.default || m)
  },
  {
    name: "tv-channels-id",
    path: "/tv-channels/:id()",
    meta: _91id_93QFcSwoB6hyMeta || {},
    component: () => import("/srv/app/apps/web/src/pages/tv-channels/[id].vue").then(m => m.default || m)
  },
  {
    name: "tv-channels",
    path: "/tv-channels",
    meta: indexfqBhcGBlk9Meta || {},
    component: () => import("/srv/app/apps/web/src/pages/tv-channels/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/poisk/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/moi-kollekcii/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/recommendation-technologies/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/user-agreement/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/privacy-policy/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/moments-feed/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  },
  {
    name: component_45stubO2wKbmSt1iMeta?.name,
    path: "/kontakty/:pathMatch(.*)",
    component: component_45stubO2wKbmSt1i
  }
]