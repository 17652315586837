import type { NuxtApp } from 'nuxt/app';
import type { FetchContext } from 'ofetch';

export abstract class HttpInterceptor {
  constructor(protected readonly nuxtApp: NuxtApp) {}

  onRequest?(context: FetchContext): Promise<void> | void;

  onRequestError?(context: FetchContext): Promise<void> | void;

  onResponse?(context: FetchContext): Promise<void> | void;

  onResponseError?(context: FetchContext): Promise<void> | void;
}
