import { AppLanguage } from '../../platform/localization/language';
import { AppRoute } from '../../platform/router/routes';

export const MAX_VISIBLE_OPTIONS = 3;
export const EXCLUDED_ROUTES = [AppRoute.Offers];

export const RU_SEARCH_PLACEHOLDERS = [
  'про космос',
  'ромком',
  'Уилл Смит',
  'русская комедия',
  'погоня на машине',
  'собака ждет хозяина',
  'путешествие во времени',
  'авиакатастрофа',
  'побег из тюрьмы',
  '1 час за 7 лет',
];

export const EN_SEARCH_PLACEHOLDERS = [
  'about space',
  'romantic comedy',
  'Will Smith',
  'comedy',
  'car chase',
  'time travel',
  'escape from prison',
];

export function getSearchPlaceholdersArray(lang: AppLanguage) {
  switch (lang) {
    case AppLanguage.EN:
      return EN_SEARCH_PLACEHOLDERS;
    case AppLanguage.RU:
    default:
      return RU_SEARCH_PLACEHOLDERS;
  }
}
