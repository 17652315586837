<template>
  <div :class="$style.checkboxWrapper">
    <input :id="inputId" v-model="model" type="checkbox" :value="value" :class="$style.checkbox" />
    <label :for="inputId" :class="$style.label">
      {{ label }}
    </label>
  </div>
</template>

<script>
import { nanoid } from 'nanoid';

export default {
  props: {
    id: { type: String },
    modelValue: { type: [Array, Boolean] },
    value: { type: [Boolean, Object] },
    label: { type: String, default: '' },
  },
  emits: ['update:modelValue'],
  computed: {
    inputId() {
      return this.$props.id || nanoid(5);
    },
    model: {
      get() {
        return this.$props.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
};
</script>

<style lang="scss" module>
.checkboxWrapper {
  position: relative;
  display: block;
  padding-left: 1.25rem;
  margin-bottom: var(--g-spacing-12);
  transition: color 0.3s ease-in-out;

  &:hover {
    color: var(--color-text-secondary);
  }
}

.checkbox {
  position: absolute;
  z-index: var(--z-index-form-check-input);
  opacity: 0;

  &:checked + .label:after {
    transition: 0.15s;
    transform: translate(-50%, -50%) scale(1);
  }
}

.label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  margin-bottom: 0;
  padding-left: var(--g-spacing-10);
  cursor: pointer;

  &:before {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: var(--color-bg-field);
    content: '';
    flex-shrink: 0;
    flex-grow: 0;
    margin-right: 0.5em;
  }

  &:after {
    position: absolute;
    top: 50%;
    left: var(--g-spacing-10);
    transition: 0.1s;
    display: block;
    width: 8px;
    height: 8px;
    border-radius: 2px;
    background-color: var(--color-bg-accent);
    transform: translate(-50%, -50%) scale(0);
    content: '';
  }
}
</style>
