import routerOptions0 from "/srv/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@18.11.17_encoding@0.1._ljwzreykdbhgeouasws5nlv2qe/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/srv/app/apps/web/src/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}