import {
  ContentAccessKind,
  Media,
  Offer,
  Subscription,
  SubscriptionState,
  TVChannelAccessKind,
} from '@package/sdk/src/api';
import { isDefined } from '@package/sdk/src/core';

interface EpisodeData {
  season?: number;
  episode?: number;
}

interface SubscribePromoTexts {
  subscribeButtonText?: string;
  subscribeButtonSubtext?: string;
}

enum OfferTextType {
  ContinueWatchPrice = 'continueWatchByPrice',
  Partner = 'partner',
  Try = 'try',
  WatchPrice = 'watchByPrice',
  WatchTrial = 'watchByTrial',
}

interface GetContentPlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: ContentAccessKind;
  offer: Offer;
  subscribePromoTexts?: SubscribePromoTexts;
  hasTrialOffer?: boolean;
  subscription: Subscription;
  canContinueWatch: boolean;
  episodeData?: EpisodeData;
}

interface GetLivePlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: TVChannelAccessKind;
  offer: Offer;
  subscribePromoTexts?: SubscribePromoTexts;
  hasTrialOffer?: boolean;
  subscription: Subscription;
}

interface GetTVSliderButtonTextOptions {
  isAuth: boolean;
  accessKind?: TVChannelAccessKind;
}

interface GetMyChannelPlayButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  offer: Offer;
  subscribePromoTexts?: SubscribePromoTexts;
  hasTrialOffer?: boolean;
  subscription: Subscription;
  isForceSimpleWatchButtonText: boolean;
}

interface GetFullscreenMomentButtonTextOptions {
  isActiveSubscription: boolean;
  isAuth: boolean;
  isPartnerSubscription?: boolean;
  accessKind?: ContentAccessKind;
  offer: Offer;
  subscribePromoTexts?: SubscribePromoTexts;
  hasTrialOffer?: boolean;
  subscription: Subscription;
  isPlaylistMode?: boolean;
  content?: Media;
}

export default function useContentMediaButtonTexts() {
  const DEFAULT_TRIAL_PRICE = 1;
  const DEFAULT_TRIAL_DURATION = 14;

  const passTranslateArgs = (key: string, params?: { [s: string]: string | number } | undefined) => {
    return { key, params };
  };

  const passTPluralArgs = (key: string, count: number, params?: Record<string, string | number>) => {
    return { key, count, params };
  };

  const getOfferText = (
    offer: Offer,
    offerTextType: OfferTextType,
    subscribePromoTexts: SubscribePromoTexts = {},
  ): { params: { [p: string]: string | number } | undefined; key: string } => {
    const price = offer?.price as number;
    const duration = offer?.trialDuration || DEFAULT_TRIAL_DURATION;

    const { subscribeButtonText } = subscribePromoTexts;

    if (subscribeButtonText) {
      return passTranslateArgs(subscribeButtonText);
    }

    switch (offerTextType) {
      case OfferTextType.Try:
        return passTPluralArgs('offer.common.tryForDaysAndPrice', duration, {
          count: duration,
          price: DEFAULT_TRIAL_PRICE,
        });
      case OfferTextType.WatchTrial:
        return passTPluralArgs('offer.common.watchDaysByTrial', duration, {
          count: duration,
          price: DEFAULT_TRIAL_PRICE,
        });
      case OfferTextType.WatchPrice:
        return passTranslateArgs('offer.common.watchByPrice', { price });
      case OfferTextType.ContinueWatchPrice:
        return passTranslateArgs('offer.common.continueWatchByPrice', { price });
      case OfferTextType.Partner:
        return passTranslateArgs('offer.partner.continueSubscription');
      default:
        return passTranslateArgs('button.watch');
    }
  };

  const getOfferTextType = (hasTrialOffer?: boolean, subscription?: Subscription) => {
    if (hasTrialOffer && subscription?.state !== SubscriptionState.Canceled) {
      return OfferTextType.WatchTrial;
    }

    return OfferTextType.WatchPrice;
  };

  const getWatchContentText = (content?: Media) => {
    if (content?.contentType === 'movie') {
      return passTranslateArgs('button.watchMovie');
    }

    return passTranslateArgs('button.watchSerial');
  };

  const getContentPlayButtonText = (options: GetContentPlayButtonTextOptions) => {
    const {
      isActiveSubscription,
      isAuth,
      isPartnerSubscription,
      accessKind,
      offer,
      subscribePromoTexts,
      hasTrialOffer,
      subscription,
      canContinueWatch,
      episodeData,
    } = options;

    if (!isPartnerSubscription && !isActiveSubscription && accessKind === 'all_users') {
      return passTranslateArgs('button.watchForFree');
    }

    if (isActiveSubscription) {
      if (canContinueWatch) {
        return passTranslateArgs('button.continueWatch');
      }

      if (episodeData?.episode && episodeData?.season) {
        // @ts-expect-error
        return passTranslateArgs('button.watchEpisode', episodeData);
      }

      return passTranslateArgs('button.watchMovie');
    }

    if (isAuth && !isActiveSubscription) {
      const canWatchContent = isDefined(accessKind) && (accessKind === 'register' || accessKind === 'all_users');

      if (canWatchContent || isPartnerSubscription) {
        return passTranslateArgs('button.watch');
      }
    }

    if (!isAuth) {
      return getOfferText(offer, OfferTextType.WatchTrial, subscribePromoTexts);
    }

    return getOfferText(offer, getOfferTextType(hasTrialOffer, subscription), subscribePromoTexts);
  };

  const getLivePlayButtonText = (options: GetLivePlayButtonTextOptions) => {
    const {
      isActiveSubscription,
      isAuth,
      isPartnerSubscription,
      accessKind,
      offer,
      subscribePromoTexts,
      hasTrialOffer,
      subscription,
    } = options;

    if (isActiveSubscription) {
      return passTranslateArgs('button.watchLive');
    }

    if (isAuth && !isActiveSubscription) {
      const canWatchContent = isDefined(accessKind) && (accessKind === 'register' || accessKind === 'all_users');

      if (canWatchContent || isPartnerSubscription) {
        return passTranslateArgs('button.watchLive');
      }
    }

    if (!isAuth) {
      if (isDefined(accessKind) && accessKind === 'register') {
        return passTranslateArgs('button.registerToWatchChannel');
      }

      if (isDefined(accessKind) && accessKind === 'all_users') {
        return passTranslateArgs('button.watchLive');
      }

      return getOfferText(offer, OfferTextType.WatchTrial, subscribePromoTexts);
    }

    return getOfferText(offer, getOfferTextType(hasTrialOffer, subscription), subscribePromoTexts);
  };

  const getTVSliderButtonText = (options: GetTVSliderButtonTextOptions) => {
    const { accessKind, isAuth } = options;

    if (accessKind === 'all_users') {
      return passTranslateArgs('button.watchLive');
    }

    if (accessKind === 'register') {
      if (!isAuth) {
        return passTranslateArgs('button.registerToWatchChannel');
      }
    }

    return passTranslateArgs('button.watchLive');
  };

  const getMyChannelPlayButtonText = (options: GetMyChannelPlayButtonTextOptions) => {
    const {
      isAuth,
      isActiveSubscription,
      isPartnerSubscription,
      offer,
      subscribePromoTexts = {},
      hasTrialOffer,
      subscription,
      isForceSimpleWatchButtonText,
    } = options;

    if (isForceSimpleWatchButtonText || isActiveSubscription) {
      return passTranslateArgs('button.watch');
    }

    if (isAuth && !isActiveSubscription && isPartnerSubscription) {
      return passTranslateArgs('button.watch');
    }

    if (!isAuth) {
      return getOfferText(offer, OfferTextType.WatchTrial, subscribePromoTexts);
    }

    return getOfferText(offer, getOfferTextType(hasTrialOffer, subscription), subscribePromoTexts);
  };

  const getFullscreenMomentButtonText = (options: GetFullscreenMomentButtonTextOptions) => {
    const {
      isAuth,
      isActiveSubscription,
      isPartnerSubscription,
      accessKind,
      offer,
      subscribePromoTexts = {},
      hasTrialOffer,
      subscription,
      isPlaylistMode,
      content,
    } = options;

    if (isActiveSubscription) {
      if (isPlaylistMode) {
        return getWatchContentText(content);
      }

      return passTranslateArgs('button.watch');
    }

    if (isAuth && !isActiveSubscription) {
      const canWatchContent = isDefined(accessKind) && (accessKind === 'register' || accessKind === 'all_users');

      if (canWatchContent || isPartnerSubscription) {
        if (isPlaylistMode) {
          return getWatchContentText(content);
        }

        return passTranslateArgs('button.watch');
      }
    }

    if (!isAuth) {
      return getOfferText(offer, OfferTextType.WatchTrial, subscribePromoTexts);
    }

    return getOfferText(offer, getOfferTextType(hasTrialOffer, subscription), subscribePromoTexts);
  };

  return {
    getContentPlayButtonText,
    getLivePlayButtonText,
    getTVSliderButtonText,
    getMyChannelPlayButtonText,
    getFullscreenMomentButtonText,
    DEFAULT_TRIAL_DURATION,
    DEFAULT_TRIAL_PRICE,
    passTPluralArgs,
  };
}
